import React, { useEffect, useRef, forwardRef, useState, useImperativeHandle } from "react";
import * as XLSX from "xlsx";

const MonthTicketStatusTable = forwardRef((props, ref) => {
    const tableRef = useRef();
    const [data] = useState(props.data ? props.data : []);
    function exportToExcel() {

        if (!tableRef.current) return;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(tableRef.current);

        const columns = Object.keys(data[0]);

        const columnHeaders = ["No", ...columns]; // Add "No" as the first header

        columnHeaders.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({ r: 0, c: index }); // Getting header row cell reference
            if (ws[cellRef]) {
                ws[cellRef].t = 's'; // Set as string
                ws[cellRef].v = columnMappings[header] || header; // Map or use original header
            }
        });

        XLSX.utils.book_append_sheet(wb, ws, `Month Ticket Status Report`);
        XLSX.writeFile(wb, `Month_Ticket_Status_Report_${props.start_date}_${props.end_date}.xlsx`);
    };
    useImperativeHandle(ref, () => {
        return {
            exportToExcel
        };
    }, []);

    if (!data || data.length === 0) return <p></p>;


    // Extract all unique column keys (including dynamic months)
    const columns = Object.keys(data[0]);

    const columnMappings = {
        name: "Name",
        total: "Total"
    };

    // Expose function to parent using useImperativeHandle

    return (
        <div>
            <div
                style={{
                    marginTop: "20px",
                    maxHeight: props.maxHeight ? props.maxHeight : "",
                    overflowY: "auto",
                    width: props.width ? props.width : "initial",
                    // maxWidth: props.maxWidth ? props.maxWidth : "",
                    overflowX: "auto",
                    height: props.height ? props.height : "",
                }}
                className="table-select-custom"
            >
                <table ref={tableRef} style={{ width: '100%' }}>
                    <thead
                        style={{
                            position: 'sticky',
                            top: '0',
                            background: '#EFEFEF',
                        }}
                    >
                        <tr>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px", textAlign: "center" }}>No</th>

                            {columns.map((col, index) => (
                                <th key={index} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px", textAlign: "center" }}>
                                    {columnMappings[col] || col}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((row, rowIndex) => rowIndex === data.length - 1 ? (
                            <tr key={rowIndex} className="runtime_feedback_report_table_footer_parent">
                                <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}></td>

                                {columns.map((col, colIndex) => (
                                    <td key={colIndex} className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>
                                        {col === "name" ? (
                                            <a
                                                href={`/runtime_feedback?start_date=${props.start_date}&end_date=${props.end_date}`}
                                                className="runtime_feedback_report_hyper_link"
                                            >
                                                {row[col]}
                                            </a>
                                        ) : (
                                            row[col]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ) : (
                            <tr key={rowIndex}>
                                <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{rowIndex + 1}</td>

                                {columns.map((col, colIndex) => (
                                    <td key={colIndex} className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>
                                        {col === "name" ? (
                                            <a
                                                href={`/runtime_feedback?depot=${row[col]}&start_date=${props.start_date}&end_date=${props.end_date}`}
                                                className="runtime_feedback_report_hyper_link"
                                            >
                                                {row[col]}
                                            </a>
                                        ) : (
                                            row[col]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>
        </div>
    );
});

export default MonthTicketStatusTable;
