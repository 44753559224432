import React from "react";
import TotalIcon from "../../../assets/dashboard/happenings_total.svg";
import ActiveIcon from "../../../assets/dashboard/happenings_active.svg";
import UpcomingIcon from "../../../assets/dashboard/happenings_upcoming.svg";
import PublishIcon from "../../../assets/dashboard/comm_publish.svg";
import ScheduleIcon from "../../../assets/dashboard/comm_schedule.svg";
import { Reusable_table } from "../../../components/reusableItems/table";
import { happening_table_config } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoPermission } from "../components/noPermission";

export const DashboardHappenings = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">Happenings</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div style={{ marginTop: "20px" }}>
            <div className="dashboard_happening_detail_main_box">
              <div className="dashboard_happening_detail_first_box">
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={TotalIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    Total promotions
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.total_promotions_count
                      ? props.data.total_promotions_count
                      : 0}
                  </div>
                </div>
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={ActiveIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    Active promotions
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.active_promotions_count
                      ? props.data.active_promotions_count
                      : 0}
                  </div>
                </div>
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={UpcomingIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    Upcoming promotions
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.upcoming_promotions_count
                      ? props.data.upcoming_promotions_count
                      : 0}
                  </div>
                </div>
              </div>
              <div className="dashboard_happening_detail_second_box">
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={TotalIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    Total articles
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.total_articles_count
                      ? props.data.total_articles_count
                      : 0}
                  </div>
                </div>
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={PublishIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    Published articles
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.published_articles_count
                      ? props.data.published_articles_count
                      : 0}
                  </div>
                </div>
                <div className="dashboard_happening_detail_first_box_item">
                  <div>
                    <img src={ScheduleIcon} />
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_text">
                    scheduled articles
                  </div>
                  <div className="dashboard_happening_detail_first_box_item_count">
                    {props.data && props.data.scheduled_articles_count
                      ? props.data.scheduled_articles_count
                      : 0}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "20px" }}
              className="dashboard_happening_detail_active_promotion_main_box"
            >
              <div className="dashboard_graph_title_box">
                <div className="dashboard_happening_table_title">
                  Active promotions
                </div>
                <div
                  className="dashboard_happening_see_all_button"
                  onClick={() =>
                    props.handleNavigate &&
                    props.handleNavigate(
                      props.route ? props.route : "/dashboard"
                    )
                  }
                >
                  See all
                </div>
              </div>
              <div style={{ height: "250px", marginTop: "20px" }}>
                <Reusable_table
                  header={happening_table_config}
                  data={
                    props.data &&
                    props.data.active_promotions &&
                    props.data.active_promotions.length > 0 &&
                    props.data.active_promotions
                  }
                  isSmall={true}
                  height={250}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
