import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as XLSX from "xlsx";

const TopRequestGroupTable = forwardRef((props, ref) => {
    const tableRef = useRef();
    const [data] = useState(props.data ? props.data : []);

    // Expose function to parent using useImperativeHandle
    useImperativeHandle(ref, () => {
        return {
            exportToExcel
        };
    }, []);

    const exportToExcel = () => {

        if (!tableRef.current) return;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(tableRef.current);

        XLSX.utils.book_append_sheet(wb, ws, `Top Request Group`);
        XLSX.writeFile(wb, `Top_Request_Group_Report_${props.start_date}_${props.end_date}.xlsx`);
    };

    return (
        <div>
            <div
                style={{
                    marginTop: "20px",
                    maxHeight: props.maxHeight ? props.maxHeight : "",
                    overflowY: "auto",
                    width: props.width ? props.width : "initial",
                    // maxWidth: props.maxWidth ? props.maxWidth : "",
                    overflowX: "auto",
                    height: props.height ? props.height : "",
                }}
                className="table-select-custom"
            >
                <table ref={tableRef} style={{ width: '100%' }}>
                    <thead
                        style={{
                            position: 'sticky',
                            top: '0',
                            background: '#EFEFEF',
                        }}
                    >
                        <tr>
                            <th className="runtime_feedback_report_table_header" style={{ height: "100px" }}>Request</th>
                            <th className="runtime_feedback_report_table_header">Total</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#FFC1D0", height: "100px" }}>Valid</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#ECB1BF", height: "100px" }} >Invalid</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#B3D4FC", height: "100px" }}>Open</th>
                        </tr>
                    </thead>

                    <tbody>


                        {(data.length == 0 && !props.loading) ? (
                            <tr>
                                <td colSpan="12" style={{ textAlign: "center", padding: "20px", fontSize: "16px", fontWeight: "bold", color: "#000" }}>
                                    No Data Available
                                </td>
                            </tr>

                        ) : (
                            data.map((row, rowIndex) => rowIndex === data.length - 1 ? (
                                <tr key={rowIndex} className="runtime_feedback_report_table_footer_parent">
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>
                                        <a
                                            href={`/runtime_feedback?start_date=${props.start_date}&end_date=${props.end_date}`}
                                            className="runtime_feedback_report_hyper_link"
                                        >
                                            {row.name}
                                        </a>
                                    </td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.valid.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.invalid.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.open.total}</td>
                                </tr>
                            ) : (
                                <tr key={rowIndex}>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>
                                        <a
                                            href={`/runtime_feedback?request=${row.name}&start_date=${props.start_date}&end_date=${props.end_date}`}
                                            className="runtime_feedback_report_hyper_link"
                                        >
                                            {row.name}
                                        </a>
                                    </td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.valid.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.invalid.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.open.total}</td>
                                </tr>
                            ))
                        )}

                    </tbody>


                </table>


            </div>
        </div>
    );
});

export default TopRequestGroupTable;
