import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import {
  deleteSurveyById,
  fetchAllPollSurvey,
  udatePollSurveyStatus
} from "../../../store/poll_and_survey";
import "./poll_and_survey.css";
import { useLocation, useNavigate } from "react-router";
import { TableHeader } from "../../../components/reusableItems/header";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import moment from "moment";
import { fetchUserById } from "../../../store/userMgmt";
import { POLL_SURVEY_ADD, POLL_SURVEY_DETAIL } from "../../../routes";
import { Reusable_table } from "../../../components/reusableItems/table";
import { ConfigData, DefaulSorting, statusConfig } from "./configData";
import { ShowDialog } from "../../../components/reusableItems/dialog";
export const POLL_SURVEY_TABS = {
  ALL: "All",
  PUBLISHED: "Published",
  CONCLUDED: "Concluded",
  PENDING_APPROVAL: "Pending Approval",
  DRAFT: "Draft",
  CLOSED: "Closed",
};

export const PollSurveyListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const { poll_survey_list } = useSelector(
    (state) => state.pollSurvey
  );
  const [tab, setTab] = useState(POLL_SURVEY_TABS.ALL);
  const [tempData, setTempData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [tempId, setTempId] = useState("");
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");

  const [showDelete, setShowDelete] = useState(false);


  const userPermissions = getUserPermission();
  const AddPermssion = checkPermission(
    userPermissions,
    "survey.submit_survey"
  );

  const viewPermssion = checkPermission(
    userPermissions,
    "survey.access_survey_polls"
  );

  const getData = async (status, callback) => {
    setLoading(true);
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {

        dispatch(fetchAllPollSurvey({ status: status })).then((res) => {
          setLoading(false);
          setTempData(
            res.payload.data
          );
          console.log('Temp Data');
          console.log(res.payload.data);

        });
      }
    );
  };

  useEffect(() => {
    getData();
  }, [tab]);


  const actionTrigger = () => {
    setLoading(true);
    if (actionType === "Delete") {
      setShowDelete(false);
      
      dispatch(deleteSurveyById(actionID)).then((res) => {
        getData();
      });
    }
  };



  const handleTableClick = (type, id) => {
    if (type === "View Details") {
      navigate(`${POLL_SURVEY_DETAIL}?id=${id}`);
    } else if (type === "Delete") {
      setActionID(id);
      setActionType(type);
      if (type === "Delete") {
        setShowDelete(true);
      }
    }
    else {
      const survey = tempData.find(survey => survey.id === id);
      setTempId(survey.id);
      setTempStatus(survey.status);

      setShowUpdateStatus(true);
    }
  };

  const RefreshData = () => {
    getData();
  };



  const handleExport = () => {



  };




  const handleCreateAction = () => {
    navigate(POLL_SURVEY_ADD);
  };

  const icons = [
    AddPermssion && {
      buttonName: "+ Add new",
      action: handleCreateAction,
    },
    {
      icon: RefreshIcon,
      title: "Refresh",
      action: RefreshData,
    },
    // {
    //   icon: ExportIcon,
    //   title: "Export",
    //   action: handleExport,
    // },
  ];

  const updateStatus = () => {
    const getstatus = statusConfig.find(status => status.name === tempStatus);

    setTempId("");
    setTempStatus("");
    setShowUpdateStatus(false);
    let params = { question_id: tempId, status: getstatus.id };
    dispatch(udatePollSurveyStatus(params)).then((res) => {
      if (res.payload.status_code === 200) {
        getData();
      } else {
        alert(res.payload.message);
      }
    });
  };





  const onTabChange = (tab) => {
    setTab(tab);
  };

  return (
    <>
      {viewPermssion ? (
        <div className="container">

          {showUpdateStatus && (
            <ShowDialog
              cancelbtn={() => {
                setShowUpdateStatus(false);
                setTempStatus("");
                setUpdateStatusDetail({ id: "", status: "" });
              }}
              title={"Update Status"}
              dropdownContent={"Set status"}
              dropdown={true}
              cancelContent={"Cancel"}
              confrimContent={"Update"}
              confrinbtn={updateStatus}
              dropdownData={statusConfig.map(status => status.name)}
              dropdownOnChange={(e) => setTempStatus(e)}
              dropdownValue={tempStatus}
            />
          )}

          <TableHeader title={"Poll & Surveys"} icons={icons} />
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}

          {(<div className="poll_and_survey_child_box_main_detail_box">
            <div>
              <div className="tabs">
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.ALL);
                    getData();
                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.ALL ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.ALL}
                </div>
                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.PUBLISHED);
                    getData("published");

                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.PUBLISHED ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.PUBLISHED}
                </div>

                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.CONCLUDED);
                    getData("concluded");

                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.CONCLUDED ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.CONCLUDED}
                </div>



                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.PENDING_APPROVAL);
                    getData("pendingapproval");

                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.PENDING_APPROVAL ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.PENDING_APPROVAL}
                </div>


                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.DRAFT);
                    getData("draft");

                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.DRAFT ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.DRAFT}
                </div>

                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(POLL_SURVEY_TABS.CLOSED);
                    getData("closed");

                  }}
                  className={`tab ${tab === POLL_SURVEY_TABS.CLOSED ? "isactive" : ""
                    }`}
                >
                  {POLL_SURVEY_TABS.CLOSED}
                </div>

              </div>
            </div>

            {loading ? (
              <Loader
                type="converging-spinner"
                style={{
                  width: "100%",
                  height: "calc(100vh - 165px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#ffffff4d",
                  position: "absolute",
                  zIndex: "999",
                }}
              />
            ) : (
              <Reusable_table
                header={ConfigData}
                data={tempData}
                pagination={true}
                calculatePagi={true}
                pageChangeLoading={false}
                showRow={true}
                checkbox={false}
                defaultSorting={DefaulSorting}
                //   handleTableClick={handleTableClick}
                handleAction={handleTableClick}
                maxHeight={"calc(-221px + 100vh)"}
                maxWidth={"calc(100vw - 240px)"}
              />
            )}
          </div>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
