import React from "react";
import TotalIcon from "../../../assets/dashboard/dr_total_report.svg";
import ClosedIcon from "../../../assets/dashboard/ifeedback_close.svg";
import SubmittedIcon from "../../../assets/dashboard/ifeedback_submitted.svg";
import InvestigateIcon from "../../../assets/dashboard/ifeedback_investigate.svg";
import TimeIcon from "../../../assets/dashboard/ifeedback_time.svg";
import { BarChartComponents } from "../components/barChart";
import { IfeedbackBarGraphConfig } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { Upcoming } from "../components/upcoming";
import { NoPermission } from "../components/noPermission";

export const DashboardIfeedback = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">iFeedback</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div className="dahsboard_ifeedback_main_box">
            <div className="dahsboard_ifeedback_first_box">
              <div className="dahsboard_ifeedback_first_box_first_child">
                <div className="dashboard_ifeedback_first_box_first_child_detail">
                  <div>
                    <img src={TotalIcon} />
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_text">
                    Total Feedback Received
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_count">
                    {props.data && props.data.total_ifeedback
                      ? props.data.total_ifeedback
                      : 0}
                  </div>
                </div>
                <div className="dashboard_ifeedback_first_box_first_child_detail">
                  <div>
                    <img src={SubmittedIcon} />
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_text">
                    Submitted
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_count">
                    {props.data && props.data.total_submitted
                      ? props.data.total_submitted
                      : 0}
                  </div>
                </div>
                <div className="dashboard_ifeedback_first_box_first_child_detail">
                  <div>
                    <img src={InvestigateIcon} />
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_text">
                    Investigating
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_count">
                    {props.data && props.data.total_investigating
                      ? props.data.total_investigating
                      : 0}
                  </div>
                </div>
                <div className="dashboard_ifeedback_first_box_first_child_detail">
                  <div>
                    <img src={ClosedIcon} />
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_text">
                    Closed
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_count">
                    {props.data && props.data.total_closed
                      ? props.data.total_closed
                      : 0}
                  </div>
                </div>
                <div className="dashboard_ifeedback_first_box_first_child_detail">
                  <div>
                    <img src={TimeIcon} />
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_text">
                    Feedback Resolution Time
                  </div>
                  <div className="dashboard_ifeedback_first_box_first_child_detail_count">
                    {props.data && props.data.resolution_time
                      ? props.data.resolution_time
                      : 0}{" "}
                    hr
                  </div>
                </div>
              </div>
              <div className="dahsboard_ifeedback_first_box_last_child">
                {props.data &&
                props.data.feedback_trends &&
                props.data.feedback_trends.length > 0 ? (
                  <BarChartComponents
                    data={
                      props.data &&
                      props.data.feedback_trends &&
                      props.data.feedback_trends.length > 0
                        ? props.data.feedback_trends
                        : []
                    }
                    config={IfeedbackBarGraphConfig}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
            <div className="dahsboard_ifeedback_second_box">
              <div className="dashboard_graph_title_box">
                <div className="dahsboard_ifeedback_second_box_title">
                  Submissions by Department
                </div>
                <div
                  className="dashboard_ifeedback_see_all_button"
                  onClick={() =>
                    props.handleNavigate &&
                    props.handleNavigate(
                      props.route ? props.route : "/dashboard"
                    )
                  }
                >
                  See all
                </div>
              </div>
              <div className="dashboard_ifeedback_second_item_box_child">
                {props.data &&
                props.data.group_by_department &&
                props.data.group_by_department.length > 0 ? (
                  props.data.group_by_department
                    .slice(0, 12)
                    .map((i, index) => (
                      <div
                        key={index}
                        className="dashboard_ifeedback_second_item_box_child_item"
                      >
                        <div className="dashboard_ifeedback_second_item_box_child_item_detail">
                          <div>{index + 1}.</div>
                          <div>{i.name}</div>
                        </div>
                        <div>{i.value}</div>
                      </div>
                    ))
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
