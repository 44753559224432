import React from "react";
import TotalGroupIcon from "../../../assets/dashboard/group_toal.svg";
import { BarChartComponents } from "../components/barChart";
import { Loader } from "@progress/kendo-react-indicators";
import { userGroupBarGraphConfig } from "./configData";
import { NoRecord } from "../components/noRecord";
import { NoPermission } from "../components/noPermission";

export const DashboardGroup = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">Group</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div
            style={{ marginTop: "20px" }}
            className="dashboard_group_first_main_box"
          >
            <div className="dashboard_group_first_box_first_child">
              <div className="dashboard_group_first_box_first_child_item">
                <div>
                  <img src={TotalGroupIcon} />
                </div>
                <div className="dashboard_group_first_box_first_child_item_text">
                  Total Groups
                </div>
                <div className="dashboard_group_first_box_first_child_item_count">
                  {props.data && props.data.total_groups
                    ? props.data.total_groups
                    : 0}
                </div>
              </div>
            </div>
            <div className="dashboard_group_first_box_second_child">
              {props.data &&
              props.data.total_users_by_groups &&
              props.data.total_users_by_groups.length > 0 ? (
                <BarChartComponents
                  data={
                    props.data &&
                    props.data.total_users_by_groups &&
                    props.data.total_users_by_groups.length > 0
                      ? props.data.total_users_by_groups
                      : []
                  }
                  config={userGroupBarGraphConfig}
                />
              ) : (
                <NoRecord />
              )}
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
