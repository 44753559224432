import React, { useEffect } from 'react';
import { Switch, TextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import CopyIcon from "../../../../assets/common/copy.svg";
import DeleteIcon from "../../../../assets/common/delete.svg";
import TextFieldBox from '../components/text_field_box';
import OptionsList from './OptionsList';
import BranchingOptionsList from './BranchingOptionsList';

const Item = React.memo(({ question, index, updateQuestion, deleteQuestion, copyQuestion, types, enQuestions, zhQuestions , tab }) => {

    return (
        <div className="poll_and_survey_create_item_main_box">
            <div className="poll_and_survey_create_item_main_box_title">{`Question ${index + 1}`}</div>
            <div className="poll_and_survey_create_item_row">
                <div className="poll_and_survey_main_box">
                    <div className="poll_and_survey_main_box_title">Title</div>
                    <div>
                        <TextBox
                            className="poll_and_survey_main_box_field"
                            value={question.qtitle}
                            onChange={(e) => updateQuestion(question.qid, "qtitle", e.target.value)}
                        />
                    </div>
                </div>
                <div className="poll_and_survey_main_box">
                    <div className="poll_and_survey_main_box_title">Types</div>
                    <div>
                        <DropDownList
                            data={types}
                            textField="name"
                            dataItemKey="value"
                            value={question.qtype}
                            onChange={(e) => {
                                updateQuestion(question.qid, "qtype", e.target.value)
                            }}
                            placeholder="Select Type"
                            className="poll_and_survey_create_item_main_box_field tutorial_dropdown"
                            itemRender={itemRender}
                        />
                    </div>
                </div>

            </div>
            <div>

                {question.qtype.value == "single-choice" || question.qtype.value == "multiple-choice" ? (
                    <OptionsList question={question} updateQuestion={updateQuestion} />
                ) : null}

                {question.qtype.value == "branching" ? (
                    <BranchingOptionsList question={question}
                        enQuestions={enQuestions} 
                        zhQuestions={zhQuestions}
                        updateQuestion={updateQuestion}
                        tab={tab}
                    />
                ) : null}

            </div>

            <div className="poll_and_survey_footer_action">

                <div
                    className="poll_and_survey_footer_action_icon"
                    onClick={() => {
                        copyQuestion(question.qid);
                    }}
                >
                    <img src={CopyIcon} />
                </div>

                <div
                    className="poll_and_survey_footer_action_icon"
                    onClick={() => {
                        deleteQuestion(question.qid);
                    }}
                >
                    <img src={DeleteIcon} />
                </div>

                <div style={{ borderLeft: "2px solid #CCCCCC", height: "40px" }}></div>
                <div className="poll_and_survey_main_box_title">Required</div>

                <div className="toggle-comment">
                    <Switch
                        defaultChecked={question.is_required}
                        offLabel=""
                        onLabel=""
                        size="small"
                        onChange={async (selected) => {

                            updateQuestion(question.qid, "is_required", selected?.value)
                        }}
                    />
                </div>
            </div>
        </div>
    );
});

const itemRender = (li, itemProps) => {
    return React.cloneElement(li, {
        style: {
            height: "48px",
            lineHeight: "48px",
        },
    });
};

export default Item;
