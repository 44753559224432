// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  POLL_AND_SURVEY_LISTING_ENDPOINT,
  POLL_AND_SURVEY_ENDPOINT,
  POLL_AND_SURVEY_ENDPOINT_UPDATE_STATUS,
  POLL_AND_SURVEY_DETAILS_ENDPOINT,
  POLL_AND_SURVEY_DETAILS_BY_DEPARTMENT_ENDPOINT,
  POLL_AND_SURVEY_DETAILS_BY_SUMMARY_ENDPOINT,
  POLL_AND_SURVEY_DELETE_ENDPOINT,
  AUDIT_ENDPOINT,

} = config.api_endpoint;

const initialState = {
  loading: false,
  poll_survey_list: [],
  error: null,
  next_key: {},
  detail_loading: false,
  detail_data: {},
  summary_data: {},
  department_loading: false,
  department_list: [],
  detail_error: null,
  audit_data: [],
  audit_loading: false,
  audit_error: null
};

export const fetchAllPollSurvey = createAsyncThunk(
  "poll_survey/fetchAllPollSurvey",
  async ({ status }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      let url = `${POLL_AND_SURVEY_LISTING_ENDPOINT}`;

      // Append status as a query parameter if provided
      if (status) {
        url += `?status=${status}`;
      }
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const fetcheSurveyDetail = createAsyncThunk(
  "poll_survey/fetcheSurveyDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        POLL_AND_SURVEY_DETAILS_ENDPOINT + "/" + id,
        {},
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetcheSurveyDetailByDepartment = createAsyncThunk(
  "poll_survey/fetcheSurveyDetaillByDepartment",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.get(
        POLL_AND_SURVEY_DETAILS_BY_DEPARTMENT_ENDPOINT + "/" + id,
        {},
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetcheSurveyDetailBySummary = createAsyncThunk(
  "poll_survey/fetcheSurveyDetaillBySummary",
  async ({ id, division }, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();

      let url = POLL_AND_SURVEY_DETAILS_BY_SUMMARY_ENDPOINT + "/" + id;

      if (division) {
        url += "?division=" + encodeURIComponent(division);
      }
      const results = await api.get(url,
        {},
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const createAndUpdatePollSurvey = createAsyncThunk(
  "poll_survey/createAndUpdatePollSurvey",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        POLL_AND_SURVEY_ENDPOINT,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const udatePollSurveyStatus = createAsyncThunk(
  "poll_survey/pdatePollSurveyStatus",
  async (data, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const results = await api.post(
        POLL_AND_SURVEY_ENDPOINT_UPDATE_STATUS,
        JSON.stringify(data),
        `Bearer ${token}`
      );

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const deleteSurveyById = createAsyncThunk(
  "poll_survey/deleteSurveyById",
  async (id, { rejectWithValue }) => {
    try {

      const token = getCurrentLoggedInSessionToken();
      const results = await api.remove(
        POLL_AND_SURVEY_DELETE_ENDPOINT + "/" + id,
        JSON.stringify({ id: id }),
        `Bearer ${token}`
      );

      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);





export const fetchSurveyAudit = createAsyncThunk(
  "poll_survey/fetchSurveyAudit",
  async (id, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${AUDIT_ENDPOINT}?table_name=ilink-dev-survey-question&source_id=${id}`;
      const results = await api.get(url, {}, token);
      return results.data;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const pollSurveySlice = createSlice({
  name: "poll_survey",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetcheSurveyDetail.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetcheSurveyDetail.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.detail_data = action.payload;
      })
      .addCase(fetcheSurveyDetail.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })
      .addCase(fetchAllPollSurvey.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPollSurvey.fulfilled, (state, action) => {
        state.loading = false;
        state.poll_survey_list = action.payload;
      })
      .addCase(fetchAllPollSurvey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetcheSurveyDetailByDepartment.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetcheSurveyDetailByDepartment.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.department_list = action.payload;
      })
      .addCase(fetcheSurveyDetailByDepartment.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })

      .addCase(fetcheSurveyDetailBySummary.pending, (state) => {
        state.detail_loading = true;
      })
      .addCase(fetcheSurveyDetailBySummary.fulfilled, (state, action) => {
        state.detail_loading = false;
        state.summary_data = action.payload;
      })
      .addCase(fetcheSurveyDetailBySummary.rejected, (state, action) => {
        state.detail_loading = false;
        state.detail_error = action.error.message;
      })

      .addCase(fetchSurveyAudit.pending, (state) => {
        state.audit_loading = true;
      })
      .addCase(fetchSurveyAudit.fulfilled, (state, action) => {
        state.audit_loading = false;
        state.audit_data = action.payload;
      })
      .addCase(fetchSurveyAudit.rejected, (state, action) => {
        state.audit_loading = false;
        state.audit_error = action.error.message;
      })
  },
});

const PollSurveyReducer = pollSurveySlice.reducer;
export default PollSurveyReducer;
