// kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
// icons
import ExportIcon from "../../../../assets/dr_safe/export.png";
import FilterIcon from "../../../../assets/dr_safe/filter.png";
import SearchIcon from "../../../../assets/header/search.svg";
// components
import PageTitle from "../../../../components/pageTitle/page_title";
import { Navigate, useNavigate } from "react-router";
import DrInfraListSearchDialog from "./dr_infra_list_search_dialog";
import DrInfraListFilterDialog from "./dr_infra_list_filter_dialog";

const DrInfraListHeader = ({
  showDrSearchDialog,
  onSearchBtnClicked,
  onSearchDialogClose,
  onSearchAction,
  showDrFilterDialog,
  onFilterBtnClicked,
  onFilterDialogClose,
  onFilterAction,
}) => {
  const navigate = useNavigate();

  return (
    <div className="dr-infra-list-header">
      <PageTitle title="DR Infra Reported Incident" />
      <div className="dr-infra-list-header-right">
        <div className="dr-infra-list-header-icon">
          <img src={ExportIcon} />
        </div>
        {/* <Button className="dr-infra-list-header-icon" title="Filter" > */}
        <div className="dr-infra-list-header-icon" onClick={onFilterBtnClicked}>
          <img src={FilterIcon} />
        </div>
        {/* </Button> */}
        <div className="dr-infra-list-header-icon" onClick={onSearchBtnClicked}>
          {/* <Button className="button2" title="Search"  > */}
          <img src={SearchIcon} />
          {/* </Button> */}
        </div>
      </div>
      {showDrSearchDialog && (
        <DrInfraListSearchDialog
          onToggleDialog={onSearchDialogClose}
          onSubmitShare={onSearchAction}
        />
      )}
      {showDrFilterDialog && (
        <DrInfraListFilterDialog
          onToggleDialog={onFilterDialogClose}
          onSubmitFilter={onFilterAction}
        />
      )}
    </div>
  );
};

export default DrInfraListHeader;
