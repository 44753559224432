import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../dashboard.css";
import { useNavigate } from "react-router";

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;

  return (
    <span style={{ color }} className="barChartLegend">
      {value}
    </span>
  );
};

const RenderLegend = (props) => {
  const navigate = useNavigate();
  const { payload } = props;

  return (
    <div className="dashboard_barChart_legend_main_box">
      <div className="dashboard_barChart_legend_title">{props.title}</div>
      {props.isShowLegend && props.type === "text" ? (
        <div className="dashboard_barChart_legend_box">
          {payload.map((entry, index) => (
            <div
              key={`item-${index}`}
              // style={{ color: entry.color }}
              className="dashboard_barChart_legend_box_item"
            >
              <div
                className="dashboard_barChart_legend_box_item_dot"
                style={{ backgroundColor: entry.color }}
              />
              {props.external.find((i) => i.data_key === entry.dataKey)
                ? props.external.find((i) => i.data_key === entry.dataKey).title
                : entry.value}
            </div>
          ))}
        </div>
      ) : (
        props.isShowLegend &&
        props.type === "button" && (
          <div
            className="dashboard_graph_see_all_button"
            onClick={() =>
              props.route ? navigate(props.route) : navigate("/dashboard")
            }
          >
            See all
          </div>
        )
      )}
    </div>
  );
};

const CustomizedTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x + 3},${y - 10})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        className="barchart_tick_text"
      >
        {props.shouldAdd0 && payload.value < 10 && 0}
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedTickY = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x - 8},${y - 18})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="start"
        fill="#666"
        className="barchart_tick_text"
      >
        {payload.value}
      </text>
    </g>
  );
};

const RenderTooltip = (props) => {
  const { active, payload, label, external } = props;
  if (active && payload && payload.length) {
    if (props.config && props.config === "comms") {
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          <div className="barchart_tooltip_text">
            {props.mainData[label - 1].date}
          </div>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {external.find((j) => j.data_key === i.dataKey)
                ? external.find((j) => j.data_key === i.dataKey).title
                : i.dataKey}
              : {i.value}
            </div>
          ))}
        </div>
      );
    } else if (props.config && props.config === "user_management") {
      const title =
        payload[0].payload.name === "01"
          ? "First week"
          : payload[0].payload.name === "02"
          ? "Second week"
          : payload[0].payload.name === "03"
          ? "Third week"
          : "Fourth week";
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {title}: {i.value}
            </div>
          ))}
        </div>
      );
    } else if (props.config && props.config === "UserGroup") {
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          <div className="barchart_tooltip_text">{payload[0].payload.name}</div>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {external.find((j) => j.data_key === i.dataKey)
                ? external.find((j) => j.data_key === i.dataKey).title
                : i.dataKey}
              : {i.value}
            </div>
          ))}
        </div>
      );
    } else {
      const title = payload[0].payload.name;
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {title}: {i.value}
            </div>
          ))}
        </div>
      );
    }
  }

  return null;
};

export const BarChartComponents = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.data && props.data}
        margin={{
          top: 20,
          right: 15,
          left: 2,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        {props.config && props.config.for_module === "comms" ? (
          <XAxis
            dataKey={(props) => new Date(props.date).getDate()}
            axisLine={false}
            tickLine={false}
            tickSize={15}
            minTickGap={2}
            tick={<CustomizedTick shouldAdd0={false} />}
          />
        ) : (
          <XAxis
            dataKey={props.config && props.config.xAxis_key}
            axisLine={false}
            tickLine={false}
            // tick={<CustomizedTick />}
            tick={
              props.config && props.config.custom_tick ? (
                <CustomizedTick />
              ) : (
                {
                  fill: "#666666",
                  fontFamily: "Poppins",
                  fontSize:
                    props.config && props.config.tick_font_size
                      ? props.config.tick_font_size
                      : "16px",
                  fontWeight: 600,
                }
              )
            }
          />
        )}
        <YAxis
          height={10}
          axisLine={false}
          tickLine={false}
          type="number"
          domain={[0, "dataMax + 3"]}
          width={25}
          tickCount={8}
          allowDecimals={false}
          tick={<CustomizedTickY />}
        />
        <Tooltip
          content={
            <RenderTooltip
              external={
                props.config && props.config.config && props.config.config
              }
              mainData={props.data && props.data}
              config={
                props.config &&
                props.config.for_module &&
                props.config.for_module
              }
            />
          }
        />
        <Legend
          content={
            <RenderLegend
              route={props.config && props.config.route && props.config.route}
              external={
                props.config && props.config.config && props.config.config
              }
              isShowLegend={props.config && props.config && props.config.legend}
              type={props.config && props.config && props.config.legend_type}
              title={props.config && props.config && props.config.graph_title}
            />
          }
          verticalAlign="top"
          height={70}
          align="right"
          iconType="circle"
          formatter={renderColorfulLegendText}
        />
        {/* <Legend verticalAlign="top" height={36} align='right' iconType='circle' formatter={renderColorfulLegendText} /> */}
        {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" barSize={10} />
        <Bar dataKey="uv" stackId="a" fill="#82ca9d" barSize={10} /> */}
        {props.config &&
          props.config.config &&
          props.config.config.map((i) => (
            <Bar
              dataKey={i.data_key}
              stackId="a"
              fill={i.color}
              barSize={props.config.barSize}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
