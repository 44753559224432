import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState } from "react";
import "../dashboard.css";
import PublishedIcon from "../../../assets/dashboard/comm_publish.svg";
import ScheduleIcon from "../../../assets/dashboard/comm_schedule.svg";
import DraftIcon from "../../../assets/dashboard/comm_draft.svg";
import PendingIcon from "../../../assets/dashboard/comm_pending_approval.svg";
import ReactionCountIcon from "../../../assets/dashboard/comm_reaction_count.svg";
import ACKIcon from "../../../assets/dashboard/comm_ack_rate.svg";
import OPENICON from "../../../assets/dashboard/comm_open_rate.svg";
import LikeReactionIcon from "../../../assets/dashboard/comm_like_reaction.svg";
import HappyReactionIcon from "../../../assets/dashboard/comm_happy_reaction.svg";
import WowReactionIcon from "../../../assets/dashboard/comm_wow_reaction.svg";
import ClapReactionIcon from "../../../assets/dashboard/comm_clap_reaction.svg";
import { BarChartComponents } from "../components/barChart";
import { PieChartComponents } from "../components/pieChart";
import { commsBarGraphConfig, pieChartColor } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { NoPermission } from "../components/noPermission";

export const DashboardCommunication = (props) => {
  return (
    <div>
      <div className="dashboard_communication_title_box">
        <div className="dashboard_communication_title">Communication</div>
        <div className="dashboard_filter_dropdown">
          {props.permission && (
            <ComboBox
              data={
                    props.category && props.category.length > 0
                      ? props.category
                      : []
                  }
            textField="name"
            valueField="id" 
            value={props.dropdownValue}
            onChange={(e) =>
                  props.handleCommDropdown && props.handleCommDropdown(e.value)
                }
            placeholder="Category"  // Placeholder text
          />
          )}
        </div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div style={{ marginTop: "20px" }}>
            <div className="dashboard_comm_reaction_main_box">
              <div className="dashboard_comm_reaction_first_box">
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={PublishedIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Published
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.published
                      ? props.data.published
                      : 0}
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={ScheduleIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Scheduled
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.scheduled
                      ? props.data.scheduled
                      : 0}
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={DraftIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Drafts
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.draft ? props.data.draft : 0}
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={PendingIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Pending Approval
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.pendingapproval
                      ? props.data.pendingapproval
                      : 0}
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={ReactionCountIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Reaction Count
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.reaction_count
                      ? props.data.reaction_count
                      : 0}
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={ACKIcon} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Ack rate
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.ack_rate
                      ? props.data.ack_rate
                      : 0}
                    %
                  </div>
                </div>
                <div className="dashboard_comm_reaction_first_box_item">
                  <div>
                    <img src={OPENICON} />
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_text">
                    Open rate
                  </div>
                  <div className="dashboard_comm_reaction_first_box_item_count">
                    {props.data && props.data.open_rate
                      ? props.data.open_rate
                      : 0}
                    %
                  </div>
                </div>
              </div>
              <div className="dashboard_comm_reaction_second_box">
                <div className="dashboard_comm_reaction_second_box_item_title">
                  Reactions
                </div>
                <div className="dashboard_comm_reaction_second_box_child_box">
                  <div className="dashboard_comm_reaction_second_box_item">
                    <div>
                      <img src={LikeReactionIcon} />
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_count">
                      {props.data && props.data.total_thumbsup
                        ? props.data.total_thumbsup
                        : 0}
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_text">
                      Thumbs up
                    </div>
                  </div>
                  <div className="dashboard_comm_reaction_second_box_item">
                    <div>
                      <img src={HappyReactionIcon} />
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_count">
                      {props.data && props.data.total_happy
                        ? props.data.total_happy
                        : 0}
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_text">
                      Happy
                    </div>
                  </div>
                  <div className="dashboard_comm_reaction_second_box_item">
                    <div>
                      <img src={WowReactionIcon} />
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_count">
                      {props.data && props.data.total_wow
                        ? props.data.total_wow
                        : 0}
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_text">
                      Wow
                    </div>
                  </div>
                  <div className="dashboard_comm_reaction_second_box_item">
                    <div>
                      <img src={ClapReactionIcon} />
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_count">
                      {props.data && props.data.total_clap
                        ? props.data.total_clap
                        : 0}
                    </div>
                    <div className="dashboard_comm_reaction_second_box_item_text">
                      Clap
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard_comm_chart_main_box">
              <div className="dashboard_comm_chart_first_box">
                {props.data &&
                props.data.overtime_activity &&
                props.data.overtime_activity.length > 0 ? (
                  <BarChartComponents
                    data={
                      props.data &&
                      props.data.overtime_activity &&
                      props.data.overtime_activity.length > 0
                        ? props.data.overtime_activity
                        : []
                    }
                    config={commsBarGraphConfig}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
              <div className="dashboard_comm_chart_second_box">
                <div className="dashboard_comm_chart_second_box_title">
                  Top 6 category Distribution
                </div>
                <div style={{ height: "calc(100% - 30px)" }}>
                  {props.data &&
                  props.data.group_by_category &&
                  props.data.group_by_category.length > 0 ? (
                    <PieChartComponents
                      data={
                        props.data && props.data.group_by_category
                          ? props.data.group_by_category
                          : []
                      }
                      colors={pieChartColor}
                    />
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
