import React from "react";
import TotalIcon from "../../../assets/dashboard/dr_total_report.svg";
import ValidIcon from "../../../assets/dashboard/mic_valid.svg";
import NotValidIcon from "../../../assets/dashboard/mic_not_vaild.svg";
import KIV from "../../../assets/dashboard/mic_kiv.svg";
import ImplementationIcon from "../../../assets/dashboard/mic_implementation.svg";
import ImplementedIcon from "../../../assets/dashboard/mic_implemented.svg";
import { LineChartComponents } from "../components/lineChart";
import { MICLineGraphConfig } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { Upcoming } from "../components/upcoming";
import { NoPermission } from "../components/noPermission";

export const DashboardMIC = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">MIC</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div className="dahsboard_MIC_main_box">
            <div className="dahsboard_MIC_first_box">
              <div className="dahsboard_MIC_first_box_first_child">
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={TotalIcon} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    Total MIC Submissions
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.total_ideas
                      ? props.data.total_ideas
                      : 0}
                  </div>
                </div>
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={ValidIcon} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    Valid
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.valid_ideas
                      ? props.data.valid_ideas
                      : 0}
                  </div>
                </div>
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={NotValidIcon} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    Not Valid
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.notvalid_ideas
                      ? props.data.notvalid_ideas
                      : 0}
                  </div>
                </div>
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={KIV} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    KIV
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.kiv_ideas
                      ? props.data.kiv_ideas
                      : 0}
                  </div>
                </div>
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={ImplementationIcon} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    Implementing
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.implementing_ideas
                      ? props.data.implementing_ideas
                      : 0}
                  </div>
                </div>
                <div className="dashboard_MIC_first_box_first_child_detail">
                  <div>
                    <img src={ImplementedIcon} />
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_text">
                    Implemented
                  </div>
                  <div className="dashboard_MIC_first_box_first_child_detail_count">
                    {props.data && props.data.implemented_ideas
                      ? props.data.implemented_ideas
                      : 0}
                  </div>
                </div>
              </div>
              <div className="dahsboard_MIC_first_box_last_child">
                {props.data &&
                props.data.engagement_analysis &&
                props.data.engagement_analysis.length > 0 ? (
                  <LineChartComponents
                    data={
                      props.data &&
                      props.data.engagement_analysis &&
                      props.data.engagement_analysis.length > 0
                        ? props.data.engagement_analysis
                        : []
                    }
                    config={MICLineGraphConfig}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
            <div className="dahsboard_MIC_second_box">
              <div className="dashboard_graph_title_box">
                <div className="dahsboard_MIC_second_box_title">
                  Submissions by Department
                </div>
                <div
                  className="dashboard_MIC_see_all_button"
                  onClick={() =>
                    props.handleNavigate &&
                    props.handleNavigate(
                      props.route ? props.route : "/dashboard"
                    )
                  }
                >
                  See all
                </div>
              </div>
              <div className="dashboard_MIC_second_item_box_child">
                {props.data &&
                props.data.group_by_deparment &&
                props.data.group_by_deparment.length > 0 ? (
                  props.data.group_by_deparment.slice(0, 12).map((i, index) => (
                    <div
                      key={index}
                      className="dashboard_MIC_second_item_box_child_item"
                    >
                      <div className="dashboard_MIC_second_item_box_child_item_detail">
                        <div>{index + 1}.</div>
                        <div>{i.name}</div>
                      </div>
                      <div>{i.value}</div>
                    </div>
                  ))
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
