import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import TextFieldBox from "../components/text_field_box";
import TextAreaBox from "../components/text_area";
import "../poll_and_survey.css";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../../components/custom/datepicker";
import moment from "moment";

export const Notification_Details = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const handleEditBtn = () => {
    query.set("state", 0);
    navigate(ADD_NOTIFICATION_INFORMATION + "?" + query.toString());
  };
  const minMinute = new Date(new Date().getTime() + 15 * 60000);

  return (
    <>
      <div className="poll_and_survey_main_box">
        <TextFieldBox
          title={"Title"}
          value={props.editData.title}
          errorMessage={props.showError.title}
          onChange={(e) => {
            const value = e.target.value;
            props.setEditData((prevData) => ({
              ...prevData,
              title: value,
            }));
          }}
        />

        <TextAreaBox
          title={"Description"}
          value={props.editData.description}
          errorMessage={props.showError.title}
          onChange={(e) => {
            const value = e.target.value;
            props.setEditData((prevData) => ({
              ...prevData,
              description: value,
            }));
          }}
        />

        <div className="poll_and_survey_date_layout">
          <div className="poll_and_survey_main_box margin-bottom">
            <div className="poll_and_survey_main_box_title">Start Date</div>
            <div>
              <DatePicker
                dateFormatCalendar="MMMM"
                className="poll-survey-date-picker"
                selected={props.editData.start_date}
                minDate={new Date()}
                onChange={(date) => {
                  props.setEditData((prevData) => ({
                    ...prevData,
                    start_date: date,
                  }));
                }}
                customInput={<CustomDatePicker />}
              />
            </div>
          </div>

          <div className="poll_and_survey_main_box margin-bottom">
            <div className="poll_and_survey_main_box_title">Start Time</div>
            <div>
              <TimePicker
                placeholder={""}
                className="info-datetime-picker keyboardInputDisable"
                defaultValue={props.editData.start_time}
                value={props.editData.start_time}
                // min={""}
                min={
                  props.editData.start_date === ""
                    ? minMinute
                    : moment(props.editData.start_date).format("DD-MM-YYYY") ===
                      moment(new Date()).format("DD-MM-YYYY")
                      ? minMinute
                      : new Date(new Date().getTime() + 3 * 24 * 3600000)
                }
                steps={{
                  hour: 1,
                  minute: 15,
                  // second: 30,
                }}
                onChange={(e) => {

                  props.setEditData((prevData) => ({
                    ...prevData,
                    start_time: e.target.value,
                  }));
                }}
              // required
              />
            </div>
          </div>

        </div>


        <div className="poll_and_survey_date_layout">
          <div className="poll_and_survey_main_box margin-bottom">
            <div className="poll_and_survey_main_box_title">End Date</div>
            <div>
              <DatePicker
                dateFormatCalendar="MMMM"
                selected={props.editData.end_date}
                className="poll-survey-date-picker"
                minDate={new Date()}
                onChange={(date) => {
                  props.setEditData((prevData) => ({
                    ...prevData,
                    end_date: date,
                  }));
                }}
                customInput={<CustomDatePicker />}
              />
            </div>
          </div>

          <div className="poll_and_survey_main_box margin-bottom">
            <div className="poll_and_survey_main_box_title">End Time</div>
            <div>
              <TimePicker
                placeholder={""}
                className="info-datetime-picker keyboardInputDisable"
                defaultValue={props.editData.end_time}
                value={props.editData.end_time}
                // min={""}
                // min={
                //   props.editData.end_date === ""
                //     ? minMinute
                //     : moment(props.editData.end_date).format("DD-MM-YYYY") ===
                //       moment(new Date()).format("DD-MM-YYYY")
                //       ? minMinute
                //       : new Date(new Date().getTime() + 3 * 24 * 3600000)
                // }
                steps={{
                  hour: 1,
                  minute: 15,
                  // second: 30,
                }}
                onChange={(e) => {

                  props.setEditData((prevData) => ({
                    ...prevData,
                    end_time: e.target.value,
                  }));
                }}
              // required
              />
            </div>
          </div>

        </div>

      </div>
    </>
  );
};
