import React, { useState } from "react";
import { useLocation,useNavigate } from "react-router";
import { Stepper,Step } from "@progress/kendo-react-layout";
import { POLL_SURVEY_ADD } from "../../../routes";

const items = [
    {
      label: "Information",
      value: 0,
    },    
    {
      label: "Question Builder",
      value: 1,
    },        
    {
      label: "Distribution",
      value: 2,
    },
    {
      label: "Collaborator",
      value: 3
    },
    {
      label: "Review and Publish",
      value: 4
    }
  ];

  const CustomStep = (props) => {
      return (
        <Step {...props}>
          <div className="custom-step">
            <span>{props.svgIcon}</span>
          </div>
          <span className="step-label">{props.label}</span>
        </Step>
      );
    };
export const AddPollSurveySteps = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);  
  const handleChange = (e) => {
    // e.preventDefault();
    if(query.get("id") || query.get("copyId")){
      if(props.isSplashOnly && e.value != 0){
        props.setState(e.value+1);
        query.set("state",e.value+1);
      }
      else{
        props.setState(e.value);
        query.set("state",e.value);  
      }
      navigate(POLL_SURVEY_ADD+"?"+query.toString());     
    }
  };
  console.log(props.state);
  console.log(props.isSplashOnly);
  return (
    <>
        <div className="poll-progress-steps-group">
            <div className="progress-steps-inside-group">
                <Stepper 
                  className="poll_and_survey_steps" 
                  value={props.state} 
                  items={items}
                  // item={CustomStep}
                  onChange={(e)=>handleChange(e)} 
                />
            </div>
        </div>
    </>
  );
};