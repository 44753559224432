
import React, { useEffect } from 'react';
import { Switch, TextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import CopyIcon from "../../../../assets/common/copy.svg";
import DeleteIcon from "../../../../assets/common/delete.svg";
import TextFieldBox from '../components/text_field_box';


const OptionsList = ({ question, updateQuestion }) => {
  const addOption = () => {
    const option = {
      "question_id": question.qid,
      "value": "",
      "type": 'key' // or other
    }
    updateQuestion(question.qid, "options", [...question.options, option]);
  };

  const addOtherOption = () => {
    const option = {
      "question_id": question.qid,
      "value": "Other",
      "type": 'other' // or other
    }
    updateQuestion(question.qid, "options", [...question.options, option]);

  };

  const updateOption = (index, key, value) => {
    const newOptions = [...question.options];
    newOptions[index] = { ...newOptions[index], [key]: value };
    updateQuestion(question.qid, "options", newOptions);
  };

  const deleteOption = (index) => {
    const newOptions = [...question.options];
    newOptions.splice(index, 1); // Remove the option at the given index
    updateQuestion(question.qid, "options", newOptions);
  };

  return (
    <div className="poll_and_survey_add_option_list">
      {question.options.map((option, index) => (
        <div className="poll-survey-option-item">
          <TextBox
            className="poll_and_survey_main_box_field"
            value={option.value}
            disabled={option.type === 'other'}
            onChange={(e) => updateOption(index, "value", e.target.value)}
          />
          <div
            className="delete-button"
            onClick={() => {
              deleteOption(index);
            }}
          >
            <img src={DeleteIcon} className="delete-button-icon" />
          </div>
        </div>
      ))}

      <div className="poll_and_survey_add_option_main">
        <div
          className="poll_and_survey_add_row_button"
          onClick={addOption}
        >
          <div>Add Option</div>
        </div>

        <div className="or_text">or</div>

        <div
          className="poll_and_survey_add_row_button"
          onClick={addOtherOption}
        >
          {" "}
          <div>Add Other</div>
        </div>

      </div>

    </div>
  );
};

export default OptionsList