import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import TextFieldBox from "../components/text_field_box";
import TextAreaBox from "../components/text_area";
import "../poll_and_survey.css";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { CustomDatePicker } from "../../../../components/custom/datepicker";
import moment from "moment";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import CopyIcon from "../../../../assets/common/copy.svg";
import DeleteIcon from "../../../../assets/common/delete.svg";
import Item from "./Item";

export const LANGUAGE_TABS = {
  EN: "English",
  CH: "Chinese",
};

export const surveyTypes = [
  {
    "value": "single-choice",
    "name": "Single Choice"
  },
  {
    "value": "multiple-choice",
    "name": "Multiple Choice"
  },
  {
    "value": "open-ended",
    "name": "Open-Ended"
  },
  {
    "value": "branching",
    "name": "Branching"
  },
  {
    "value": "star-rating",
    "name": "Star Rating"
  },

]



export const PollQuestionList = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const minMinute = new Date(new Date().getTime() + 15 * 60000);
  const [tab, setTab] = useState(LANGUAGE_TABS.EN);



  const addQuestion = () => {

    if (tab == LANGUAGE_TABS.EN) {
      props.setEnQuestions([
        ...props.enQuestions,
        {
          qid: `${Date.now()}`,
          qtitle: "",
          qtype: "single-choice",
          options: [],
          is_required: false,
        },
      ]);
    } else {
      props.setZhQuestions([
        ...props.zhQuestions,
        {
          qid: `${Date.now()}`,
          qtitle: "",
          qtype: "single-choice",
          options: [],
          is_required: false,

        },
      ]);
    }

  };

  const updateQuestion = (id, field, value) => {
    if (tab == LANGUAGE_TABS.EN) {
      props.setEnQuestions((prevQuestions) =>
        prevQuestions.map((q) => (q.qid === id ? { ...q, [field]: value } : q))
      );
    } else {
      props.setZhQuestions((prevQuestions) =>
        prevQuestions.map((q) => (q.qid === id ? { ...q, [field]: value } : q))
      );
    }

  };

  const deleteQuestion = (id) => {
    if (tab == LANGUAGE_TABS.EN) {
      props.setEnQuestions(props.enQuestions.filter((q) => q.qid !== id));
    } else {
      props.setZhQuestions(props.zhQuestions.filter((q) => q.qid !== id));
    }

  };

  const copyQuestion = (id) => {
    if (tab == LANGUAGE_TABS.EN) {
      const questionToCopy = props.enQuestions.find((q) => q.qid === id);
      props.setEnQuestions([...props.enQuestions, { ...questionToCopy, qid: Date.now() }]);
    } else {
      const questionToCopy = props.zhQuestions.find((q) => q.qid === id);
      props.setZhQuestions([...props.zhQuestions, { ...questionToCopy, qid: Date.now() }]);
    }

  };

  const onTabChange = (tab) => {
    setTab(tab);
  };


  return (
    <>
      <div className="poll_and_survey_scroll_box">
        <div className="poll_and_survey_tab_content">
          <div className="tabs">
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.EN)}
              className={`tab ${tab === LANGUAGE_TABS.EN ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.EN}
            </div>
            <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.CH)}
              className={`tab ${tab === LANGUAGE_TABS.CH ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.CH}
            </div>
          </div>
        </div>



        <div className="poll_and_survey_items_list">
          {tab == LANGUAGE_TABS.EN ? (
            props.enQuestions.map((question, index) => (
              <Item
                key={question.qid}
                question={question}
                index={index}
                updateQuestion={updateQuestion}
                deleteQuestion={deleteQuestion}
                copyQuestion={copyQuestion}
                types={surveyTypes}
                enQuestions={props.enQuestions}
                zhQuestions={props.zhQuestions}
                tab={tab}
              />
            ))
          ) : (
            props.zhQuestions.map((question, index) => (
              <Item
                key={question.qid}
                question={question}
                index={index}
                updateQuestion={updateQuestion}
                deleteQuestion={deleteQuestion}
                copyQuestion={copyQuestion}
                types={surveyTypes}
                enQuestions={props.enQuestions}
                zhQuestions={props.zhQuestions}
                tab={tab}
              />
            ))
          )

          }
        </div>

        <div
          className="poll_and_survey_add_row_button margin-bottom"
          onClick={addQuestion}
        >
          {" "}
          <div>+ Add Question</div>
        </div>
      </div>
    </>
  );
};
