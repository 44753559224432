import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import {
  checkMultiPermission,
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import { Tooltip } from '@progress/kendo-react-tooltip';
import "./menu.css";
// kendo
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
// routes
import {
  DASHBOARD_ROUTE,
  INBOX_ROUTE,
  NEWSFEED_ROUTE,
  DR_SAFE_INCIDENT_LIST,
  DR_SAFE_CONTACT_LIST,
  DR_SECURE_INCIDENT_LIST,
  DR_SECURE_CONTACT_LIST,
  DR_INFRA_INCIDENT_LIST,
  DR_INFRA_LOCATION_LIST,
  DR_INFRA_SUBSYSTEM_LIST,
  DR_INFRA_SYSTEM_LIST,
  DR_INFRA_CONTACT_LIST,
  ADMIN_TOOLS_CATEGORY_LIST,
  ADMIN_TOOLS_DIVISION_LIST,
  ADMIN_TOOLS_MATTER_LIST,
  IFEEDBACK_LIST,
  FEEDBACK_ON_BUS_SERVICE_CHECKER,
  FEEDBACK_ON_BUS_SERVICE,
  FEEDBACK_ON_BUS_SERVICE_PREFERENCE,
  FEEDBACK_ON_BUS_SERVICE_SERVICES,
  FEEDBACK_ON_BUS_SERVICE_APPROVED,
  ADMIN_TOOLS_MIC_DEPARTMENT,
  ADMIN_TOOLS_MIC_DEPARTMENT_ADD,
  ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT,
  ADMIN_TOOLS_TUTORIAL_LIST,
  USER_MANAGEMENT,
  USER_MANAGEMENT_USERS,
  USER_MANAGEMENT_GROUPS,
  TELEPHONE_DIRECTORY_DEPOT_LIST,
  TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
  TELEPHONE_DIRECTORY_CONTACT_LIST,
  ADMIN_TOOLS_ROLE_LIST,
  ISAFE_MESSAGE,
  ADMIN_TOOLS_EMAIL_GROUP_LIST,
  ADMIN_TOOLS_EMAIL_GROUP_DETAILS,
  ADMIN_TOOLS_EMAIL_GROUP_ADD,
  ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
  ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS,
  ADMIN_TOOLS_EMAIL_TEMPLATE_ADD,
  FEEDBACK_ON_BUS_ASSIGNMENT,
  FEEDBACK_ON_BUS_SUMMARY_OF_CHECK,
  FEEDBACK_ON_BUS_REPORTS,
  POLICY_DOCUMENT_ROUTE,
  BUSINESS_CONTINUITY_ROUTE,
  ASSET_MANAGMENT_ROUTE,
  CARE_ENGLISH_ROUTE,
  CARE_MANDRAIN_ROUTE,
  SAFETY_HEALTH_ROUTE,
  SECURITY_ROUTE,
  VMB_ENGLISH_ROUTE,
  VMB_MANDARIN_ROUTE,
  FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
  CARES_ECARDS_LISTING,
  CARES_APPRECIATION_WALLS_LISTING,
  ADMIN_TOOLS_CARES_ACCESSORY,
  ADMIN_TOOLS_CARES_IMAGES,
  HAPPENINGS_PROMOTIONS_ROUTE,
  HAPPENINGS_ARTICLES_ROUTE,
  ADMIN_TOOLS_CLINIC_LIST,
  PERSONA_CONFIGURATION_LIST,
  RUNTIME_FEEDBACK_LIST,
  HAPPENINGS_CATEGORY_ROUTE,
  RUNTIME_FEEDBACK_REPORT,
  POLL_SURVEY_LIST,
} from "../../routes";
import Logo from "../../assets/dashboard/logo.png";
import LogoutIcon from "../../assets/dashboard/LogoutIcon.png";
import MenuRightArrow from "../../assets/common/menu-arrow-right.svg";
import { getCurrentUserDetails } from "../../config/userPool";
import Icons, { IconSize } from "../icons";

const { signout } = require("../../config/userPool");

const PAGE_KEYS = {
  ADMIN_TOOLS_MIC: "admin_tools_mic",
};

const Navigation = ({ children, selected }) => {
  const [isExpanded, setExpanded] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const userdetails = getCurrentUserDetails();

  useEffect(() => {
    if (userdetails !== null && !!Object.keys(userdetails).length) {
      localStorage.setItem("username", userdetails?.["cognito:username"]);
    }
  }, [userdetails]);

  // const {payload: user_info} = userdetails;
  const [isCommnuicationShow, setIsCommunicationShow] = useState(
    selected === "communication"
  );
  const [isHappeningShow, setIsHappeningShow] = useState(
    selected === "happenings"
  );
  const [isDrSafeShow, setIsDrSafeShow] = useState(selected === "dr_safe");
  const [isDrSecureShow, setIsDrSecureShow] = useState(
    selected === "dr_secure"
  );
  const [isAdminToolsShow, setIsAdminToolsShow] = useState(
    selected === "admin_tools"
  );
  const [isAdminToolsMIC, setisAdminToolsMIC] = useState(
    selected === PAGE_KEYS.ADMIN_TOOLS_MIC
  );
  const [isToolsCommShow, setIsToolsCommShow] = useState(selected === "");
  const [isToolsArticlesShow, setIsToolsArticlesShow] = useState(
    selected === ""
  );
  const [isToolsiSafeShow, setIsToolsiSafeShow] = useState(selected === "");
  const [isToolsIFeedbackShow, SetIsToolsIFeedbackShow] = useState(
    selected === ""
  );
  const [isIFeedbackShow, SetIFeedbackShow] = useState(selected === "feedback");
  const [isPolicyShow, setIsPolicyShow] = useState(
    selected === "policy_documents"
  );
  const [isManageTutorialShow, setIsManageTutorialShow] = useState(
    selected === "manage_tutorial"
  );
  const [isPersonaShow, setIsPersonaShow] = useState(selected === "persona");

  const [isClinicShow, setIsClinicShow] = useState(selected === "clinic");
  const [isFeedbackBusShow, setFeedbackBusShow] = useState(
    selected === "feedback_on_bus"
  );
  const [isBSQCFeedbackBusShow, setBSQCFeedbackBusShow] = useState(
    selected === "BSQC_feedback_on_bus"
  );
  const [isDrInfraShow, setIsDrInfraShow] = useState(selected === "dr_infra");
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [selectedParentmenu, setSelectedParentMenu] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isTelephoneDirectoryShow, setIsTelephoneDirectoryShow] = useState(
    selected === "telephone_directory"
  );
  const [isUserMgmtShow, setIsUserMgmtShow] = useState(
    selected === "user_management"
  );
  const [isRuntimeFeedbackShow, setIsRuntimeFeedbackShow] = useState(
    selected === "runtime_feedback"
  );
  const [isFeedbackBusServiceShow, setIsFeedbackBusServiceShow] = useState(
    selected === "feedback_bus_service"
  );
  const [isCaresShow, setCaresShow] = useState(selected === "cares");
  const [isToolCaresShow, setIsToolCaresShow] = useState(
    (selected = "admin_cares")
  );
  console.log("selected:", selected, isUserMgmtShow);

  console.log(userdetails);
  // drawer items
  const items = [
    {
      text: "",
      src: { Logo },
      route: DASHBOARD_ROUTE,
      selected: false,
      id: "logo",
      class: "sticky_logo",
    },
    {
      text: "Dashboard",
      route: DASHBOARD_ROUTE,
      selected: true,
      id: "dashboard",
      permission: ["dashboard.access_dashboard"],
    },
    {
      text: "Communication",
      // route: "/communication",
      selected: true,
      id: "communication",
      flag: isCommnuicationShow,
      setFlag: setIsCommunicationShow,
      permission: ["comms.can_access_comms"],
      sub_menu: [
        {
          id: "inbox",
          route: INBOX_ROUTE,
          text: "Inbox",
          selected: false,
          parent_id: "communication",
          permission: [
            "comms.access_comms_inbox",
            "comms.limited_access_comms_inbox",
          ],
        },
        {
          id: "poll_survey",
          route: POLL_SURVEY_LIST,
          text: "Poll Surveys",
          selected: false,
          parent_id: "communication",
          permission: [
            "survey.access_survey_polls",
            "survey.view_all_surveys",
            "survey.view_as_collaborator",
            "survey.submit_survey"
          ],
        },
        // {
        //   id: "newsfeed",
        //   route: NEWSFEED_ROUTE,
        //   text: "Newsfeed",
        //   selected: false,
        //   parent_id: "communication",
        // },
      ],
    },
    {
      text: "Happenings",
      // route: HAPPENINGS_PROMOTIONS_ROUTE,
      selected: true,
      id: "happenings",
      flag: isHappeningShow,
      setFlag: setIsHappeningShow,
      permission: ["happening.access_happening"],
      sub_menu: [
        {
          id: "promotions",
          route: HAPPENINGS_PROMOTIONS_ROUTE,
          text: "Promotions",
          selected: false,
          parent_id: "happenings",
          permission: ["happening.access_happening_promotion_list"],
        },
        {
          id: "articles",
          route: HAPPENINGS_ARTICLES_ROUTE,
          text: "Articles",
          selected: false,
          parent_id: "happenings",
          permission: ["happening.access_happening_article_list"],
        },
        {
          id: "category",
          route: HAPPENINGS_CATEGORY_ROUTE,
          text: "Manage Category",
          selected: false,
          parent_id: "happenings",
          permission: ["admin_tools.access_comms_category"],
        },
      ],
    },
    {
      text: "Employee Welfare",
      // route: ADMIN_TOOLS_CLINIC_LIST,
      selected: true,
      id: "employee_welfare",
      permission: ["clinic.access_clinic"],
      flag: isClinicShow,
      setFlag: setIsClinicShow,
      sub_menu: [
        {
          text: "Manage Panel GP Clinics",
          route: ADMIN_TOOLS_CLINIC_LIST,
          selected: false,
          id: "clinic",
          parent_id: "employee_welfare",
          permission: ["clinic.access_clinic"],
        },
      ],
    },
    {
      text: "Feedback",
      // route: "/feedback",
      selected: true,
      id: "feedback",
      permission: ["ifeedback.access_ifeedback"],
      flag: isIFeedbackShow,
      setFlag: SetIFeedbackShow,
      sub_menu: [
        {
          id: "ifeedback",
          route: IFEEDBACK_LIST,
          text: "iFeedback",
          selected: false,
          parent_id: "feedback",
        },
      ],
    },
    // {
    //   text: "Policy Documents",
    //   route: "/policy_document",
    //   selected: true,
    //   id: "policy_documents",
    //   // permission: ["ifeedback.access_ifeedback"],
    //   flag: isPolicyShow,
    //   setFlag: setIsPolicyShow,
    //   sub_menu: [
    // {
    //   id: "asset_managment",
    //   route: ASSET_MANAGMENT_ROUTE,
    //   text: "Asset Managment",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "business_continuity",
    //   route: BUSINESS_CONTINUITY_ROUTE,
    //   text: "Business Continuity",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "cares_english",
    //   route: CARE_ENGLISH_ROUTE,
    //   text: "CARES (English)",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "cares_mandrain",
    //   route: CARE_MANDRAIN_ROUTE,
    //   text: "CARES (Mandarin)",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "safety_health",
    //   route: SAFETY_HEALTH_ROUTE,
    //   text: "Safety and Health",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "security",
    //   route: SECURITY_ROUTE,
    //   text: "Security",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "vmb_english",
    //   route: VMB_ENGLISH_ROUTE,
    //   text: "VMB (English)",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    // {
    //   id: "vmb_mandrain",
    //   route: VMB_MANDARIN_ROUTE,
    //   text: "VMB (Mandarin)",
    //   selected: false,
    //   parent_id: "policy_documents",
    // },
    //   ],
    // },
    {
      text: "Feedback on Bus Service",
      // route: FEEDBACK_ON_BUS_SERVICE,
      selected: true,
      id: "feedback_on_bus",
      permission: [
        "fob.access_bsqc",
        "fob.bsqc_access_check_periods",
        "fob.bsqc_access_checker_preference",
        "fob.access_report",
        "fob.access_summary_of_checks",
        "fob.access_assignment",
      ],
      flag: isFeedbackBusShow,
      setFlag: setFeedbackBusShow,
      sub_menu: [
        {
          id: "feedback_on_bus_assignment",
          route: FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
          text: "Assignment",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_assignment"],
        },
        {
          id: "BSQC",
          route: FEEDBACK_ON_BUS_SERVICE_CHECKER,
          text: "BSQC",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_bsqc"],
          flag: isBSQCFeedbackBusShow,
          setFlag: setBSQCFeedbackBusShow,
          sub_menu: [
            {
              id: "feedback_on_bus_checker_periods",
              route: FEEDBACK_ON_BUS_SERVICE_CHECKER,
              text: "BSQC Check Periods",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_check_periods"],
            },
            {
              id: "feedback_on_bus_checkers_preference",
              route: FEEDBACK_ON_BUS_SERVICE_PREFERENCE,
              text: "Checkers Preference",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_checker_preference"],
            },
            {
              id: "feedback_on_bus_selected_services",
              route: FEEDBACK_ON_BUS_SERVICE_SERVICES,
              text: "Select Services",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_select_services"],
            },
            {
              id: "feedback_on_bus_approved_assignments",
              route: FEEDBACK_ON_BUS_SERVICE_APPROVED,
              text: "Approved Assignments",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_approved_assignment"],
            },
          ],
        },
        // {
        //   id: "feedback_on_bus_assignment",
        //   route: FEEDBACK_ON_BUS_ASSIGNMENT,
        //   text: "Assignment",
        //   selected: false,
        //   parent_id: "feedback_on_bus",
        //   permission: ["fob.access_assignment"],
        // },
        {
          id: "feedback_on_bus_summary_of_check",
          route: FEEDBACK_ON_BUS_SUMMARY_OF_CHECK,
          text: "Summary of Check",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_summary_of_checks"],
        },
        {
          id: "feedback_on_bus_reports",
          route: FEEDBACK_ON_BUS_REPORTS,
          text: "Reports",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_report"],
        },
      ],
    },
    {
      text: "MIC",
      route: "/mic",
      selected: true,
      id: "mic",
      permission: ["my_ideas.can_access_mic"],
    },
    {
      text: "DR Infra",
      // route: "/dr_infra",
      selected: true,
      id: "dr_infra",
      permission: [
        "dr_infra.view_admin_incidents",
        "dr_infra.limited_view_incident",
      ],
      flag: isDrInfraShow,
      setFlag: setIsDrInfraShow,
      sub_menu: [
        {
          id: "dr_infra_incident_list",
          route: DR_INFRA_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_admin_incidents",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_location_listing",
          route: DR_INFRA_LOCATION_LIST,
          text: "Location Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_locations",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_subsytem_listing",
          route: DR_INFRA_SUBSYSTEM_LIST,
          text: "Sub System Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_subsystems",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_sytem_listing",
          route: DR_INFRA_SYSTEM_LIST,
          text: "System ID Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_systems",
            "dr_infra.limited_view_incident",
          ],
        },
      ],
    },
    {
      text: "DR Safe",
      // route: DR_SAFE_INCIDENT_LIST,
      selected: false,
      id: "dr_safe",
      permission: [
        "dr_safe.view_admin_incidents",
        "dr_safe.limited_view_incident",
      ],
      flag: isDrSafeShow,
      setFlag: setIsDrSafeShow,
      sub_menu: [
        {
          id: "dr_safe_incident_list",
          route: DR_SAFE_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_safe",
        },
      ],
    },
    {
      text: "DR Secure",
      // route: DR_SECURE_INCIDENT_LIST,
      selected: true,
      id: "dr_secure",
      permission: [
        "dr_secure.view_admin_incidents",
        "dr_secure.limited_view_incident",
      ],
      flag: isDrSecureShow,
      setFlag: setIsDrSecureShow,
      sub_menu: [
        {
          id: "dr_secure_incident_list",
          route: DR_SECURE_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_secure",
        },
      ],
    },
    {
      text: "CARES",
      // route: CARES_ECARDS_LISTING,
      selected: true,
      id: "cares",
      permission: [
        "cares.access_cares_ecard_list",
        "cares.access_cares_appwall_list",
      ],
      flag: isCaresShow,
      setFlag: setCaresShow,
      sub_menu: [
        {
          id: "cares_ecard",
          route: CARES_ECARDS_LISTING,
          text: "eCard",
          selected: false,
          parent_id: "cares",
          permission: ["cares.access_cares_ecard_list"],
        },
        {
          id: "cares_appreciationwalls",
          route: CARES_APPRECIATION_WALLS_LISTING,
          text: "Appreciation Wall",
          selected: false,
          parent_id: "cares",
          permission: ["cares.access_cares_appwall_list"],
        },
        {
          id: "ecard_accessory",
          route: ADMIN_TOOLS_CARES_ACCESSORY,
          text: "Manage Accessory",
          selected: false,
          parent_id: "cares",
          // permission: ["telephone_directory.access_depot_subdepot_listing"],
        },
        {
          id: "ecard_image",
          route: ADMIN_TOOLS_CARES_IMAGES,
          text: "Manage Images  ",
          selected: false,
          parent_id: "cares",
          // permission: ["telephone_directory.access_department_listing"],
        },
      ],
    },
    {
      text: "Manage Tutorial",
      route: ADMIN_TOOLS_TUTORIAL_LIST,
      selected: false,
      id: "manage_tutorial",
      permission: ["tutorial.access_tutorial"],
      flag: isManageTutorialShow,
    },
    // {
    //   text: "Telephone Directory",
    //   route: "/telephone_directory",
    //   selected: true,
    //   id: "telephone_directory",
    //   flag: isTelephoneDirectoryShow,
    //   setFlag: setIsTelephoneDirectoryShow,
    //   permission: [
    //     "telephone_directory.access_depot_subdepot_listing",
    //     "telephone_directory.access_department_listing",
    //     "telephone_directory.access_directory_listing"

    //   ],
    //   sub_menu: [
    //     {
    //       id: "depot",
    //       route: TELEPHONE_DIRECTORY_DEPOT_LIST,
    //       text: "Depot/Sub Depot Listing",
    //       selected: false,
    //       parent_id: "telephone_directory",
    //       permission: ["telephone_directory.access_depot_subdepot_listing"],
    //     },
    //     {
    //       id: "department",
    //       route: TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
    //       text: "Department Listing",
    //       selected: false,
    //       parent_id: "telephone_directory",
    //       permission: ["telephone_directory.access_department_listing"],
    //     },
    //     {
    //       id: "directory",
    //       route: TELEPHONE_DIRECTORY_CONTACT_LIST,
    //       text: "Directory Listing",
    //       selected: false,
    //       parent_id: "telephone_directory",
    //       permission: ["telephone_directory.access_directory_listing"],
    //     },
    //   ],
    // },
    {
      text: "Admin Tools",
      // route: "/admin_tools",
      selected: true,
      id: "admin_tools",
      permission: ["admin_tools.can_access_admin_tools"],
      flag: isAdminToolsShow,
      setFlag: setIsAdminToolsShow,
      sub_menu: [
        // {
        //   id: "articles_tools",
        //   route: ADMIN_TOOLS_ARTICLES_CATEGORY_ROUTE,
        //   text: "Happenings",
        //   selected: false,
        //   parent_id: "admin_tools",
        //   flag: isToolsArticlesShow,
        //   setFlag: setIsToolsArticlesShow,
        //   permission: [
        //     "admin_tools.access_comms_category",
        //     "admin_tools.access_comms_roles",
        //     "admin_tools.access_comms_division",
        //   ],
        //   sub_menu: [
        //     {
        //       id: "articles_tools_manage_category",
        //       route: ADMIN_TOOLS_ARTICLES_CATEGORY_ROUTE,
        //       text: "Manage Category",
        //       selected: false,
        //       parent_id: "articles_tools",
        //       permission: ["admin_tools.access_comms_category"],
        //     },
        //   ],
        // },
        {
          id: "communication_tools",
          route: ADMIN_TOOLS_CATEGORY_LIST,
          text: "Communication",
          selected: false,
          parent_id: "admin_tools",
          flag: isToolsCommShow,
          setFlag: setIsToolsCommShow,
          permission: [
            "admin_tools.access_comms_category",
            "admin_tools.access_comms_roles",
            "admin_tools.access_comms_division",
          ],
          sub_menu: [
            {
              id: "tools_category",
              route: ADMIN_TOOLS_CATEGORY_LIST,
              text: "Manage Category",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_category"],
            },
            {
              id: "tools_role",
              route: ADMIN_TOOLS_ROLE_LIST,
              text: "Manage Role",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_roles"],
            },
            {
              id: "tools_division",
              route: ADMIN_TOOLS_DIVISION_LIST,
              text: "Manage Divisions",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_division"],
            },
          ],
        },
        {
          text: "Policy Documents",
          route: POLICY_DOCUMENT_ROUTE,
          selected: false,
          id: "policy_documents",
          parent_id: "admin_tools",
          permission: ["admin_tools.access_VMB_policy_docs"],
          flag: isPolicyShow,
        },
        {
          id: "isafe_tools",
          route: ISAFE_MESSAGE,
          text: "iSafe",
          selected: false,
          parent_id: "admin_tools",
          flag: isToolsiSafeShow,
          setFlag: setIsToolsiSafeShow,
          permission: ["admin_tools.access_isafe_safety_message"],
        },
        {
          id: "ifeedback_tools",
          permission: ["admin_tools.access_ifeedback_matter"],
          // route: ADMIN_TOOLS_MATTER_LIST,
          text: "iFeedback",
          selected: false,
          parent_id: "admin_tools",
          flag: isToolsIFeedbackShow,
          setFlag: SetIsToolsIFeedbackShow,
          sub_menu: [
            {
              id: "tools_matter",
              route: ADMIN_TOOLS_MATTER_LIST,
              text: "Manage Matter",
              selected: false,
              parent_id: "ifeedback_tools",
            },
          ],
        },
        {
          id: "mic_tools",
          permission: [
            "admin_tools.access_MIC_department",
            "admin_tools.access_MIC_type_of_improvement",
          ],
          // route: ADMIN_TOOLS_MIC_DEPARTMENT,
          selected: false,
          text: "MIC",
          parent_id: "admin_tools",
          flag: isAdminToolsMIC,
          setFlag: setisAdminToolsMIC,
          sub_menu: [
            {
              id: "mic_tools_department",
              route: ADMIN_TOOLS_MIC_DEPARTMENT,
              text: "Manage MIC Department",
              selected: false,
              permission: "admin_tools.access_MIC_department",
              parent_id: "admin_tools",
            },
            {
              id: "mic_tools_type_of_improvement",
              route: ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT,
              text: "Manege MIC Type of Improvement",
              permission: "admin_tools.access_MIC_type_of_improvement",
              selected: false,
              parent_id: "admin_tools",
            },
          ],
        },
        {
          id: "feedback_bus_service",
          permission: [
            "admin_tools.access_FOB_email_groups",
            "admin_tools.access_FOB_email_templates",
          ],
          // route: ADMIN_TOOLS_EMAIL_GROUP_LIST,
          selected: false,
          text: "Feedback on Bus Service",
          parent_id: "admin_tools",
          flag: isFeedbackBusServiceShow,
          setFlag: setIsFeedbackBusServiceShow,
          sub_menu: [
            {
              id: "feedback_bus_service_email_group",
              route: ADMIN_TOOLS_EMAIL_GROUP_LIST,
              text: "Manage Email Groups",
              selected: false,
              permission: "admin_tools.access_FOB_email_groups",
              parent_id: "feedback_bus_service",
            },
            {
              id: "feedback_bus_service_email_template",
              route: ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
              text: "Manage Email Templates",
              selected: false,
              permission: "admin_tools.access_FOB_email_templates",
              parent_id: "feedback_bus_service",
            },
          ],
        },
      ],
    },
    {
      id: "telephone_directory",
      text: "Telephone Directory",
      // route: "/telephone_directory",
      selected: true,
      flag: isTelephoneDirectoryShow,
      setFlag: setIsTelephoneDirectoryShow,
      permission: [
        "telephone_directory.access_depot_subdepot_listing",
        "telephone_directory.access_department_listing",
        "telephone_directory.access_directory_listing",
      ],
      sub_menu: [
        {
          id: "depot",
          route: TELEPHONE_DIRECTORY_DEPOT_LIST,
          text: "Depot/Sub Depot Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_depot_subdepot_listing"],
        },
        {
          id: "department",
          route: TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
          text: "Department Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_department_listing"],
        },
        {
          id: "directory",
          route: TELEPHONE_DIRECTORY_CONTACT_LIST,
          text: "Directory Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_directory_listing"],
        },
      ],
    },
    {
      id: "runtime_feedback",
      // route: USER_MANAGEMENT,
      selected: true,
      text: "Run Time Feedback",
      flag: isRuntimeFeedbackShow,
      setFlag: setIsRuntimeFeedbackShow,
      permission: [
        "runtime_feedback.access_runtime_feedback_list",
        "runtime_feedback.access_runtime_feedback_report"
      ],
      sub_menu: [
        {
          id: "rt_feedbackList",
          route: RUNTIME_FEEDBACK_LIST,
          text: "RT Feedback List",
          selected: false,
          parent_id: "runtime_feedback",
          permission: ["runtime_feedback.access_runtime_feedback_list"],
        },
        {
          id: "rt_feedbackReport",
          route: RUNTIME_FEEDBACK_REPORT,
          text: "Report",
          selected: false,
          parent_id: "runtime_feedback",
          permission: ["runtime_feedback.access_runtime_feedback_report"],
        },
      ],
    },
    {
      id: "user_management",
      // route: USER_MANAGEMENT,
      selected: true,
      text: "User Management",
      flag: isUserMgmtShow,
      setFlag: setIsUserMgmtShow,
      permission: [
        "user_management.access_group_management",
        "user_management.access_user_management",
      ],
      sub_menu: [
        {
          id: "user_management_users",
          route: USER_MANAGEMENT_USERS,
          text: "Users",
          selected: false,
          parent_id: "user_management",
          permission: ["user_management.access_user_management"],
        },
        {
          id: "user_management_groups",
          route: USER_MANAGEMENT_GROUPS,
          text: "Groups",
          selected: false,
          parent_id: "user_management",
          permission: ["user_management.access_group_management"],
        },
        {
          text: "Persona Configurations",
          route: PERSONA_CONFIGURATION_LIST,
          selected: false,
          id: "persona_configuration",
          permission: ["admin_tools.access_persona"],
          flag: isPersonaShow,
        },
      ],
    },
    // {
    //   text: "iFeedback",
    //   route: "/i_feedback",
    //   selected: true,
    //   id: "i_feedback",
    // }
  ];

  // const username = getusername();
  // localStorage.getItem("username");

  const getUserName = () => {
    return localStorage.getItem("username");
  };

  const userPermissions = getUserPermission();
  if (userPermissions) {
    for (let i = 0; i < items.length; i++) {
      if (!items[i].permission) {
        items[i].is_visible = true;
      } else {
        if (checkMultiPermission(userPermissions, items[i].permission)) {
          items[i].is_visible = true;
        } else {
          items[i].is_visible = false;
        }
      }

      if (items[i].sub_menu?.length > 0) {
        for (let j = 0; j < items[i].sub_menu.length; j++) {
          let sub_menu = items[i].sub_menu[j];
          if (!sub_menu.permission) {
            sub_menu.is_visible = true;
          } else {
            if (
              sub_menu.permission.find(
                (i) => i === "dr_infra.limited_view_incident"
              )
            ) {
              if (
                checkPermission(
                  userPermissions,
                  "dr_infra.limited_view_incident"
                )
              ) {
                console.log(
                  checkPermission(
                    userPermissions,
                    "dr_infra.limited_view_incident"
                  )
                );
                console.log("This one shouldn't do");
                const filter = sub_menu.permission.filter(
                  (i) => i !== "dr_infra.limited_view_incident"
                );
                if (filter.find((j) => j === "dr_infra.view_admin_incidents")) {
                  sub_menu.is_visible = true;
                } else {
                  sub_menu.is_visible = false;
                }
              } else {
                console.log("This one should be doing");
                const filter = sub_menu.permission.filter(
                  (i) => i !== "dr_infra.limited_view_incident"
                );

                if (checkMultiPermission(userPermissions, filter)) {
                  sub_menu.is_visible = true;
                } else {
                  sub_menu.is_visible = false;
                }
              }
            } else {
              if (checkMultiPermission(userPermissions, sub_menu.permission)) {
                sub_menu.is_visible = true;
              } else {
                sub_menu.is_visible = false;
              }
            }
          }
        }
      }
    }
  }

  const display_block = {
    display: "block",
  };
  const display_none = {
    display: "none",
  };
  const sub_child_selected = { color: "#781d7e" };
  const sub_child_notselected = { color: "#727272" };
  const child_selected = { backgroundColor: "#E7ECEF" };

  useEffect(() => {
    const current_items = items.filter((v) => v.id === selected);
    console.log(selected);
    console.log(location.pathname);
    current_items.map((v) => {
      if (v.sub_menu) {
        console.log(v.sub_menu.filter((v) => v.id === location.pathname));
      }
    });
  }, []);

  const CustomItem = (props) => {
    if (props.sub_menu) {
      return (
        <div className={props.is_visible ? "" : "display-none"}>
          <DrawerItem {...props}>
            <div
              className="item-descr-wrap"
              onClick={() =>
                props.flag ? props.setFlag(false) : props.setFlag(true)
              }
            >
              <div style={{ fontSize: 16 }}>{props.text}</div>
              <Icons
                className="fa fa-chevron-down"
                size={IconSize.small}
                hidden={props.flag}
              />
              <Icons
                className="fa fa-chevron-up"
                size={IconSize.small}
                hidden={!props.flag}
              />
            </div>
          </DrawerItem>
          {props.flag && (
            <div>
              {props.sub_menu.map((child) => (
                <>
                  <DrawerItem
                    className={
                      child.is_visible
                        ? "Drawer-submenu"
                        : "Drawer-submenu display-none"
                    }
                    onSelect={
                      child.sub_menu
                        ? () => {
                          child.flag
                            ? child.setFlag(false)
                            : child.setFlag(true);
                        }
                        : onSelect_Submenu
                    }
                    style={
                      selectedSubmenu === child.id ||
                        selectedParentmenu === child.id
                        ? child_selected
                        : {}
                    }
                    {...child}
                  >
                    <div className="item-descr-wrap">
                      <div
                        style={{
                          fontSize: 16,
                          color:
                            child.id === selectedSubmenu
                              ? "rgb(120, 29, 126)"
                              : "inherit",
                        }}
                      >
                        {child.text}
                      </div>
                      {child.sub_menu && (
                        <>
                          <i
                            className="fa fa-chevron-down"
                            style={child.flag ? display_none : display_block}
                            onClick={() => setIsToolsCommShow(true)}
                          ></i>
                          <i
                            className="fa fa-chevron-up"
                            style={child.flag ? display_block : display_none}
                            onClick={() => setIsToolsCommShow(false)}
                          ></i>
                        </>
                      )}
                      <i
                        className="fa fa-caret-right"
                        style={
                          child.id === selectedSubmenu
                            ? { ...display_block, color: "rgb(120, 29, 126)" }
                            : display_none
                        }
                      ></i>
                    </div>
                  </DrawerItem>
                  {child.flag ? (
                    <>
                      {child.sub_menu.map((sub_child) => (
                        <>
                          <DrawerItem
                            className="Drawer-submenu"
                            onSelect={onSelect_Submenu}
                            style={
                              sub_child.id === selectedSubmenu
                                ? sub_child_selected
                                : sub_child_notselected
                            }
                            {...sub_child}
                          >
                            <div className="item-descr-wrap">
                              {sub_child.text}
                              <i
                                className="fa fa-caret-right"
                                style={
                                  sub_child.id === selectedSubmenu
                                    ? display_block
                                    : display_none
                                }
                              ></i>
                            </div>
                          </DrawerItem>
                        </>
                      ))}
                    </>
                  ) : null}
                </>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          className={
            props.is_visible
              ? props.class
                ? `${props.class}`
                : ""
              : `${props.class} display-none`
          }
          style={{
            backgroundColor:
              !props.src && location.pathname === props.route ? "#73007D" : "",
            color:
              !props.src && location.pathname === props.route ? "white" : "",
          }}
        >
          <DrawerItem
            {...props}
            className={location.pathname === props.route && "disabled"}
          >
            {props.src ? (
              <div className="item-descr-wrap" style={{ padding: "0" }}>
                <div className="Dashboard-Logo">
                  <img src={Logo} width="168" />
                </div>
              </div>
            ) : (
              <div className="item-descr-wrap">
                <div>{props.text}</div>
                <i
                  className="fa fa-chevron-right"
                  style={{ ...display_block }}
                />
              </div>
            )}
          </DrawerItem>
        </div>
      );
    }
  };

  const onSelect = (ev) => {
    console.log(ev);
    setSelectedSubmenu(null);
    setSelectedParentMenu(null);
    if (isMobile) {
      navigate(ev.itemTarget.route);
    } else {
      navigate(ev.itemTarget.props.route);
    }
  };

  const onExpandedMenu = () => setExpanded((prev) => !prev);

  const onSelect_Submenu = (e) => {
    const { route, id, parent_id } = e.props;
    console.log("SubMenu: ", e.props);
    setSelectedSubmenu(id);
    setSelectedParentMenu(parent_id);
    navigate(route);
  };
  const logoutUser = () => {
    signout();
    localStorage.removeItem("login");
    localStorage.removeItem("username");
    localStorage.removeItem("user_permissions");
    navigate("/");
    setVisible(!visible);
  };

  return (
    <div>
      {visible && (
        <Dialog
          onClose={() => setVisible(!visible)}
          style={{ borderRadius: "8px" }}
        >
          <span style={{ float: "right", cursor: "pointer" }}>
            <img onClick={() => setVisible(!visible)} />
          </span>
          <p className="logout_popup_box">Are you sure you want to logout?</p>
          <div className="logout_popup_action_box">
            <button
              className="logout_popup_action_button"
              onClick={() => setVisible(!visible)}
              style={{ color: "#781D7E", background: "#FFFFFF" }}
            >
              No, cancel
            </button>
            <button
              className="logout_popup_action_button"
              onClick={logoutUser}
              style={{ background: "#781D7E", color: "white" }}
            >
              Yes, logout
            </button>
          </div>
        </Dialog>
      )}
      <div style={{ background: "#FFFFFF" }}>
        <Drawer
          expanded={isExpanded}
          position={"start"}
          mode={"push"}
          items={items.map((item) => ({
            ...item,
            selected: item.id.toLowerCase() === selected.toLowerCase(),
          }))}
          item={CustomItem}
          onSelect={onSelect}
          className="navigation-div"
        >
          <DrawerContent style={{ background: "#FFFFFF" }}>
            <div
              style={{
                background: "#FFFFFF",
                position: "sticky",
                top: 0,
                zIndex: 20,
              }}
            >
              <div className="app-wrapper__header">
                <div className="topbar">
                  <div className="topbar__left">
                    <Button
                      icon="menu"
                      fillMode="flat"
                      style={{ color: "#781d7e" }}
                      onClick={onExpandedMenu}
                    />
                    <div className="topbat__left-title">
                      <h3 className="bar-title">iLink Management</h3>
                    </div>
                  </div>
                  <div
                    className="topbar__right"
                    onClick={() => setVisible(true)}
                  >

                    <Tooltip anchorElement="target" parentTitle={true}>
                      <div className="logout-box" title={'Logout'}>
                        <img
                          alt="icon"
                          className="logout-icon"
                          src={LogoutIcon}
                          width={24}
                          height={24}
                        />
                        <p className="logout_user">
                          {userdetails ? userdetails.name : ""}
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </DrawerContent>
        </Drawer>

        {/* <div className="custom-toolbar">
              <div className="topbar-right" onClick={()=>setVisible(true)}>
                  <div className="logout-box">
                  <img className="logout-icon" src={LogoutIcon} width={24} height={24}/>
                  <p className="logout_user">David Tan</p>
                  </div>
              </div>
              <div onClick={() => setVisible(true)}>
                <div className="logout-box">
                  <img
                    className="logout-icon"
                    src={LogoutIcon}
                    width={24}
                    height={24}
                  />
                  <p className="logout_user">{userdetails? userdetails.name: ""}</p>
                </div>
              </div>
          </div> */}
      </div>
    </div>
  );
};

export default Navigation;
