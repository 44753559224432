// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionToken } from "../config/userPool";

const {
  RUNTIME_FEEDBACK_REPORT_MONTH_TICKET_STATUS,
  RUNTIME_FEEDBACK_REPORT_TICKET_STATUS,
  RUNTIME_FEEDBACK_REPORT_TOP_SERVICE,
  RUNTIME_FEEDBACK_REPORT_TOP_BC,
  RUNTIME_FEEDBACK_REPORT_TOP_GROUP,
} = config.api_endpoint;

const initialState = {
  loading: false,
  error: null,
  month_ticket_status_list: [],
  month_ticket_status_loading: false,
  ticket_status_list: [],
  ticket_status_loading: false,
  top_request_service_list: [],
  top_request_service_loading: false,
  top_request_bc_list: [],
  top_request_bc_loading: false,
  top_request_group_list: [],
  top_request_group_loading: false,

};

export const fetchMonthTicketStatusList = createAsyncThunk(
  "runtime_feedback_report/fetchMonthTicketStatusList",
  async ({start_date,end_date}, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${RUNTIME_FEEDBACK_REPORT_MONTH_TICKET_STATUS}?start_date=${start_date}&end_date=${end_date}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const fetchTicketStatusList = createAsyncThunk(
  "runtime_feedback_report/fetchTicketStatusList",
  async ({start_date,end_date}, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${RUNTIME_FEEDBACK_REPORT_TICKET_STATUS}?start_date=${start_date}&end_date=${end_date}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchTopServiceList = createAsyncThunk(
  "runtime_feedback_report/fetchTopServiceList",
  async ({start_date,end_date}, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${RUNTIME_FEEDBACK_REPORT_TOP_SERVICE}?start_date=${start_date}&end_date=${end_date}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchTopBCList = createAsyncThunk(
  "runtime_feedback_report/fetchTopBCList",
  async ({start_date,end_date}, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${RUNTIME_FEEDBACK_REPORT_TOP_BC}?start_date=${start_date}&end_date=${end_date}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);


export const fetchTopGroupList = createAsyncThunk(
  "runtime_feedback_report/fetchTopGroupList",
  async ({start_date,end_date}, { rejectWithValue }) => {
    try {
      const token = getCurrentLoggedInSessionToken();
      const url = `${RUNTIME_FEEDBACK_REPORT_TOP_GROUP}?start_date=${start_date}&end_date=${end_date}`;
      const results = await api.get(url, {}, token);

      return results;
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const runtimeFeedbackSlice = createSlice({
  name: "runtime_feedback_report",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthTicketStatusList.pending, (state) => {
        state.loading = true;
        state.month_ticket_status_loading = true;        

      })
      .addCase(fetchMonthTicketStatusList.fulfilled, (state, action) => {
        state.loading = false;
        state.month_ticket_status_loading = false;        
        state.month_ticket_status_list = action.payload.data;        
      })
      .addCase(fetchMonthTicketStatusList.rejected, (state, action) => {
        state.loading = false;
        state.month_ticket_status_loading = false;        
        state.error = action.error.message;
      })

      .addCase(fetchTicketStatusList.pending, (state) => {
        state.loading = true;
        state.ticket_status_loading = true;        

      })
      .addCase(fetchTicketStatusList.fulfilled, (state, action) => {
        state.loading = false;
        state.ticket_status_loading = false;        
        state.ticket_status_list = action.payload.data;
      })
      .addCase(fetchTicketStatusList.rejected, (state, action) => {
        state.loading = false;
        state.ticket_status_loading = false;        
        state.error = action.error.message;
      })

      .addCase(fetchTopServiceList.pending, (state) => {
        state.loading = true;
        state.top_request_service_loading = true;        
      })
      .addCase(fetchTopServiceList.fulfilled, (state, action) => {
        state.loading = false;
        state.top_request_service_loading = false;        
        state.top_request_service_list = action.payload.data;
      })
      .addCase(fetchTopServiceList.rejected, (state, action) => {
        state.loading = false;
        state.top_request_service_loading = false;        
        state.error = action.error.message;
      })

      .addCase(fetchTopBCList.pending, (state) => {
        state.loading = true;
        state.top_request_bc_loading = true;        
      })
      .addCase(fetchTopBCList.fulfilled, (state, action) => {
        state.loading = false;
        state.top_request_bc_loading = false;        
        state.top_request_bc_list = action.payload.data;
      })
      .addCase(fetchTopBCList.rejected, (state, action) => {
        state.loading = false;
        state.top_request_bc_loading = false;        
        state.error = action.error.message;
      })

      .addCase(fetchTopGroupList.pending, (state) => {
        state.loading = true;
        state.top_request_group_loading = true;        
      })
      .addCase(fetchTopGroupList.fulfilled, (state, action) => {
        state.loading = false;
        state.top_request_group_loading = false;        
        state.top_request_group_list = action.payload.data;
      })
      .addCase(fetchTopGroupList.rejected, (state, action) => {
        state.loading = false;
        state.top_request_group_loading = false;        
        state.error = action.error.message;
      })

  },
});

const RuntimeFeedbackReducer = runtimeFeedbackSlice.reducer;
export default RuntimeFeedbackReducer;
