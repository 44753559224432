import React from "react";
import TotalECardIcon from "../../../assets/dashboard/cares_total.svg";
import TotalAppreciationIcon from "../../../assets/dashboard/care_total_apperciation.svg";
import { PieChartComponents } from "../components/pieChart";
import { pieChartColor } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { NoPermission } from "../components/noPermission";

export const DashboardCare = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">CARES</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div className="dahsboard_care_main_box">
            <div className="dahsboard_care_first_box">
              <div className="dahsboard_care_first_box_first_child">
                <div className="dashboard_care_first_box_first_child_detail">
                  <div>
                    <img src={TotalECardIcon} />
                  </div>
                  <div className="dashboard_care_first_box_first_child_detail_text">
                    Total CARES eCard
                  </div>
                  <div className="dashboard_care_first_box_first_child_detail_count">
                    {props.data && props.data.pendingapproval
                      ? props.data.pendingapproval
                      : 0}
                  </div>
                </div>
                <div className="dashboard_care_first_box_first_child_detail">
                  <div>
                    <img src={TotalAppreciationIcon} />
                  </div>
                  <div className="dashboard_care_first_box_first_child_detail_text">
                    Total appreciations
                  </div>
                  <div className="dashboard_care_first_box_first_child_detail_count">
                    {props.data && props.data.pendingapproval
                      ? props.data.pendingapproval
                      : 0}
                  </div>
                </div>
              </div>
              <div className="dahsboard_care_first_box_last_child">
                <div className="dashboard_graph_title_box">
                  <div className="dahsboard_care_second_box_title">
                    CARES submissions by business area
                  </div>
                  <div
                    className="dashboard_care_see_all_button"
                    onClick={() =>
                      props.handleNavigate &&
                      props.handleNavigate(
                        props.route ? props.route : "/dashboard"
                      )
                    }
                  >
                    See all
                  </div>
                </div>
                <div className="dashboard_care_second_item_box_child">
                  {props.data.groupbybusinessarea &&
                  props.data.groupbybusinessarea.length > 0 ? (
                    props.data.groupbybusinessarea
                      .slice(0, 4)
                      .map((i, index) => (
                        <div
                          key={index}
                          className="dashboard_care_second_item_box_child_item"
                        >
                          <div className="dashboard_care_second_item_box_child_item_detail">
                            <div>{index + 1}.</div>
                            <div>{i.name}</div>
                          </div>
                          <div>{i.value}</div>
                        </div>
                      ))
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </div>
            </div>
            <div className="dahsboard_care_second_box">
              <div className="dashboard_care_chart_second_box_title">
                Status Distribution
              </div>
              <div style={{ height: "calc(100% - 30px)" }}>
                {props.data &&
                props.data.group_by_status &&
                props.data.group_by_status.length > 0 ? (
                  <PieChartComponents
                    data={
                      props.data && props.data.group_by_status
                        ? props.data.group_by_status
                        : []
                    }
                    Legendheight={"calc(100% - 300px)"}
                    colors={pieChartColor}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
