import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import RefreshIcon from "../../../assets/header/refresh.svg";
import ExportIcon from "../../../assets/header/export.svg";
import {
  fetchMonthTicketStatusList,
  fetchTicketStatusList,
  fetchTopServiceList,
  fetchTopBCList,
  fetchTopGroupList
} from "../../../store/runtime_feedback";
import "./runtime_feedback_report.css";
import { useLocation, useNavigate } from "react-router";
import { TableHeader } from "../../../components/reusableItems/header";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import moment from "moment";
import { fetchUserById } from "../../../store/userMgmt";
import DatePicker from "react-datepicker";
import { CustomDatePicker } from "../../../components/custom/datepicker";
import MonthTicketStatusTable from "./tables/month_ticket_status_table";
import TicketStatusTable from "./tables/ticket_status_table";
import TopRequestServiceTable from "./tables/top_request_service_table";
import TopRequestBCTable from "./tables/top_request_bc_table";
import TopRequestGroupTable from "./tables/top_request_group_table";

export const RUN_TIME_FEEDBACK_REPORT_TABS = {
  MONTH_TICKET_STATUS: "Month & Ticket Status",
  TICKET_STATUS: "Ticket Status",
  TOP_REQUEST_SERVICE: "Top requests by services",
  TOP_REQUEST_BC: "Top requests by BC",
  TOP_REQUEST_GROUP: "Top requests by Group",
};

export const RuntimeFeedbackReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const query = new URLSearchParams(searchQuery);
  const { loading, month_ticket_status_list, month_ticket_status_loading,
    ticket_status_list, ticket_status_loading, top_request_service_list,
    top_request_service_loading, top_request_bc_list, top_request_bc_loading,
    top_request_group_list, top_request_group_loading } = useSelector(
      (state) => state.runtimeFeedback
    );
  const [tab, setTab] = useState(RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const currentDate = new Date();

  // Get the first day of the current year
  const firstMonthOfYear = new Date(currentDate.getFullYear(), 0, 1); // January 1st of the current year

  // Get the first day of the current month
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Set state for CreatedDateFrom (first day of the current year) and CreatedDateTo (first day of the current month)
  const [monthTicketStatusDateFrom, setMonthTicketStatusDateFrom] = useState(firstMonthOfYear);
  const [monthTicketStatusDateTo, setMonthTicketStatusDateTo] = useState(firstDayOfCurrentMonth);

  const [ticketStatusDateFrom, setTicketStatusDateFrom] = useState(firstMonthOfYear);
  const [ticketStatusDateTo, setTicketStatusDateTo] = useState(firstDayOfCurrentMonth);

  const [topRequestServiceDateFrom, setTopRequestServiceDateFrom] = useState(firstMonthOfYear);
  const [topRequestServiceDateTo, setTopRequestServiceDateTo] = useState(firstDayOfCurrentMonth);

  const [topRequestBCDateFrom, setTopRequestBCDateFrom] = useState(firstMonthOfYear);
  const [topRequestBCDateTo, setTopRequestBCDateTo] = useState(firstDayOfCurrentMonth);

  const [topRequestGroupDateFrom, setTopRequestGroupDateFrom] = useState(firstMonthOfYear);
  const [topRequestGroupDateTo, setTopRequestGroupDateTo] = useState(firstDayOfCurrentMonth);


  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "runtime_feedback.access_runtime_feedback_report"
  );

  const formatDate = (date, type) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month

    let day;
    if (type === "first") {
      day = "01"; // First day of the month
    } else if (type === "last") {
      day = new Date(year, date.getMonth() + 1, 0).getDate(); // Last day of the month
    }

    return `${year}-${month}-${day}`;
  };

  const getData = async (callback) => {
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {

        if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS) {
          dispatch(fetchMonthTicketStatusList({ start_date: formatDate(monthTicketStatusDateFrom, "first"), end_date: formatDate(monthTicketStatusDateTo, "last") }));
        }

        if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS) {
          dispatch(fetchTicketStatusList({ start_date: formatDate(ticketStatusDateFrom, "first"), end_date: formatDate(ticketStatusDateTo, "last") }));
        }

        if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE) {
          dispatch(fetchTopServiceList({ start_date: formatDate(topRequestServiceDateFrom, "first"), end_date: formatDate(topRequestServiceDateTo, "last") }));
        }

        if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC) {
          dispatch(fetchTopBCList({ start_date: formatDate(topRequestBCDateFrom, "first"), end_date: formatDate(topRequestBCDateTo, "last") }));
        }

        if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP) {
          dispatch(fetchTopGroupList({ start_date: formatDate(topRequestGroupDateFrom, "first"), end_date: formatDate(topRequestGroupDateTo, "last") }));
        }

        setIsFirstRender(false);
      }
    );
  };

  useEffect(() => {
    getData();
  }, [tab]);

  useEffect(() => {
    if (isFirstRender) return;
    getData();
  }, [monthTicketStatusDateFrom, monthTicketStatusDateTo]);


  useEffect(() => {
    if (isFirstRender) return;
    getData();
  }, [ticketStatusDateFrom, ticketStatusDateTo]);

  useEffect(() => {
    if (isFirstRender) return;

    getData();
  }, [topRequestServiceDateFrom, topRequestServiceDateTo]);

  useEffect(() => {
    if (isFirstRender) return;

    getData();
  }, [topRequestBCDateFrom, topRequestBCDateTo]);

  useEffect(() => {
    if (isFirstRender) return;

    getData();
  }, [topRequestGroupDateFrom, topRequestGroupDateTo]);


  const RefreshData = () => {
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {

        dispatch(fetchMonthTicketStatusList({ start_date: formatDate(monthTicketStatusDateFrom, "first"), end_date: formatDate(monthTicketStatusDateTo, "last") }));

        dispatch(fetchTicketStatusList({ start_date: formatDate(ticketStatusDateFrom, "first"), end_date: formatDate(ticketStatusDateTo, "last") }));

        dispatch(fetchTopServiceList({ start_date: formatDate(topRequestServiceDateFrom, "first"), end_date: formatDate(topRequestServiceDateTo, "last") }));

        dispatch(fetchTopBCList({ start_date: formatDate(topRequestBCDateFrom, "first"), end_date: formatDate(topRequestBCDateTo, "last") }));

        dispatch(fetchTopGroupList({ start_date: formatDate(topRequestGroupDateFrom, "first"), end_date: formatDate(topRequestGroupDateTo, "last") }));

      }
    );
  };




  const monthTicketSatusTableRef = useRef();
  const ticketStatusTableRef = useRef();
  const topRequestBCTableRef = useRef();
  const topRequestGroupTableRef = useRef();
  const topRequestServiceTableRef = useRef();

  // Function to trigger export from child component
  const handleExport = () => {

    if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS) {

      if (monthTicketSatusTableRef.current) {
        monthTicketSatusTableRef.current.exportToExcel();
      }
    }

    if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS) {
      if (ticketStatusTableRef.current) {
        ticketStatusTableRef.current.exportToExcel();
      }
    }

    if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE) {
      if (topRequestServiceTableRef.current) {
        topRequestServiceTableRef.current.exportToExcel();
      }
    }

    if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC) {
      if (topRequestBCTableRef.current) {
        topRequestBCTableRef.current.exportToExcel();
      }
    }

    if (tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP) {
      if (topRequestGroupTableRef.current) {
        topRequestGroupTableRef.current.exportToExcel();
      }
    }

  };



  const icons = [
    {
      icon: RefreshIcon,
      title: "Refresh",
      action: RefreshData,
    },
    {
      icon: ExportIcon,
      title: "Export",
      action: handleExport,
    },
  ];



  const onTabChange = (tab) => {
    setTab(tab);
  };

  const MonthTicketStatusUI = () => {

    return (
      <div>
        <div className="category-flex-row">
          <div className="category-date-div">
            <div>From</div>
            <DatePicker
              className="filter-date-range"
              dateFormat="MMM yyyy"
              selected={monthTicketStatusDateFrom}
              showMonthYearPicker
              onChange={(date) => setMonthTicketStatusDateFrom(date)}
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
          <div className="category-date-div">
            <div>To</div>
            <DatePicker
              selected={monthTicketStatusDateTo}
              onChange={(date) => setMonthTicketStatusDateTo(date)}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
        </div>

        <MonthTicketStatusTable data={month_ticket_status_list} ref={monthTicketSatusTableRef} loading={month_ticket_status_loading}
          start_date={formatDate(monthTicketStatusDateFrom, "first")} end_date={formatDate(monthTicketStatusDateTo, "last")} />
      </div>
    )
  }


  const TicketStatusUI = () => {

    return (
      <div>
        <div className="category-flex-row">
          <div className="category-date-div">
            <div>From</div>
            <DatePicker
              className="filter-date-range"
              dateFormat="MMM yyyy"
              selected={ticketStatusDateFrom}
              showMonthYearPicker
              onChange={(date) => setTicketStatusDateFrom(date)}
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
          <div className="category-date-div">
            <div>To</div>
            <DatePicker
              selected={ticketStatusDateTo}
              onChange={(date) => setTicketStatusDateTo(date)}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
        </div>

        <TicketStatusTable data={ticket_status_list} ref={ticketStatusTableRef} loading={ticket_status_loading}
          start_date={formatDate(ticketStatusDateFrom, "first")} end_date={formatDate(ticketStatusDateTo, "last")} />
      </div>
    )
  }

  const TopRequestServiceUI = () => {

    return (
      <div>
        <div className="category-flex-row">
          <div className="category-date-div">
            <div>From</div>
            <DatePicker
              className="filter-date-range"
              dateFormat="MMM yyyy"
              selected={topRequestServiceDateFrom}
              showMonthYearPicker
              onChange={(date) => setTopRequestServiceDateFrom(date)}
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
          <div className="category-date-div">
            <div>To</div>
            <DatePicker
              selected={topRequestServiceDateTo}
              onChange={(date) => setTopRequestServiceDateTo(date)}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
        </div>

        <TopRequestServiceTable data={top_request_service_list} ref={topRequestServiceTableRef}
          loading={top_request_service_loading}
          start_date={formatDate(topRequestServiceDateFrom, "first")} end_date={formatDate(topRequestServiceDateTo, "last")} />
      </div>
    )
  }

  const TopRequestBCUI = () => {

    return (
      <div>
        <div className="category-flex-row">
          <div className="category-date-div">
            <div>From</div>
            <DatePicker
              className="filter-date-range"
              dateFormat="MMM yyyy"
              selected={topRequestBCDateFrom}
              showMonthYearPicker
              onChange={(date) => setTopRequestBCDateFrom(date)}
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
          <div className="category-date-div">
            <div>To</div>
            <DatePicker
              selected={topRequestBCDateTo}
              onChange={(date) => setTopRequestBCDateTo(date)}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
        </div>

        <TopRequestBCTable data={top_request_bc_list} ref={topRequestBCTableRef} loading={top_request_bc_loading}
          start_date={formatDate(topRequestBCDateFrom, "first")} end_date={formatDate(topRequestBCDateTo, "last")} />
      </div>
    )
  }



  const TopRequestGroupUI = () => {

    return (
      <div>
        <div className="category-flex-row">
          <div className="category-date-div">
            <div>From</div>
            <DatePicker
              className="filter-date-range"
              dateFormat="MMM yyyy"
              selected={topRequestGroupDateFrom}
              showMonthYearPicker
              onChange={(date) => setTopRequestGroupDateFrom(date)}
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
          <div className="category-date-div">
            <div>To</div>
            <DatePicker
              selected={topRequestGroupDateTo}
              onChange={(date) => setTopRequestGroupDateTo(date)}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              customInput={<CustomDatePicker iconColor={"#781D7E"} />}
            />
          </div>
        </div>

        <TopRequestGroupTable data={top_request_group_list} ref={topRequestGroupTableRef} loading={top_request_group_loading}
          start_date={formatDate(topRequestGroupDateFrom, "first")} end_date={formatDate(topRequestGroupDateTo, "last")} />
      </div>
    )
  }


  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <TableHeader title={"Run Time Feedback Report"} icons={icons} />

          {loading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )
          }

          {(<div className="runtime_feedback_report_child_box_main_detail_box">
            <div>
              <div className="tabs">
                <div
                  onClick={() => {
                    onTabChange(RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS);
                    if (month_ticket_status_list.length == 0) {
                      getData();
                    }
                  }}
                  className={`tab ${tab === RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS ? "isactive" : ""
                    }`}
                >
                  {RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS}
                </div>
                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS);
                    if (ticket_status_list.length == 0) {
                      getData();
                    }
                  }}
                  className={`tab ${tab === RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS ? "isactive" : ""
                    }`}
                >
                  {RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS}
                </div>


                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE);
                    if (top_request_service_list.length == 0) {
                      getData();
                    }
                  }}
                  className={`tab ${tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE ? "isactive" : ""
                    }`}
                >
                  {RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE}
                </div>


                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC);
                    if (top_request_bc_list.length == 0) {
                      getData();
                    }
                  }}
                  className={`tab ${tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC ? "isactive" : ""
                    }`}
                >
                  {RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC}
                </div>

                <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                <div
                  onClick={() => {
                    onTabChange(RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP);
                    if (top_request_group_list.length == 0) {
                      getData();
                    }
                  }}
                  className={`tab ${tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP ? "isactive" : ""
                    }`}
                >
                  {RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP}
                </div>

              </div>
            </div>

            {(tab === RUN_TIME_FEEDBACK_REPORT_TABS.MONTH_TICKET_STATUS) ? <MonthTicketStatusUI /> : <div></div>}
            {(tab === RUN_TIME_FEEDBACK_REPORT_TABS.TICKET_STATUS) ? <TicketStatusUI /> : <div></div>}
            {(tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_SERVICE) ? <TopRequestServiceUI /> : <div></div>}
            {(tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_BC) ? <TopRequestBCUI /> : <div></div>}
            {(tab === RUN_TIME_FEEDBACK_REPORT_TABS.TOP_REQUEST_GROUP) ? <TopRequestGroupUI /> : <div></div>}

          </div>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
