import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router";
import "../communication.css";
import BackIcon from "../../../assets/communication/Back.svg";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { 
  INBOX_ROUTE
} from "../../../routes";
import moment from "moment";

export const AddSurveyHeader = (props) => {
  const navigate = useNavigate();
  return (
    <div className="poll_and_survey_body">
        <div className="poll_and_survey_addheader" >
          <div className="back-title-group" onClick={(e)=> props.backBtncallback(e)}>
            <img src={BackIcon} className="back-icon" />
            <span className="back-text">Back</span>
          </div>
        </div>
    </div>
  );
};