import React, { PureComponent } from "react";
import { useNavigate } from "react-router";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;

  return (
    <span style={{ color }} className="barChartLegend">
      {value}
    </span>
  );
};

const RenderLegend = (props) => {
  const navigate = useNavigate()
  const { payload } = props;

  return (
    <div className="dashboard_barChart_legend_main_box">
      <div className="dashboard_barChart_legend_title">{props.title}</div>
      {props.isShowLegend && props.type === "text" ? (
        <div className="dashboard_barChart_legend_box">
          {payload.map((entry, index) => (
            <div
              key={`item-${index}`}
              // style={{ color: entry.color }}
              className="dashboard_barChart_legend_box_item"
            >
              <div
                className="dashboard_barChart_legend_box_item_dot"
                style={{ backgroundColor: entry.color }}
              />
              {props.external.find((i) => i.data_key === entry.dataKey)
                ? props.external.find((i) => i.data_key === entry.dataKey).title
                : entry.value}
            </div>
          ))}
        </div>
      ) : (
        props.isShowLegend &&
        props.type === "button" && (
          <div
            className="dashboard_graph_see_all_button"
            onClick={() =>
              props.route ? navigate(props.route) : navigate("/dashboard")
            }
          >
            See all
          </div>
        )
      )}
    </div>
  );
};

const CustomizedTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x + 2},${y - 10})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        className="barchart_tick_text"
      >
        {props.shouldAdd0 && payload.value < 10 && 0}
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedTickY = (props) => {
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x - 8},${y - 18})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="start"
        fill="#666"
        className="barchart_tick_text"
      >
        {payload.value}
      </text>
    </g>
  );
};

const RenderTooltip = (props) => {
  const { active, payload, label, external } = props;
  if (active && payload && payload.length) {
    if (props.config && props.config === "MIC") {
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          <div className="barchart_tooltip_text">
            {props.mainData[label - 1].date}
          </div>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {external.find((j) => j.data_key === i.dataKey)
                ? external.find((j) => j.data_key === i.dataKey).title
                : i.dataKey}
              : {i.value}
            </div>
          ))}
        </div>
      );
    } else {
      const title = payload[0].payload.name;
      return (
        <div className="barchart_tooltip_main_box" key={label}>
          {payload.map((i, index) => (
            <div className="barchart_tooltip_text" key={index}>
              {title}: {i.value}
            </div>
          ))}
        </div>
      );
    }
  }

  return null;
};

export const LineChartComponents = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={props.data && props.data}
        margin={{
          top: 20,
          right: 15,
          left: 2,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        {props.config && props.config.for_module === "MIC" ? (
          <XAxis
            dataKey={(props) => new Date(props.date).getDate()}
            axisLine={false}
            tickLine={false}
            tickSize={15}
            minTickGap={2}
            tick={<CustomizedTick shouldAdd0={false} />}
          />
        ) : (
          <XAxis
            dataKey={props.config && props.config.xAxis_key}
            axisLine={false}
            tickLine={false}
            // tick={<CustomizedTick />}
            tick={
              props.config && props.config.custom_tick ? (
                <CustomizedTick />
              ) : (
                {
                  fill: "#666666",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 600,
                }
              )
            }
          />
        )}
        <YAxis
          height={10}
          axisLine={false}
          tickLine={false}
          type="number"
          domain={[0, "dataMax + 3"]}
          width={25}
          tickCount={8}
          allowDecimals={false}
          tick={<CustomizedTickY />}
        />
        <Tooltip
          content={
            <RenderTooltip
              external={
                props.config && props.config.config && props.config.config
              }
              mainData={props.data && props.data}
              config={
                props.config &&
                props.config.for_module &&
                props.config.for_module
              }
            />
          }
        />
        <Legend
          content={
            <RenderLegend
              route={props.config && props.config.route && props.config.route}
              external={
                props.config && props.config.config && props.config.config
              }
              isShowLegend={props.config && props.config && props.config.legend}
              type={props.config && props.config && props.config.legend_type}
              title={props.config && props.config && props.config.graph_title}
            />
          }
          verticalAlign="top"
          height={70}
          align="right"
          iconType="circle"
          formatter={renderColorfulLegendText}
        />
        {props.config &&
          props.config.config &&
          props.config.config.map((i) => (
            <Line
              type="monotone"
              dataKey={i.data_key}
              stroke={i.color}
              activeDot={false}
              strokeWidth={5}
              dot={false}
            />
          ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
