import React from "react";
import TotalReportIcon from "../../../assets/dashboard/dr_total_report.svg";
import { PieChartComponents } from "../components/pieChart";
import { pieChartColor } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { Upcoming } from "../components/upcoming";
import { NoPermission } from "../components/noPermission";

export const DashboardDR = (props) => {
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">
          {props.title && props.title}
        </div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div className="dashboard_dr_main_box">
            <div className="dashboard_dr_first_box">
              <div className="dashboard_dr_first_box_first_child">
                <div className="dashboard_dr_first_box_first_child_first_item_box">
                  <div className="dashboard_dr_first_box_first_child_first_item_box_detail">
                    <div>
                      <img src={TotalReportIcon} />
                    </div>
                    <div className="dashboard_dr_first_box_first_child_first_item_box_detail_text">
                      Total Reported
                    </div>
                    <div className="dashboard_dr_first_box_first_child_first_item_box_detail_count">
                      {props.total_report ? props.total_report : 0}
                    </div>
                  </div>
                </div>
                <div className="dashboard_dr_first_box_first_child_second_item_box">
                  <div className="dashboard_graph_title_box">
                    <div className="dashboard_dr_second_box_title">
                      Top Incident Locations
                    </div>
                    <div
                      className="dashboard_dr_first_box_first_child_second_item_box_see_all_button"
                      onClick={() =>
                        props.handleNavigate &&
                        props.handleNavigate(
                          props.route ? props.route : "/dashboard"
                        )
                      }
                    >
                      See all
                    </div>
                  </div>
                  <div
                    className="dashboard_dr_first_box_first_child_second_item_main_box"
                    style={{
                      columns:
                        props.top_incident_location &&
                        props.top_incident_location.length < 7 &&
                        1,
                    }}
                  >
                    {props.top_incident_location &&
                    props.top_incident_location.length > 0 ? (
                      props.top_incident_location
                        .slice(0, 12)
                        .map((i, index) => (
                          <div
                            key={index}
                            className="dashboard_dr_first_box_first_child_second_item_box_child"
                          >
                            <div className="dashboard_dr_first_box_first_child_second_item_box_child_title">
                              <div>{index + 1}.</div>
                              <div>{i.name}</div>
                            </div>
                            <div>{i.value}</div>
                          </div>
                        ))
                    ) : (
                      <NoRecord />
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard_dr_first_box_second_child">
                <div className="dashboard_graph_title_box">
                  <div className="dashboard_dr_second_box_title">
                    Top incidents by Department
                  </div>
                  <div
                    className="dashboard_dr_first_box_first_child_second_item_box_see_all_button"
                    onClick={() =>
                      props.handleNavigate &&
                      props.handleNavigate(
                        props.route ? props.route : "/dashboard"
                      )
                    }
                  >
                    See all
                  </div>
                </div>
                <div
                  className="dashboard_dr_first_box_first_child_second_item_main_box"
                  style={{
                    columns: 1,
                  }}
                >
                  {props.top_incident_department &&
                  props.top_incident_department.length > 0 ? (
                    props.top_incident_department
                      .slice(0, 12)
                      .map((i, index) => (
                        <div
                          key={index}
                          className="dashboard_dr_first_box_first_child_second_item_box_child"
                        >
                          <div className="dashboard_dr_first_box_first_child_second_item_box_child_title">
                            <div>{index + 1}.</div>
                            <div>{i.name}</div>
                          </div>
                          <div>{i.value}</div>
                        </div>
                      ))
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </div>
            </div>
            <div className="dashboard_dr_second_box">
              <div className="dashboard_graph_title_box">
                <div className="dashboard_dr_second_box_title">
                  Top num of users by Department/Depot
                </div>
              </div>
              <div style={{ height: "calc(100% - 60px)" }}>
                {props.pieChartData && props.pieChartData.length > 0 ? (
                  <PieChartComponents
                    data={props.pieChartData && props.pieChartData}
                    Legendheight={"calc(100% - 300px)"}
                    colors={pieChartColor}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
