import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import "./poll_and_survey.css";
import api from "../../../config/api";
import config from "../../../config/config";
import { getCurrentLoggedInSessionToken } from "../../../config/userPool";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import {
  createAndUpdatePollSurvey,
  fetcheSurveyDetail
} from "../../../store/poll_and_survey";
import { useDispatch, useSelector } from "react-redux";
import { POLL_SURVEY_ADD, POLL_SURVEY_LIST } from "../../../routes";
import { AddSurveyHeader } from "./add_header";
import { AddSurveySteps } from "./add_steps";
import { AddPollSurveySteps } from "./add_steps";
import { Notification_Details } from "./view/notification";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import NextIcon from "../../../assets/communication/NextIcon.svg";
import { PollQuestionList, surveyTypes } from "./view/question_lists";
import { RecipientsForm } from "./recipients/recipients";
import { CollaboratorForm } from "./recipients/collaborator";
import { Loader } from "@progress/kendo-react-indicators";
import { ReviewAndPublish } from "./review_publish/review_and_publish";
import { checkPermission, getUserPermission } from "../../../config/permission_helper";


export const AddSurvey = () => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const [selected_tab, setSelectedTab] = useState(
    parseInt(query.get("state", 0)) || 0
  );
  const [id, setId] = useState(
    query.get("id", "")
  );

  const userPermissions = getUserPermission();

  const AddPermssion = checkPermission(
    userPermissions,
    "survey.submit_survey"
  );

  const [showRequiredFieldError, setShowRequiredFieldError] = useState(false);
  const [recipientUsers, setRecipientUsers] = useState([]);
  const [recipientSelectedState, setRecipientSelectedState] = React.useState({});

  const [collaboratorUsers, setCollaboratorUsers] = useState([]);
  const [collaboratorSelectedState, setCollaboratorSelectedState] = React.useState({});

  const [loading, setLoading] = useState(false);
  const [showDateError, setShowDateError] = useState(false);
  const [errors, setErrors] = useState(false);
  const [enQuestions, setEnQuestions] = useState([]);
  const [zhQuestions, setZhQuestions] = useState([]);
  const [selected_dist, setSelected_dist] = useState("user_group");
  const [selected_dist_collaborator, setSelected_dist_collaborator] = useState("user_group");

  const [showError, setShowError] = useState({
    title: "",
    description: "",
    start_date: new Date(),
    start_time: "",
    end_date: new Date(),
    end_time: "",

  });
  const [disabled, setDisabled] = useState(false);

  const [editData, setEditData] = useState({
    status: "draft",
    title: "",
    description: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    recipient: [],
    collaborator: [],
    questions_en: [],
    questions_zh: []
  });

  const getData = async () => {

    if (id) {
      dispatch(fetcheSurveyDetail(id)).then((res) => {

        const updatedQuestionEn = res.payload.questions_en.map(question => {
          const qtype = surveyTypes.find(type => type.value === question.qtype) || "Unknown";

          const updatedOptions = question.options.map(option => ({
            ...option,
            type: option.value.toLowerCase() === "other" ? "other" : "key"
          }));



          return {
            ...question,
            qtype: qtype,
            options: updatedOptions
          };
        });

        const updatedQuestionZh = res.payload.questions_zh.map(question => {
          const qtype = surveyTypes.find(type => type.value === question.qtype) || "Unknown";

          const updatedOptions = question.options.map(option => ({
            ...option,
            type: option.value.toLowerCase() === "other" ? "other" : "key"
          }));

          return {
            ...question,
            qtype: qtype,
            options: updatedOptions
          };
        });

        setZhQuestions(updatedQuestionZh);
        setEnQuestions(updatedQuestionEn);

        setEditData((prevData) => ({
          ...prevData,
          title: res.payload.title,
          description: res.payload.description,
          start_date: moment(res.payload.start_date, "YYYY-MM-DD").toDate(),
          start_time: moment(`${res.payload.start_date} ${res.payload.start_time}`, "YYYY-MM-DD hh:mmA").toDate(),
          end_date: moment(res.payload.end_date, "YYYY-MM-DD").toDate(),
          end_time: moment(`${res.payload.end_date} ${res.payload.end_time}`, "YYYY-MM-DD hh:mmA").toDate(),
          recipient: res.payload.recipient,
          collaborator: res.payload.collaborator,
        }));
        console.log("result");
        console.log(updatedQuestionEn);
        console.log(editData);


      });
    }
  };


  useEffect(() => {
    getData();
  }, [id]);


  const backtoInformation = () => {
    query.set("state", 0);
    navigate(POLL_SURVEY_ADD + "?" + query.toString());
  };

  const checkMandatoryFields = () => {
    setShowDateError(false);

    const { start_date, start_time, end_date, end_time, title, description, questions_en, questions_zh } = editData;

    if (!start_date || !start_time || !end_date || !end_time || !title || !description) {
      console.error("All date and time fields must be filled.");
      setShowRequiredFieldError(true);
      return true;

    }

    const startDateTime = moment(`${moment(start_date).format("YYYY-MM-DD")} ${moment(start_time).format("hh:mmA")}`, "YYYY-MM-DD hh:mmA").toDate();
    const endDateTime = moment(`${moment(end_date).format("YYYY-MM-DD")}T${moment(end_time).format("hh:mmA")}`, "YYYY-MM-DD hh:mmA").toDate();

    if (endDateTime <= startDateTime) {
      setShowDateError(true);
      return true;
    }

    if (selected_tab === 1 && (enQuestions.length === 0 && zhQuestions.length === 0)) {
      setShowRequiredFieldError(true);
      return true;
    }


  };

  const handleSubmit = (isSaved) => {
    console.log(editData);

    if (checkMandatoryFields()) return;

    setLoading(true);

    let updatedData = { ...editData };

    if (id) {  // Ensures id is neither null nor empty
      updatedData.id = id;
    }

    updatedData.questions_en = enQuestions;
    updatedData.questions_zh = zhQuestions;

    updatedData.start_date = moment(editData.start_date).format("YYYY-MM-DD");
    updatedData.end_date = moment(editData.end_date).format("YYYY-MM-DD");


    updatedData.start_time = moment(editData.start_time).format("hh:mmA");
    updatedData.end_time = moment(editData.end_time).format("hh:mmA");

    updatedData.questions_en = enQuestions.map((v) => {
      return {
        qid: v.qid,
        qtitle: v.qtitle,
        qtype: v.qtype.value,
        options: v.options,
        is_required: v.is_required
      };
    });

    updatedData.questions_zh = zhQuestions.map((v) => {
      return {
        qid: v.qid,
        qtitle: v.qtitle,
        qtype: v.qtype.value,
        options: v.options,
        is_required: v.is_required
      };
    });


    dispatch(createAndUpdatePollSurvey(updatedData)).then((res) => {
      const { payload } = res;
      if (payload.status === "success") {
        const saved_id = payload.data.id;

        setLoading(false);

        if (selected_tab == 4) {
          navigate(POLL_SURVEY_LIST);
        } else {
          let tab = isSaved ? selected_tab : parseInt(selected_tab) + 1;
          setSelectedTab(tab);
          setId(saved_id);
          if (saved_id) {
            query.set("id", saved_id);
          }
          query.set("state", tab);
          navigate(POLL_SURVEY_ADD + "?" + query.toString());
        }


      } else {
        console.error(res);
        setErrors(res.message);
      }
      setLoading(false);
    });
  };


  const handleNext = (isSaved) => {
    console.log(editData);

    if (checkMandatoryFields()) return;


    let tab = parseInt(selected_tab) + 1;
    setSelectedTab(tab);
    setId(id);
    if (id) {
      query.set("id", id);
    }
    query.set("state", tab);
    navigate(POLL_SURVEY_ADD + "?" + query.toString());
  };

  const callback_handleBackBtn = (e) => {

    if (selected_tab == 0) {
      navigate(POLL_SURVEY_LIST);
    }
    const currentTab = selected_tab < 0 ? (selected_tab - 1) : 0;
    if (id) {
      query.set("id", id);
    }
    query.set("state", currentTab);

    setSelectedTab(currentTab);
    navigate(POLL_SURVEY_ADD + "?" + query.toString());
  };


  const compareDate = (data) => {
    if (
      new Date(
        `${data.start_date} ${moment(data.start_time, "hh:mmA").format(
          "HH:mm:ss"
        )}`
      ) <
      new Date(
        `${data.end_date} ${moment(data.end_time, "hh:mmA").format("HH:mm:ss")}`
      )
    ) {
      return true;
    } else {
      return false;
    }
  };



  return (
    <>
      {AddPermssion ? (
        <div style={{ height: "calc(100% - 75px)" }}>

          {errors && (
            <Dialog
              width={400}
              title={"Message"}
              onClose={() => setErrors(false)}
              className="error-message"
            >
              <p>{errors}</p>
            </Dialog>
          )}

          {showRequiredFieldError && (
            <Dialog
              className="information-details-alert-form"
              title={"Alert"}
              onClose={() => setShowRequiredFieldError(false)}
            >
              <p
                style={{
                  margin: "25px",
                  textAlign: "center",
                }}
              >
                Please fill in all the required fields.
              </p>
            </Dialog>
          )}

          {loading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}

          <AddSurveyHeader
            backBtncallback={(e) => callback_handleBackBtn(e)}
            state={selected_tab}
          />
          <AddPollSurveySteps
            state={selected_tab}
            setState={setSelectedTab}
          />

          <div className="main-poll-survey">
            {selected_tab === 0 && (
              <Notification_Details
                loading={loading}
                setLoading={setLoading}
                editData={editData}
                setEditData={setEditData}
                showError={showError}
              />
            )}

            {selected_tab === 1 && (
              <PollQuestionList
                setEnQuestions={setEnQuestions}
                enQuestions={enQuestions}
                setZhQuestions={setZhQuestions}
                zhQuestions={zhQuestions}
                loading={loading}
                setLoading={setLoading}
                editData={editData}
                setEditData={setEditData}
              />
            )}

            {selected_tab === 2 && (
              <RecipientsForm
                loading={loading}
                setLoading={setLoading}
                selected_dist={selected_dist}
                setSelected_dist={setSelected_dist}
                setEditData={setEditData}
                editData={editData}
                setUsers={setRecipientUsers}
                users={recipientUsers}
                setSelectedState={setRecipientSelectedState}
                selectedState={recipientSelectedState}
              />
            )}

            {selected_tab === 3 && (
              <CollaboratorForm
                loading={loading}
                setLoading={setLoading}
                selected_dist={selected_dist_collaborator}
                setSelected_dist={setSelected_dist_collaborator}
                setEditData={setEditData}
                setUsers={setCollaboratorUsers}
                users={collaboratorUsers}
                setSelectedState={setCollaboratorSelectedState}
                selectedState={collaboratorSelectedState}
              />
            )}

            {selected_tab === 4 && (
              <ReviewAndPublish
                loading={loading}
                setLoading={setLoading}
                editData={editData}
                setEditData={setEditData}
                setEnQuestions={setEnQuestions}
                enQuestions={enQuestions}
                setZhQuestions={setZhQuestions}
                zhQuestions={zhQuestions}
              />
            )}


          </div>

          <div className="footer pad-left-20 pad-bottom-20">
            <div className="footer-left-btn-groups">
              {selected_tab !== 0 &&
                <Button
                  className="footer-previous-btn font-family-poppins"
                  onClick={() => {
                    callback_handleBackBtn();
                  }}
                >
                  Previous
                </Button>
              }
              <Button
                className="footer-save-btn"
                onClick={(e) => {
                  handleSubmit(true);
                }}
                disabled={disabled}
              >
                Save
              </Button>
            </div>
            {selected_tab !== 4 &&
              <div className="footer-right">
                <Button
                  className="footer-next-btn"
                  onClick={(e) => {
                    handleSubmit(false);
                  }}
                  disabled={disabled}
                >
                  Next
                  <Icon>
                    <img alt="" src={NextIcon} />
                  </Icon>
                </Button>
              </div>
            }

            {selected_tab === 4 &&
              <div className="footer-right">
                <Button
                  className="footer-next-btn"
                  onClick={(e) => {
                    handleSubmit(false);
                  }}
                  disabled={disabled}
                >
                  Submit
                </Button>
              </div>
            }

          </div>



          {showDateError && (
            <Dialog
              className="alert-form"
              title={"Alert"}
              width={400}
              // height={280}
              onClose={() => setShowDateError(false)}
            >
              <p>End Date cannot be earlier than Start Date!</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px",
                    height: "30px",
                    borderRadius: "10px",
                    background: "#eb6e1f",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowDateError(false)}
                >
                  Change
                </div>
              </div>
            </Dialog>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
