import React, { useEffect, useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { SelectUserGroups } from "./user_group";
import { SelectUsers } from "./select_users";
import { UploadExcels } from "./upload_excel";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import NextIcon from "../../../../assets/communication/NextIcon.svg";
import { Loader, Skeleton } from "@progress/kendo-react-indicators";
import api from "../../../../config/api";
import config from "../../../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../../config/userPool";
import {
  fetchNotificationById,
  fetchNotificationRecipients,
  getCommRecipientsFile,
} from "../../../../store/notification";
import {
  fetchAllCommUsers,
  fetchAllCommUsersByKey,
} from "../../../../store/commUserList";
import moment from "moment";
const { NOTIFICATION_RECIPIENT_ENDPOINT, NOTIFICATION_ENDPOINT } =
  config.api_endpoint;

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const distribution = [
  {
    name: "Select User Group",
    value: "user_group",
  },
  {
    name: "Select User(s)",
    value: "users",
  },
  {
    name: "Upload Excel",
    value: "upload_excel",
  },
];
export const CollaboratorForm = (props) => {
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // const [selected_dist, setSelected_dist] = useState("user_group");
  const [disabled, setDisabled] = useState(false);
  const [autoSaveOn, setAutoSaveOn] = useState(false);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [textLoadin, setTextLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = React.useState([
    {
      field: "name",
      dir: "asc",
    },
  ]);
  const [dataState, setDataState] = React.useState(
    props.users.map((dataItem) => {
      let tmp = { selected: false };
      for (let i in dataItem) {
        tmp[i.toLowerCase()] = dataItem[i];
      }
      return tmp;
    })
  );
  const pageChange = (event) => {
    setSkip(event.page.skip);
  };
  // const { notification_data, ndata_loading, ndata_error } = useSelector(
  //   (state) => state.notification
  // );
  const {
    users: comm_users,
    users2: comm_users_key,
    loading: commUsersLoading,
    loading2: commUsersKeyLoading,
  } = useSelector((state) => state.commUser);
  const [notification_data, setNotificationData] = useState();

  const notification__id = query.get("id");
  const copyId = query.get("copyId", false);

  // useRef value to check autoSaveOn
  // to save the data if autoSaveOn is true when component unloads
  const autoSaveOnRef = useRef();
  autoSaveOnRef.current = autoSaveOn;
  const recipientsRef = useRef({});
  const notificationDataRef = useRef({});
  const selectedStateRef = useRef({});

  const [fileUploadLoading, setFileUploadLoading] = useState(false);


  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: props.selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      console.log("change1");
      props.setSelectedState(newSelectedState);
      selectedStateRef.current = newSelectedState;

      let user_ids = props.users.filter(
        (v) => v.id in props.selectedState && props.selectedState[v.id] === true
      );
      user_ids = user_ids.map((v) => {
        return {
          id: v.id,
          name: v.name,
          depot: v.depot || v.department,
          interchange: v.interchange,
          designation: v.designation
        };
      });
  
      props.setEditData((prevData) => ({
        ...prevData,
        collaborator: user_ids,
      }));

      console.log('dsafdasf');
      console.log(user_ids);
      
      

    },
    [props.selectedState]
  );
  const onHeaderSelectionChange = React.useCallback((event) => {
    console.log("changeall");
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    props.setSelectedState(newSelectedState);
    selectedStateRef.current = newSelectedState;
    setSelectedLoading(false);

    let user_ids = props.users.filter(
      (v) => v.id in props.selectedState && props.selectedState[v.id] === true
    );
    user_ids = user_ids.map((v) => {
      return {
        id: v.id,
        name: v.name,
        depot: v.depot || v.department,
        interchange: v.interchange,
        designation: v.designation
      };
    });

    props.setEditData((prevData) => ({
      ...prevData,
      collaborator: user_ids,
    }));

    console.log('New Changes');
      console.log(user_ids);
      
    
    console.log("onHeaderSelectionChange newSelectedState:", newSelectedState);
  }, []);
  const handleDistChange = (e) => {
    props.setSelected_dist(e.value);
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    props.setSelectedState({});
    selectedStateRef.current = {};
    props.setUsers([]);
    recipientsRef.current = [];
    setDataState([]);
  };
  
  const onChangecallback = async (data) => {
    console.log("onChangecallback:", data);
    let copiedData = [];
    data.map((dataItem) => {
      let tmp = {};
      for (let i in dataItem) {
        if (i.toLowerCase() === "sap") {
          tmp["id"] = dataItem[i];
        } else if (i.toLowerCase() === "department") {
          tmp["depot"] = dataItem[i];
        } else {
          tmp[i.toLowerCase()] = dataItem[i];
        }
      }
      copiedData.push(tmp);
    });
    setAutoSaveOn(true);
    autoSaveOnRef.current = true;
    props.setSelectedState({});
    selectedStateRef.current = {};
    props.setUsers(copiedData);
    recipientsRef.current = copiedData;
    console.log("copiedData:", copiedData);
    setDataState(
      copiedData.map((dataItem) => Object.assign({ selected: false }, dataItem))
    );
  };

  const [userGroupFilters, setUserGroupFilters] = useState({});
  const [userFilters, setUserFilters] = useState({});

  const handleResetUserList = () => {
    onChangecallback([]);
  };

  const handleUserGroupFilters = (filters) => {
    let copiedFilters = { ...userGroupFilters };
    console.log("filters:", filters);
    if (Object.keys(filters).length === 0) {
      setUserGroupFilters({});
    } else {
      for (let key in filters) {
        copiedFilters[key] = filters[key];
      }
      setUserGroupFilters(copiedFilters);
    }
  };

  const handleUserFilters = (filters) => {
    let copiedFilters = { ...userFilters };
    console.log("filters:", filters);
    for (let key in filters) {
      copiedFilters[key] = filters[key];
    }
    setUserFilters(copiedFilters);
  };

  useEffect(() => {
    console.log("user group filters:", userGroupFilters);
    if (
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length > 0
    ) {
      dispatch(fetchAllCommUsers({ ...userGroupFilters, type: "user_group" }));
    } else if (
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length === 0
    ) {
      onChangecallback([]);
    }
  }, [userGroupFilters]);

  useEffect(() => {
    if (userFilters.search_key) {
      console.log("user filters:", userFilters);
      dispatch(fetchAllCommUsersByKey({ ...userFilters, type: "user" }));
    }
  }, [userFilters]);

  useEffect(() => {
    console.log(
      "CHECK:",
      comm_users,
      userGroupFilters,
      comm_users &&
        userGroupFilters.interchange &&
        userGroupFilters.interchange.length > 0
    );
    if (
      comm_users &&
      userGroupFilters.interchange &&
      userGroupFilters.interchange.length > 0
    ) {
      console.log("comm users:", comm_users);
      props.setSelectedState({});
      selectedStateRef.current = {};
      props.setUsers([]);
      recipientsRef.current = [];
      setDataState([]);
      onChangecallback(comm_users);
    }

    let user_ids = props.users.filter(
      (v) => v.id in props.selectedState && props.selectedState[v.id] === true
    );
    user_ids = user_ids.map((v) => {
      return {
        id: v.id,
        name: v.name,
        depot: v.depot || v.department,
        interchange: v.interchange,
        designation: v.designation
      };
    });

    props.setEditData((prevData) => ({
      ...prevData,
      collaborator: user_ids,
    }));
  }, [comm_users, userGroupFilters]);

  useEffect(() => {
    console.log(
      "CHECK:",
      comm_users_key,
      userFilters,
      comm_users_key && userFilters.search_key,
      commUsersKeyLoading
    );
    if (
      (comm_users_key && userFilters.search_key) ||
      (!comm_users_key && userFilters.search_key)
    ) {
      console.log("comm users:", comm_users_key);
      props.setSelectedState({});
      selectedStateRef.current = {};
      props.setUsers([]);
      recipientsRef.current = [];
      setDataState([]);
      if (comm_users_key) {
        onChangecallback(comm_users_key);
      }

      let user_ids = props.users.filter(
        (v) => v.id in props.selectedState && props.selectedState[v.id] === true
      );
      user_ids = user_ids.map((v) => {
        return {
          id: v.id,
          name: v.name,
          depot: v.depot || v.department,
          interchange: v.interchange,
          designation: v.designation
        };
      });
  
      props.setEditData((prevData) => ({
        ...prevData,
        collaborator: user_ids,
      }));
    }
  }, [comm_users_key, userFilters]);

  const onClickOpenFile = (key) => {
    console.log(key);
    const data = { key };
    dispatch(getCommRecipientsFile(data)).then((res) => {
      console.log(res.payload.data);
      if (res.payload.data) {
        window.open(res.payload.data);
      }
    });
  };
  const customDateCell = (props) => {
    if (props.dataItem[props.field] !== "") {
      return (
        <td style={{ background: "transparent" }}>
          <span
            style={{
              textDecoration: "underline",
              fontWeight: 700,
              color: "#781D7E",
            }}
            onClick={() => onClickOpenFile(props.dataItem[props.field])}
          >
            {props.dataItem[props.field]}
          </span>
        </td>
      );
    }
    return (
      <td style={{ background: "transparent" }}>
        {props.dataItem[props.field]}
      </td>
    );
  };
  return (
    <>
      {props.loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <p className="distribution-title">Distribution</p>
          <ul className="distribution-list">
            {distribution.map((val) => (
              <li>
                <RadioButton
                  className="distribution-radio"
                  key={val.value}
                  label={val.name}
                  name="distribution"
                  value={val.value}
                  onChange={handleDistChange}
                  checked={props.selected_dist === val.value}
                />
              </li>
            ))}
          </ul>
          {props.selected_dist == "user_group" && (
            <SelectUserGroups
              onFilter={handleUserGroupFilters}
              resetUserList={handleResetUserList}
            />
          )}
          {props.selected_dist == "users" && (
            <SelectUsers onFilter={handleUserFilters} />
          )}
          {props.selected_dist == "upload_excel" && (
            <UploadExcels
              onChangeFile={(data) => onChangecallback(data)}
              setLoading={setFileUploadLoading}
            />
          )}
          {/* <div>*Please Select a Maximum of 4000 Records</div> */}
          <div>
            {(commUsersLoading ||
              commUsersKeyLoading ||
              fileUploadLoading ||
              selectedLoading) && (
              <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
              </div>
            )}

            <div>Select All</div>
            <Grid
              data={
                dataState
                  ? dataState.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: props.selectedState[idGetter(item)],
                    }))
                  : []
              }
              style={{
                height: "300px",
              }}
              className="Scroll"
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={{
                enabled: true,
                drag: false,
                cell: true,
                mode: "multiple",
              }}
              // pageSize={25}
              total={dataState.length}
              // skip={skip}
              // scrollable={"virtual"}
              // onPageChange={pageChange}
              pageable
              // cellRender={loadingCell}
              // sortable={true}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
            >
              <Column
                field={SELECTED_FIELD}
                width="100px"
                headerSelectionValue={
                  dataState.length === 0
                    ? false
                    : dataState.findIndex(
                        (item) => !props.selectedState[idGetter(item)]
                      ) === -1
                }
              />
              <Column field="emp_sap_no" title="SAP No" />
              <Column field="id" title="BC No" />
              <Column field="name" title="Name" />
              {/* <Column field="division" title="Division" /> */}
              <Column field="depot" title="Department/Depot" />
              <Column field="interchange" title="Section/Interchange Group" />
              <Column field="designation" title="Designation" />
              <Column field="leadership" title="Leadership Role" />
              <Column field="citizenship" title="Citizenship" />
              <Column field="nationality" title="Nationality" />
              
            </Grid>
          </div>

          
          
        </div>
      )}
    </>
  );
};
