import {
    DASHBOARD_ROUTE,
    INBOX_ROUTE,
    NEWSFEED_ROUTE,
    DR_SAFE_INCIDENT_LIST,
    DR_SAFE_CONTACT_LIST,
    DR_SECURE_INCIDENT_LIST,
    DR_SECURE_CONTACT_LIST,
    DR_INFRA_INCIDENT_LIST,
    DR_INFRA_LOCATION_LIST,
    DR_INFRA_SUBSYSTEM_LIST,
    DR_INFRA_SYSTEM_LIST,
    DR_INFRA_CONTACT_LIST,
    ADMIN_TOOLS_CATEGORY_LIST,
    ADMIN_TOOLS_DIVISION_LIST,
    ADMIN_TOOLS_MATTER_LIST,
    IFEEDBACK_LIST,
    FEEDBACK_ON_BUS_SERVICE_CHECKER,
    FEEDBACK_ON_BUS_SERVICE,
    FEEDBACK_ON_BUS_SERVICE_PREFERENCE,
    FEEDBACK_ON_BUS_SERVICE_SERVICES,
    FEEDBACK_ON_BUS_SERVICE_APPROVED,
    ADMIN_TOOLS_MIC_DEPARTMENT,
    ADMIN_TOOLS_MIC_DEPARTMENT_ADD,
    ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT,
    ADMIN_TOOLS_TUTORIAL_LIST,
    USER_MANAGEMENT,
    USER_MANAGEMENT_USERS,
    USER_MANAGEMENT_GROUPS,
    TELEPHONE_DIRECTORY_DEPOT_LIST,
    TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
    TELEPHONE_DIRECTORY_CONTACT_LIST,
    ADMIN_TOOLS_ROLE_LIST,
    ISAFE_MESSAGE,
    ADMIN_TOOLS_EMAIL_GROUP_LIST,
    ADMIN_TOOLS_EMAIL_GROUP_DETAILS,
    ADMIN_TOOLS_EMAIL_GROUP_ADD,
    ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
    ADMIN_TOOLS_EMAIL_TEMPLATE_DETAILS,
    ADMIN_TOOLS_EMAIL_TEMPLATE_ADD,
    FEEDBACK_ON_BUS_ASSIGNMENT,
    FEEDBACK_ON_BUS_SUMMARY_OF_CHECK,
    FEEDBACK_ON_BUS_REPORTS,
    POLICY_DOCUMENT_ROUTE,
    BUSINESS_CONTINUITY_ROUTE,
    ASSET_MANAGMENT_ROUTE,
    CARE_ENGLISH_ROUTE,
    CARE_MANDRAIN_ROUTE,
    SAFETY_HEALTH_ROUTE,
    SECURITY_ROUTE,
    VMB_ENGLISH_ROUTE,
    VMB_MANDARIN_ROUTE,
    FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
    CARES_ECARDS_LISTING,
    CARES_APPRECIATION_WALLS_LISTING,
    ADMIN_TOOLS_CARES_ACCESSORY,
    ADMIN_TOOLS_CARES_IMAGES,
    HAPPENINGS_PROMOTIONS_ROUTE,
    HAPPENINGS_ARTICLES_ROUTE,
    ADMIN_TOOLS_CLINIC_LIST,
    PERSONA_CONFIGURATION_LIST,
    RUNTIME_FEEDBACK_LIST,
    HAPPENINGS_CATEGORY_ROUTE,
    RUNTIME_FEEDBACK_REPORT,
    POLL_SURVEY_LIST,
  } from "../../routes";


export  const RouteLists = [
    {
      text: "Dashboard",
      route: DASHBOARD_ROUTE,
      selected: true,
      id: "dashboard",
      permission: ["dashboard.access_dashboard"],
    },
    {
      text: "Communication",
      // route: "/communication",
      selected: true,
      id: "communication",
      permission: ["comms.can_access_comms"],
      sub_menu: [
        {
          id: "inbox",
          route: INBOX_ROUTE,
          text: "Inbox",
          selected: false,
          parent_id: "communication",
          permission: [
            "comms.access_comms_inbox",
            "comms.limited_access_comms_inbox",
          ],
        },
      ],
    },
    {
      text: "Happenings",
      // route: HAPPENINGS_PROMOTIONS_ROUTE,
      selected: true,
      id: "happenings",
      permission: ["happening.access_happening"],
      sub_menu: [
        {
          id: "promotions",
          route: HAPPENINGS_PROMOTIONS_ROUTE,
          text: "Promotions",
          selected: false,
          parent_id: "happenings",
          permission: ["happening.access_happening_promotion_list"],
        },
        {
          id: "articles",
          route: HAPPENINGS_ARTICLES_ROUTE,
          text: "Articles",
          selected: false,
          parent_id: "happenings",
          permission: ["happening.access_happening_article_list"],
        },
        {
          id: "category",
          route: HAPPENINGS_CATEGORY_ROUTE,
          text: "Manage Category",
          selected: false,
          parent_id: "happenings",
          permission: ["admin_tools.access_comms_category"],
        },
      ],
    },
    {
      text: "Employee Welfare",
      // route: ADMIN_TOOLS_CLINIC_LIST,
      selected: true,
      id: "employee_welfare",
      permission: ["clinic.access_clinic"],
      sub_menu: [
        {
          text: "Manage Panel GP Clinics",
          route: ADMIN_TOOLS_CLINIC_LIST,
          selected: false,
          id: "clinic",
          parent_id: "employee_welfare",
          permission: ["clinic.access_clinic"],
        },
      ],
    },
    {
      text: "Feedback",
      // route: "/feedback",
      selected: true,
      id: "feedback",
      permission: ["ifeedback.access_ifeedback"],
      sub_menu: [
        {
          id: "ifeedback",
          route: IFEEDBACK_LIST,
          text: "iFeedback",
          selected: false,
          parent_id: "feedback",
        },
      ],
    },
    {
      text: "Feedback on Bus Service",
      // route: FEEDBACK_ON_BUS_SERVICE,
      selected: true,
      id: "feedback_on_bus",
      permission: [
        "fob.access_bsqc",
        "fob.bsqc_access_check_periods",
        "fob.bsqc_access_checker_preference",
        "fob.access_report",
        "fob.access_summary_of_checks",
        "fob.access_assignment",
      ],
      sub_menu: [
        {
          id: "feedback_on_bus_assignment",
          route: FEEDBACK_ON_BUS_ASSIGNMENT_LISTING,
          text: "Assignment",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_assignment"],
        },
        {
          id: "BSQC",
          route: FEEDBACK_ON_BUS_SERVICE_CHECKER,
          text: "BSQC",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_bsqc"],
          sub_menu: [
            {
              id: "feedback_on_bus_checker_periods",
              route: FEEDBACK_ON_BUS_SERVICE_CHECKER,
              text: "BSQC Check Periods",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_check_periods"],
            },
            {
              id: "feedback_on_bus_checkers_preference",
              route: FEEDBACK_ON_BUS_SERVICE_PREFERENCE,
              text: "Checkers Preference",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_checker_preference"],
            },
            {
              id: "feedback_on_bus_selected_services",
              route: FEEDBACK_ON_BUS_SERVICE_SERVICES,
              text: "Select Services",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_select_services"],
            },
            {
              id: "feedback_on_bus_approved_assignments",
              route: FEEDBACK_ON_BUS_SERVICE_APPROVED,
              text: "Approved Assignments",
              selected: false,
              parent_id: "feedback_on_bus",
              permission: ["fob.bsqc_access_approved_assignment"],
            },
          ],
        },
        {
          id: "feedback_on_bus_summary_of_check",
          route: FEEDBACK_ON_BUS_SUMMARY_OF_CHECK,
          text: "Summary of Check",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_summary_of_checks"],
        },
        {
          id: "feedback_on_bus_reports",
          route: FEEDBACK_ON_BUS_REPORTS,
          text: "Reports",
          selected: false,
          parent_id: "feedback_on_bus",
          permission: ["fob.access_report"],
        },
      ],
    },
    {
      text: "MIC",
      route: "/mic",
      selected: true,
      id: "mic",
      permission: ["my_ideas.can_access_mic"],
    },
    {
      text: "DR Infra",
      // route: "/dr_infra",
      selected: true,
      id: "dr_infra",
      permission: [
        "dr_infra.view_admin_incidents",
        "dr_infra.limited_view_incident",
      ],
      sub_menu: [
        {
          id: "dr_infra_incident_list",
          route: DR_INFRA_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_admin_incidents",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_location_listing",
          route: DR_INFRA_LOCATION_LIST,
          text: "Location Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_locations",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_subsytem_listing",
          route: DR_INFRA_SUBSYSTEM_LIST,
          text: "Sub System Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_subsystems",
            "dr_infra.limited_view_incident",
          ],
        },
        {
          id: "dr_infra_sytem_listing",
          route: DR_INFRA_SYSTEM_LIST,
          text: "System ID Listing",
          selected: false,
          parent_id: "dr_infra",
          permission: [
            "dr_infra.view_systems",
            "dr_infra.limited_view_incident",
          ],
        },
      ],
    },
    {
      text: "DR Safe",
      // route: DR_SAFE_INCIDENT_LIST,
      selected: false,
      id: "dr_safe",
      permission: [
        "dr_safe.view_admin_incidents",
        "dr_safe.limited_view_incident",
      ],
      sub_menu: [
        {
          id: "dr_safe_incident_list",
          route: DR_SAFE_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_safe",
        },
      ],
    },
    {
      text: "DR Secure",
      // route: DR_SECURE_INCIDENT_LIST,
      selected: true,
      id: "dr_secure",
      permission: [
        "dr_secure.view_admin_incidents",
        "dr_secure.limited_view_incident",
      ],
      sub_menu: [
        {
          id: "dr_secure_incident_list",
          route: DR_SECURE_INCIDENT_LIST,
          text: "Reported incident Listing",
          selected: false,
          parent_id: "dr_secure",
        },
      ],
    },
    {
      text: "CARES",
      // route: CARES_ECARDS_LISTING,
      selected: true,
      id: "cares",
      permission: [
        "cares.access_cares_ecard_list",
        "cares.access_cares_appwall_list",
      ],
      sub_menu: [
        {
          id: "cares_ecard",
          route: CARES_ECARDS_LISTING,
          text: "eCard",
          selected: false,
          parent_id: "cares",
          permission: ["cares.access_cares_ecard_list"],
        },
        {
          id: "cares_appreciationwalls",
          route: CARES_APPRECIATION_WALLS_LISTING,
          text: "Appreciation Wall",
          selected: false,
          parent_id: "cares",
          permission: ["cares.access_cares_appwall_list"],
        },
        {
          id: "ecard_accessory",
          route: ADMIN_TOOLS_CARES_ACCESSORY,
          text: "Manage Accessory",
          selected: false,
          parent_id: "cares",
          // permission: ["telephone_directory.access_depot_subdepot_listing"],
        },
        {
          id: "ecard_image",
          route: ADMIN_TOOLS_CARES_IMAGES,
          text: "Manage Images  ",
          selected: false,
          parent_id: "cares",
          // permission: ["telephone_directory.access_department_listing"],
        },
      ],
    },
    {
      text: "Manage Tutorial",
      route: ADMIN_TOOLS_TUTORIAL_LIST,
      selected: false,
      id: "manage_tutorial",
      permission: ["tutorial.access_tutorial"],
    },
    {
      text: "Admin Tools",
      // route: "/admin_tools",
      selected: true,
      id: "admin_tools",
      permission: ["admin_tools.can_access_admin_tools"],
      sub_menu: [
        {
          id: "communication_tools",
          route: ADMIN_TOOLS_CATEGORY_LIST,
          text: "Communication",
          selected: false,
          parent_id: "admin_tools",
          permission: [
            "admin_tools.access_comms_category",
            "admin_tools.access_comms_roles",
            "admin_tools.access_comms_division",
          ],
          sub_menu: [
            {
              id: "tools_category",
              route: ADMIN_TOOLS_CATEGORY_LIST,
              text: "Manage Category",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_category"],
            },
            {
              id: "tools_role",
              route: ADMIN_TOOLS_ROLE_LIST,
              text: "Manage Role",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_roles"],
            },
            {
              id: "tools_division",
              route: ADMIN_TOOLS_DIVISION_LIST,
              text: "Manage Divisions",
              selected: false,
              parent_id: "communication_tools",
              permission: ["admin_tools.access_comms_division"],
            },
          ],
        },
        {
          text: "Policy Documents",
          route: POLICY_DOCUMENT_ROUTE,
          selected: false,
          id: "policy_documents",
          parent_id: "admin_tools",
          permission: ["admin_tools.access_VMB_policy_docs"],
        },
        {
          id: "isafe_tools",
          route: ISAFE_MESSAGE,
          text: "iSafe",
          selected: false,
          parent_id: "admin_tools",
          permission: ["admin_tools.access_isafe_safety_message"],
        },
        {
          id: "ifeedback_tools",
          permission: ["admin_tools.access_ifeedback_matter"],
          // route: ADMIN_TOOLS_MATTER_LIST,
          text: "iFeedback",
          selected: false,
          parent_id: "admin_tools",
          sub_menu: [
            {
              id: "tools_matter",
              route: ADMIN_TOOLS_MATTER_LIST,
              text: "Manage Matter",
              selected: false,
              parent_id: "ifeedback_tools",
              permission: ["admin_tools.access_ifeedback_matter"],

            },
          ],
        },
        {
          id: "mic_tools",
          permission: [
            "admin_tools.access_MIC_department",
            "admin_tools.access_MIC_type_of_improvement",
          ],
          // route: ADMIN_TOOLS_MIC_DEPARTMENT,
          selected: false,
          text: "MIC",
          parent_id: "admin_tools",
          sub_menu: [
            {
              id: "mic_tools_department",
              route: ADMIN_TOOLS_MIC_DEPARTMENT,
              text: "Manage MIC Department",
              selected: false,
              permission: ["admin_tools.access_MIC_department"],
              parent_id: "admin_tools",
            },
            {
              id: "mic_tools_type_of_improvement",
              route: ADMIN_TOOLS_MIC_TYPE_OF_IMPROVEMENT,
              text: "Manege MIC Type of Improvement",
              permission: ["admin_tools.access_MIC_type_of_improvement"],
              selected: false,
              parent_id: "admin_tools",
            },
          ],
        },
        {
          id: "feedback_bus_service",
          permission: [
            "admin_tools.access_FOB_email_groups",
            "admin_tools.access_FOB_email_templates",
          ],
          // route: ADMIN_TOOLS_EMAIL_GROUP_LIST,
          selected: false,
          text: "Feedback on Bus Service",
          parent_id: "admin_tools",
          sub_menu: [
            {
              id: "feedback_bus_service_email_group",
              route: ADMIN_TOOLS_EMAIL_GROUP_LIST,
              text: "Manage Email Groups",
              selected: false,
              permission: ["admin_tools.access_FOB_email_groups"],
              parent_id: "feedback_bus_service",
            },
            {
              id: "feedback_bus_service_email_template",
              route: ADMIN_TOOLS_EMAIL_TEMPLATE_LIST,
              text: "Manage Email Templates",
              selected: false,
              permission: ["admin_tools.access_FOB_email_templates"],
              parent_id: "feedback_bus_service",
            },
          ],
        },
      ],
    },
    {
      id: "telephone_directory",
      text: "Telephone Directory",
      // route: "/telephone_directory",
      selected: true,
      permission: [
        "telephone_directory.access_depot_subdepot_listing",
        "telephone_directory.access_department_listing",
        "telephone_directory.access_directory_listing",
      ],
      sub_menu: [
        {
          id: "depot",
          route: TELEPHONE_DIRECTORY_DEPOT_LIST,
          text: "Depot/Sub Depot Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_depot_subdepot_listing"],
        },
        {
          id: "department",
          route: TELEPHONE_DIRECTORY_DEPARTMENT_LIST,
          text: "Department Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_department_listing"],
        },
        {
          id: "directory",
          route: TELEPHONE_DIRECTORY_CONTACT_LIST,
          text: "Directory Listing",
          selected: false,
          parent_id: "telephone_directory",
          permission: ["telephone_directory.access_directory_listing"],
        },
      ],
    },
    {
      id: "runtime_feedback",
      // route: USER_MANAGEMENT,
      selected: true,
      text: "Run Time Feedback",
      permission: [
        "runtime_feedback.access_runtime_feedback_list",
        "runtime_feedback.access_runtime_feedback_report"
      ],
      sub_menu: [
        {
          id: "rt_feedbackList",
          route: RUNTIME_FEEDBACK_LIST,
          text: "RT Feedback List",
          selected: false,
          parent_id: "runtime_feedback",
          permission: ["runtime_feedback.access_runtime_feedback_list"],
        },
        {
          id: "rt_feedbackReport",
          route: RUNTIME_FEEDBACK_REPORT,
          text: "Report",
          selected: false,
          parent_id: "runtime_feedback",
          permission: ["runtime_feedback.access_runtime_feedback_report"],
        },
      ],
    },
    {
      id: "user_management",
      // route: USER_MANAGEMENT,
      selected: true,
      text: "User Management",
      permission: [
        "user_management.access_group_management",
        "user_management.access_user_management",
      ],
      sub_menu: [
        {
          id: "user_management_users",
          route: USER_MANAGEMENT_USERS,
          text: "Users",
          selected: false,
          parent_id: "user_management",
          permission: ["user_management.access_user_management"],
        },
        {
          id: "user_management_groups",
          route: USER_MANAGEMENT_GROUPS,
          text: "Groups",
          selected: false,
          parent_id: "user_management",
          permission: ["user_management.access_group_management"],
        },
        {
          text: "Persona Configurations",
          route: PERSONA_CONFIGURATION_LIST,
          selected: false,
          id: "persona_configuration",
          permission: ["admin_tools.access_persona"],
        },
      ],
    },
  ];