import React, { useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import "./auth.css";
import Logo from "../../assets/authentication/logo_login.png";
import config from "../../config/config";
import api from "../../config/api";
import { saveUserPermission } from "../../config/permission_helper";
import FullscreenLoader from "../../components/fullScreenLoader";
import { DASHBOARD_ROUTE } from "../../routes";
import Hide_icon from "../../assets/authentication/hide_icon.svg";
import UNHide_icon from "../../assets/authentication/unhide_icon.svg";
import { getDefaultRoute } from "../../App";
import { RouteLists } from "../../components/common/route";

const { client_id, pool_id } = require("../../service");
const {
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../../config/userPool");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const poolData = {
  // UserPoolId: "ap-southeast-1_S7KrtpLKO", // Your user pool id here
  // ClientId: "slret5aod5h83sc9u7tdhkkm4", // Your client id here
  UserPoolId: pool_id ?? "ap-southeast-1_n73g9eYCz", // Your user pool id here ap-southeast-1_S7KrtpLKO / ap-southeast-1_n73g9eYCz
  ClientId: client_id ?? "4ifplvjfiqqs9s3phrqp0kq04e", // Your client id here slret5aod5h83sc9u7tdhkkm4 / 4ifplvjfiqqs9s3phrqp0kq04e
};

const Login = () => {
  const navigate = useNavigate();
  const [hide, setHide] = React.useState(true);
  const [errorText, setErrorText] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [count, setCount] = useState(0);
  const usernameValidationMessage =
    "Your username should contain only letters!";
  const passwordValidationMessage =
    "Please enter password between 6 and 16 characters!";
  const { USER_PERMISSION_ENDPOINT } = config.api_endpoint;
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    setErrorText(null);
    var authenticationData = {
      Username: username,
      Password: password,
    };
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationData
    );
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username: username,
      Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        console.log("Logged in!");
        const idToken = result.getIdToken().getJwtToken();
        const results = await api.get(USER_PERMISSION_ENDPOINT, {}, idToken);

        if (results.status_code === 200) {
          saveUserPermission(results.data);
          localStorage.setItem("login", "true");
          localStorage.setItem("username", username);
          setLoading(false);
          const defaultRoute = getDefaultRoute(RouteLists, results.data);
          
          navigate(defaultRoute);
        } else {
          setErrorText("Unable to fetch user permissions.");
          setLoading(false);
        }
      },

      onFailure: function (err) {
        console.log(err.message);
        if (err.message === "Incorrect username or password.") {
          setCount(count + 1);
          if (count + 1 > 3) {
            setErrorText(
              "Too many attempt to login. Please contact administration!"
            );
            setLoading(false);
          } else {
            console.log(err.message);
            setErrorText(err.message);
            setLoading(false);
          }
        } else if (err.message === "Password attempts exceeded") {
          setErrorText(err.message);
          setLoading(false);
        } else {
          setErrorText(
            "There is an error with login. Please contact administartion for more information!"
          );
          setLoading(false);
        }
      },
    });
  };
  return (
    <>
      {loading && <FullscreenLoader total_fullscreen={"true"} />}
      <div className="login-wrapper">
        <div
          className="row example-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "11% auto 0 auto",
          }}
        >
          <div className="login_media_screen">
            <div style={{ margin: "32px 0px" }}>
              <img src={Logo} style={{ width: "40%" }} />
            </div>
            <form className="k-form" onSubmit={handleSubmit}>
              <div>
                <Input
                  name="username"
                  style={{
                    width: "80%",
                    borderRadius: "8px",
                    border: "1px solid #E4E1DE",
                  }}
                  placeholder=" User ID"
                  // pattern={"[A-Za-z0-9]+"}
                  minLength={2}
                  required={true}
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                  // validationMessage={usernameValidationMessage}
                />
              </div>
              <div style={{ position: "relative", marginTop: "32px" }}>
                <div style={{ width: "100%", position: "relative" }}>
                  <div
                    style={{
                      backgroundImage: hide
                        ? `url(${Hide_icon})`
                        : `url(${UNHide_icon})`,
                      cursor: "pointer",
                      right: "50px",
                    }}
                    onClick={() => setHide(!hide)}
                    className="forgot_password_icon"
                  />
                </div>
                <Input
                  name="password"
                  type={hide ? "password" : "text"}
                  style={{
                    width: "80%",
                    borderRadius: "8px",
                    border: "1px solid #E4E1DE",
                  }}
                  placeholder=" Password"
                  autoComplete="off"
                  required={true}
                  minLength={6}
                  maxLength={18}
                  validationMessage={passwordValidationMessage}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div>
                <input type="submit" className="Button_submit" value="Login" />
              </div>
              <div style={{ marginTop: 10, marginBottom: 20 }}>
                <span style={{ color: "red" }}>{errorText}</span>
              </div>
              <div
                className="forgot_password"
                onClick={() => {
                  navigate("/forgot_password");
                }}
              >
                Forgot password
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
