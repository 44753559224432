import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

const data = [
  { "62d0f9aa-bf5e-47e0-910c-abe8c788837b": 400 },
  { "62d0f9aa-bf5e-47e0-910c-abe8c788837b": 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
  { name: "Group E", value: 200 },
  { name: "Group F", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;

  return (
    <span style={{ color }} className="barChartLegend">
      {value}
    </span>
  );
};

const RenderLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ height: "100%" }}>
      <div className="dashboard_pieChart_legend_main_box">
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ color: entry.color }}
            className="dashboard_pieChart_legend_box_item"
          >
            <div className="dashboard_pieChart_legend_box_item_child">
              <div
                className="dashboard_pieChart_legend_box_item_dot"
                style={{ backgroundColor: entry.color }}
              />
              <div className="dashboard_comm_chart_legent_text">
                {entry.value}
              </div>
            </div>
            <div className="dashboard_comm_chart_legent_text">{`${(
              entry.payload.percent * 100
            ).toFixed(0)}%`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const PieChartComponents = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Pie
          data={props.data && props.data}
          cx="50%"
          cy="50%"
          labelLine={false}
          // label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {props.data &&
            props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  props.colors && props.colors.length > 0
                    ? props.colors[index % props.colors.length]
                    : COLORS[index % COLORS.length]
                }
              />
            ))}
        </Pie>
        <Legend
          content={<RenderLegend />}
          verticalAlign="bottom"
          height={
            props.Legendheight ? props.Legendheight : "calc(100% - 230px)"
          }
          align="right"
          iconType="circle"
          formatter={renderColorfulLegendText}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
