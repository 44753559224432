import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import "../dashboard.css";
import { CustomDropdownMulti } from "../../../components/reusableItems/customMultiDropdown";

export const DashboardToolbar = (props) => {
  return (
    <div className="dashboard_toolbar_main_box">
      <div className="dashboard_toolbar_title">Dashboard</div>
      <div className="dashboard_toolbar_action_box">
        <div className="dashboard_filter_dropdown">
          <DropDownList
            data={props.month ? props.month : []}
            className="dashboard_filter_dropdown_item"
            placeholder="Please Select"
            value={props.filterValue.month}
            size={"medium"}
            onChange={(e) =>
              props.handleFilterChange &&
              props.handleFilterChange(e.target.value, "month")
            }
          />
        </div>
        <div className="dashboard_filter_dropdown">
          <DropDownList
            className="dashboard_filter_dropdown_item"
            data={props.year ? props.year : []}
            placeholder="Please Select"
            size={"medium"}
            value={props.filterValue.year}
            onChange={(e) =>
              props.handleFilterChange &&
              props.handleFilterChange(e.target.value, "year")
            }
          />
        </div>
        <div style={{ flex: "1 1", maxWidth: "200px" }}>
          <CustomDropdownMulti
            data={props.widget ? props.widget : []}
            value={props.filterValue.widget}
            onChange={(e) =>
              props.handleFilterChange && props.handleFilterChange(e, "widget")
            }
          />
        </div>
      </div>
    </div>
  );
};
