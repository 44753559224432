import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router";
import "./poll_and_survey.css";
import api from "../../../config/api";
import config from "../../../config/config";
import { getCurrentLoggedInSessionToken } from "../../../config/userPool";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import {
  createAndUpdatePollSurvey,
  fetcheSurveyDetail,
  fetcheSurveyDetailByDepartment,
  fetcheSurveyDetailBySummary
} from "../../../store/poll_and_survey";
import { useDispatch, useSelector } from "react-redux";
import { POLL_SURVEY_ADD, POLL_SURVEY_DETAIL, POLL_SURVEY_LIST } from "../../../routes";
import { AddSurveyHeader } from "./add_header";
import { AddSurveySteps } from "./add_steps";
import { AddPollSurveySteps } from "./add_steps";
import { Notification_Details } from "./view/notification";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import NextIcon from "../../../assets/communication/NextIcon.svg";
import { LANGUAGE_TABS, PollQuestionList } from "./view/question_lists";
import { RecipientsForm } from "./recipients/recipients";
import { CollaboratorForm } from "./recipients/collaborator";
import { Loader } from "@progress/kendo-react-indicators";
import { ReviewAndPublish } from "./review_publish/review_and_publish";
import BackIcon from "../../../assets/communication/Back.svg";
import RadioIcon from "../../../assets/poll_survey/radio.svg";
import CheckboxIcon from "../../../assets/poll_survey/checkbox.svg";
import StarIcon from "../../../assets/poll_survey/star.svg";

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, Text, LabelList } from "recharts";
import TextAreaBox from "./components/text_area";
import { TextBox } from "@progress/kendo-react-inputs";


const SURVEY_TAB = {
  SURVEY_RESPONSE: "Survey Response",
  SURVEY_DETAIL: "Survey Detail",
};

export const DetailSurvey = () => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const { detail_loading, detail_data, summary_data, department_list } = useSelector(
    (state) => state.pollSurvey
  );

  const [surveyTab, setSurveyTab] = useState(SURVEY_TAB.SURVEY_RESPONSE);
  const [tab, setTab] = useState(LANGUAGE_TABS.EN);
  const [selectedDepartment, setSelectedDepartment] = useState();


  const getData = async () => {
    dispatch(fetcheSurveyDetail(id));
    dispatch(fetcheSurveyDetailByDepartment(id)).then((res) => {
      setSelectedDepartment(res.payload[0].code);

      dispatch(fetcheSurveyDetailBySummary({ id: id, division: res.payload[0].code }));
    });
  };

  const getSurveyDetailBySummary = async () => {
    dispatch(fetcheSurveyDetailBySummary({ id: id, division: selectedDepartment }));
  };

  useEffect(() => {
    getData();
  }, [id]);


  useEffect(() => {
    getSurveyDetailBySummary();
  }, [selectedDepartment]);




  const onTabChange = (tab) => {
    setTab(tab);
  };


  const onSurveyTabChange = (tab) => {
    setSurveyTab(tab);
  };


  // Custom color for the bars
  const getColor = (length, index) => {
    return "#FF6B6B"; // Using a fixed color (red) for simplicity
  };

  // Measure the width of the text dynamically to fit labels
  let ctx;
  const measureText14HelveticaNeue = text => {
    if (!ctx) {
      ctx = document.createElement("canvas").getContext("2d");
      ctx.font = "14px 'Helvetica Neue'";
    }

    return ctx.measureText(text).width;
  };

  const BAR_AXIS_SPACE = 10;

  // Custom Y-axis tick renderer
  const YAxisLeftTick = ({ y, payload: { value } }) => {
    return (
      <Text x={0} y={y} textAnchor="start" verticalAnchor="middle" fontSize={14} >
        {value}
      </Text>
    );
  };
  

  // Chart Component
  const SimpleBarChart = ({ data, yKey, xKey,percentKey }) => {
    const maxTextWidth = useMemo(
      () =>
        data.reduce((acc, cur) => {
          const value = cur[percentKey]; // Get the Y-axis label value
          const width = measureText14HelveticaNeue(value.toLocaleString());
          if (width > acc) {
            return width;
          }
          return acc;
        }, 0),
      [data, percentKey]
    );

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ left: 30, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis axisLine={false} type="number" />
          <YAxis
            yAxisId={0}
            dataKey={xKey}
            type="category"
            axisLine={false}
            tickLine={false}
            tick={YAxisLeftTick}
          />
          <YAxis
            orientation="right"
            yAxisId={1}
            dataKey={percentKey}
            type="category"
            axisLine={false}
            tickLine={false}
            tickFormatter={value => value.toLocaleString()}
            mirror
            tick={{
              fontSize: 14,
              transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`
            }}
          />
          <Bar dataKey={yKey} minPointSize={2} barSize={32} fill={'#FF6B6B'} isAnimationActive={false}>
          </Bar>


        </BarChart>
      </ResponsiveContainer>
    );
  };


  const SummaryItem = ({ summary, index }) => {
    console.log(summary);

    console.log('summary.qtitle');
    console.log(summary.qtitle);


    const data = summary.answers.map(answer => ({
      name: answer.value, // Option name
      count: answer.count,
      percent: `${answer.count}(${answer.percent}%)`,
    }));

    return (
      <div className="poll_and_survey_create_item_main_box">
        <div className="flex-between">
          <div className="poll_and_survey_create_item_main_box_title">{`Question ${index + 1}`}</div>
          <div className="required-text">{summary.is_required ? 'Required' : ''}</div>
        </div>
        <div className="poll_and_survey_create_item_main_box_field" style={{ margin: '1px' }}>
          <div className="poll_and_survey_create_item_main_box_title">{summary.qtitle}</div>
          {summary.qtype != "open-ended" ? (
            <div>
              <SimpleBarChart data={data} xKey="name" yKey="count" percentKey="percent" />
            </div>
          ) : null}

          <div>
            {summary.qtype == "open-ended" ? (
              summary.answers.map((answer, index) => (
                <div className="poll_and_survey_open_ended">
                  {answer.value}
                </div>
              ))
            ) : null}
          </div>

        </div>
      </div>
    );
  };

  const SurveyResponse = (() => {
    // Calculate the total count
    if (department_list.length == 0) {
      return (<div></div>);
    }
    // const totalCount = department_list.reduce((sum, dept) => sum + dept.count, 0);
    // const totalCountTotal = department_list.reduce((sum, dept) => sum + dept.total, 0);

    // // Create the "All" object
    // const allDepartment = { value: "All", count: totalCount,total: totalCountTotal};
    // const temp_department = [allDepartment, ...department_list];


    return (
      <>
        <div>
          <div className="department-container">
            {department_list.map((department, index) => (
              <div
                key={index}
                className={`department-card ${selectedDepartment === department.code ? "selected-card" : ""}`}
                onClick={() => {
                  setSelectedDepartment(department.code);
                }
                }
              >
                {/* <img src={department.icon} className="department-icon" /> */}
                <div className="department-count">{department.count}</div>
                <div className="department-total">/ {department.total}</div>
                <div className="department-name">{department.name}</div>
              </div>
            ))}
          </div>

          <div className="poll_and_survey_tab_content">
            <div className="tabs">
              <div
                onClick={() => onTabChange(LANGUAGE_TABS.EN)}
                className={`tab ${tab === LANGUAGE_TABS.EN ? "isactive" : ""
                  }`}
              >
                {LANGUAGE_TABS.EN}
              </div>
              <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
              <div
                onClick={() => onTabChange(LANGUAGE_TABS.CH)}
                className={`tab ${tab === LANGUAGE_TABS.CH ? "isactive" : ""
                  }`}
              >
                {LANGUAGE_TABS.CH}
              </div>
            </div>
          </div>


          <div className="poll_and_survey_items_list">
            {tab == LANGUAGE_TABS.EN ? (
              summary_data && summary_data.answers_en
                ? summary_data.answers_en.map((summary, index) => (
                  <SummaryItem summary={summary} index={index} key={index} />
                )) : <div></div>
            ) : (
              summary_data && summary_data.answers_zh
                ? summary_data.answers_zh.map((summary, index) => (
                  <SummaryItem summary={summary} index={index} key={index} />
                )) : <div></div>
            )

            }
          </div>


        </div>
      </>
    );

  });




  const RenderRow = ({ title, value }) => {
    return (
      <>
        {value ? (
          <>
            <div className="poll_survey_child_box_main_detail_items">
              <div className="poll_survey_child_box_main_detail_items_title poll_survey_center_item poll_survey_detail_padding_bottom">
                {title}
              </div>
              <div className="poll_survey_child_box_main_detail_items_splitter" />
              <div className="poll_survey_center_item poll_survey_child_box_main_detail_items_subdetail poll_survey_detail_padding_bottom">
                {value}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };



  const Item = ({ question, index }) => {

    return (
      <div className="poll_and_survey_create_item_main_box">
        <div className="flex-between">
          <div className="poll_and_survey_create_item_main_box_title">{`Question ${index + 1}`}</div>
          <div className="required-text" key={index}>{question.is_required ? 'Required' : ''}</div>
        </div>
        <div className="poll_and_survey_create_item_main_box_field" style={{ margin: "1px" }}>
          <div className="poll_and_survey_create_item_main_box_title">{`${question.qtitle}`}</div>
          {question.options.map((option, index) => (
            <div>
              <div className="poll-survey-option-item" style={{ marginBottom: "5px" }}>
                <img src={question.qtype === 'single-choice' ? RadioIcon : CheckboxIcon} className="delete-button-icon" />
                <div className="poll_and_survey_create_item_main_box_title_review">{`${option.value}`}</div>
              </div>
              {option.value.toLowerCase() === 'other' ?
                <div>
                  <TextBox
                    className="review-text-field"
                    value={"Please Input"}
                    disabled
                  />
                </div> : null}
            </div>
          ))}

          {question.qtype == "star-rating" ? (
            <div className="poll-survey-option-item" style={{ marginBottom: "5px" }}>
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
            </div>
          ) : null}

          {question.qtype == "open-ended" ? (
            <TextAreaBox
              className="review-text-field"
              value={"Please Input"}
              disabled={true}
            />
          ) : null}
        </div>
      </div>
    );
  }



  // Maximum number of names to show before "others"
  const MAX_DISPLAY_COUNT = 3;

  const CollaboratorView = ({ title, collaborators }) => {
    const displayNames = collaborators.slice(0, MAX_DISPLAY_COUNT);
    const remainingNamesCount = collaborators.length - MAX_DISPLAY_COUNT;

    return (
      <div className="poll_survey_child_box_main_detail_items">
        <div className="poll_survey_child_box_main_detail_items_title poll_survey_center_item poll_survey_detail_padding_bottom">
          {title}
        </div>
        <div className="poll_survey_child_box_main_detail_items_splitter" />
        <div className="poll_survey_center_item poll_survey_child_box_main_detail_items_subdetail poll_survey_detail_padding_bottom">
          <span>{displayNames.map((collaborator, index) => (
            <span key={collaborator.id}>
              {collaborator.name}
              {index < displayNames.length - 1 && ", "}
            </span>
          ))}</span>
          {remainingNamesCount > 0 && (
            <span>
              , <span style={{ color: 'blue', cursor: 'pointer' }}>{`+${remainingNamesCount} others`}</span>
            </span>
          )}
        </div>
      </div>

    );
  };


  const SurveyDetailView = (() => {

    return (
      <div className="">
        <div>

          <RenderRow
            title={"Title"}
            value={detail_data.title}
          />

          <RenderRow
            title={"Title in Description"}
            value={detail_data.description}
          />

          <RenderRow
            title={"Start Date"}
            value={detail_data.start_date}
          />

          <RenderRow
            title={"Start Time"}
            value={detail_data.start_time}
          />

          <RenderRow
            title={"End Date"}
            value={detail_data.end_date}
          />

          <RenderRow
            title={"End Time"}
            value={detail_data.end_time}
          />

          <CollaboratorView
            title={"Distribution"}
            collaborators={detail_data.recipient}
          />

          <CollaboratorView
            title={"Collaborator"}
            collaborators={detail_data.collaborator}
          />

        </div>

        <div className="poll_and_survey_tab_content">
          <div className="tabs">
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.EN)}
              className={`tab ${tab === LANGUAGE_TABS.EN ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.EN}
            </div>
            <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.CH)}
              className={`tab ${tab === LANGUAGE_TABS.CH ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.CH}
            </div>
          </div>
        </div>


        {tab == LANGUAGE_TABS.EN ? (
          detail_data.questions_en.map((question, index) => (
            <Item question={question} index={index} key={index} />
          ))

        ) : (
          detail_data.questions_zh.map((question, index) => (
            <Item question={question} index={index} key={index} />
          ))
        )}

      </div>
    );
  });



  return (
    <>

      {detail_loading && (
        <Loader
          type="converging-spinner"
          style={{
            width: "100%",
            height: "calc(100% - 75px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff4d",
            position: "absolute",
            zIndex: "999",
          }}
        />
      )}

      <div className="poll_and_survey_body">
        <div className="poll_and_survey_addheader" >
          <div className="back-title-group" onClick={(e) => {
            navigate(POLL_SURVEY_LIST);

          }}>
            <img src={BackIcon} className="back-icon" />
            <span className="back-text">Back</span>
          </div>
        </div>
      </div>

      <div className="poll_and_survey_scroll_box_detail">

        <div className="flex-between">
          <div className="poll_and_survey_tab_content">
            <div className="tabs">
              <div
                onClick={() => onSurveyTabChange(SURVEY_TAB.SURVEY_RESPONSE)}
                className={`tab ${surveyTab === SURVEY_TAB.SURVEY_RESPONSE ? "isactive" : ""
                  }`}
              >
                {SURVEY_TAB.SURVEY_RESPONSE}
              </div>
              <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
              <div
                onClick={() => onSurveyTabChange(SURVEY_TAB.SURVEY_DETAIL)}
                className={`tab ${surveyTab === SURVEY_TAB.SURVEY_DETAIL ? "isactive" : ""
                  }`}
              >
                {SURVEY_TAB.SURVEY_DETAIL}
              </div>
            </div>

          </div>
          <div
            className="reusable_header_create_box"
            onClick={() => {
              navigate(`${POLL_SURVEY_ADD}?id=${id}`);
            }}
          >
            Edit
          </div>
        </div>



        <div className="poll_and_survey_items_list">
          {surveyTab == SURVEY_TAB.SURVEY_RESPONSE ? (
            <SurveyResponse />
          ) : (
            <SurveyDetailView />
          )

          }
        </div>



      </div>


    </>
  );
};
