import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Checkbox } from "@progress/kendo-react-inputs";
import "./reusable.css";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Pagination } from "./pagination";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import { checkUserGroup, getUserGroup } from "../../config/group_helper";

export const role_permission_checker = ({ permission, role }) => {
  const permission_checker = (permission_) => {
    let permission = false;
    permission_.map((i) => {
      const userPermission = getUserPermission();
      const checkStatusPermission = checkPermission(userPermission, i);
      if (checkStatusPermission) {
        permission = true;
      }
    });
    return permission;
  };
  const role_checker = (role_) => {
    let role = false;
    role_.map((i) => {
      const userGroup = getUserGroup();
      const checkGroup = checkUserGroup(userGroup, i);
      if (checkGroup) {
        role = true;
      }
    });
    return role;
  };

  let returnValue = {};
  if (permission !== undefined && permission.length > 0) {
    returnValue = {
      ...returnValue,
      permission: permission_checker(permission),
    };
  }
  if (role !== undefined && role.length > 0) {
    returnValue = {
      ...returnValue,
      role: role_checker(role),
    };
  }
  return returnValue;
};

export const Reusable_table = (props) => {
  const ref = useRef();
  const [data, setData] = useState(props.data ? props.data : []);
  const [sortingID, setSortingID] = useState("");
  const [constRowCount, setConstRowCount] = useState(10);
  let cosntRowPerPage = [5, 10, 15, 25, 35, 45, 55, 65, 75, 85, 100, "All"];
  const dateFormatChanger = (input) => {
    return `${input.split("-")[2]}-${input.split("-")[1]}-${
      input.split("-")[0]
    }`;
  };
  const timeFormatChanger = (input, bol) => {
    if (input === "") return "";
    let matches = input.toLowerCase().match(/(\d{1,2}):(\d{2}) ([ap]m)/);
    let decide24hr =
      matches[3] === "pm"
        ? parseInt(matches[1]) === 12
          ? "12"
          : `${parseInt(matches[1]) + 12}`
        : parseInt(matches[1]) === 12
        ? "00"
        : matches[1];
    let output = decide24hr + ":" + matches[2] + ":00";
    if (bol) return output;
    else return new Date(`01/01/1970 ${output}`);
  };

  const getDataValue = (_data, dat) => {
    if (dat.type === "action") {
      if (dat.action_type === "dropdown") {
        if (dat.id.includes(".") || dat.id.includes(",")) {
          alert(
            "Action performer which is used with object and comma seperated data are not supported please modify reusable table."
          );
          return "-";
        }
        const action_identifier = dat.action_show_on.find((i) =>
          i.show_condition.find(
            (j) => j.toLowerCase() === _data[i.show_checker_id].toLowerCase()
          )
        );
        if (action_identifier !== undefined) {
          const checker = role_permission_checker({
            permission: action_identifier.permission,
            // role: action_identifier.role,
          });
          if (Object.values(checker).every((item) => item === true)) {
            return (
              <DropDownList
                className="reusable_table_button_dropdown"
                style={{ width: dat.width && dat.width }}
                value={
                  props.checkedItemData.length > 0
                    ? props.checkedItemData.find((i) => i.id === _data.id) ===
                      undefined
                      ? "Please Select"
                      : props.checkedItemData.find((i) => i.id === _data.id)
                          .status === ""
                      ? "Please Select"
                      : props.checkedItemData.find((i) => i.id === _data.id)
                          .status
                    : "Please Select"
                }
                data={action_identifier.action_value}
                onChange={(e) =>
                  props?.[dat.action_identifier] &&
                  props?.[dat.action_identifier](e.target.value, _data.id)
                }
                size={"large"}
              />
            );
          }
        }
        return _data[dat.action_hide_on_show_id];
      }
      return _data[dat.id] === null || _data[dat.id] === undefined
        ? "-"
        : _data[dat.id];
    } else if (dat.id.includes(",")) {
      const split = dat.id.split(",");
      if (_data[`${split[0]}`] === null) {
        return "-";
      } else if (_data[`${split[1]}`] === null) {
        return "-";
      } else {
        if (dat.format === "date-time") {
          return `${moment(
            new Date(dateFormatChanger(_data[`${split[0]}`]))
          ).format("DD MMM YYYY")}, ${_data[`${split[1]}`]}`;
        } else {
          return `${_data[`${split[0]}`]}, ${_data[`${split[1]}`]}`;
        }
      }
    } else if (dat.id.includes(".")) {
      const split = dat.id.split(".");
      if (_data[`${split[0]}`] === null) {
        return "-";
      } else if (_data[`${split[0]}`]?.[`${split[1]}`] === undefined) {
        return "-";
      } else {
        return Array.isArray(_data[`${split[0]}`]?.[`${split[1]}`])
          ? _data[`${split[0]}`]?.[`${split[1]}`].join(", ")
          : _data[`${split[0]}`]?.[`${split[1]}`];
      }
    } else if (dat.longIdentifier) {
      if (_data[`${dat.id}`] === null) {
        return "-";
      } else {
        let find = props?.[dat.longIdentifierObject].find(
          (i) => i.id === _data[`${dat.id}`]
        );
        if (find) {
          return find?.[dat.returnLongIdentifier];
        } else {
          return "-";
        }
      }
    } else if (dat.format && dat.format === "date") {
      if (_data[`${dat.id}`] === null || _data[`${dat.id}`] === undefined) {
        return "-";
      } else {
        if (_data[`${dat.id}`].includes("T")) {
          return moment(new Date(_data[`${dat.id}`].split("T")[0])).format(
            "DD MMM YYYY"
          );
        } else {
          return moment(new Date(_data[`${dat.id}`])).format("DD MMM YYYY");
        }
      }
    } else if (dat.format && dat.format === "date-time") {
      if (
        _data[`${dat.id}`] === "" ||
        _data[`${dat.id}`] === null ||
        _data[`${dat.id}`] === undefined
      ) {
        return "-";
      } else {
        return moment(new Date(_data[`${dat.id}`])).format(
          "DD MMM YYYY, hh:mm a"
        );
      }
    } else if (_data[`${dat.id}`] === null) {
      return "-";
    } else if (typeof _data[`${dat.id}`] === "object") {
      if (_data[`${dat.id}`]?.[`${dat.objectValue}`] === undefined) {
        return "-";
      } else {
        return _data[`${dat.id}`]?.[`${dat.objectValue}`];
      }
    } else if (dat.objectValue !== undefined) {
      return _data[`${dat.id}`]?.[dat.objectValue];
    } else if (dat.format) {
      if (dat.format === "date") {
        return moment(_data[`${dat.id}`]).format("YYYY-MM-DD");
      }
      if (
        dat.format === "time" &&
        isNaN(new Date(_data[`${dat.id}`]).getHours())
      ) {
        return _data[`${dat.id}`];
      } else {
        return `${new Date(_data[`${dat.id}`]).getHours()}:${
          new Date(_data[`${dat.id}`]).getMinutes() >= 10
            ? new Date(_data[`${dat.id}`]).getMinutes()
            : "0" + new Date(_data[`${dat.id}`]).getMinutes()
        }`;
      }
    } else if (dat.type === "image") {
      if (_data[`${dat.id}`] === null || _data[`${dat.id}`] === undefined) {
        return "-";
      } else {
        return (
          <img
            src={_data[`${dat.id}`]}
            style={{
              width: dat.imageSize
                ? dat.imageSize.width
                  ? dat.imageSize.width
                  : "200px"
                : "200px",
              height: dat.imageSize
                ? dat.imageSize.height
                  ? dat.imageSize.height
                  : "200px"
                : "200px",
            }}
          />
        );
      }
    } else {
      if (dat.html) {
      } else {
        return _data[`${dat.id}`];
      }
    }
  };

  const sortForTable = (ascending, value, format) => {
    return function (a, b) {
      let first = value.id.includes(".")
        ? a[value.id.split(".")[0]]
        : value.id.includes(",")
        ? value.format === "date-time" &&
          new Date(
            `${dateFormatChanger(
              a[value.id.split(",")[0]]
            )} ${timeFormatChanger(a[value.id.split(",")[1]], true)}`
          )
        : a[value.id];
      let second = value.id.includes(".")
        ? b[value.id.split(".")[0]]
        : value.id.includes(",")
        ? value.format === "date-time" &&
          new Date(
            `${dateFormatChanger(
              b[value.id.split(",")[0]]
            )} ${timeFormatChanger(b[value.id.split(",")[1]], true)}`
          )
        : b[value.id];

      // equal items sort equally
      if (first === second) {
        return 0;
      }

      // nulls sort after anything else
      if (first === null) {
        return 1;
      }
      if (second === null) {
        return -1;
      }
      first =
        format === "date"
          ? new Date(a[value.id])
          : format === "time"
          ? a[value.id]
          : value.id.includes(".")
          ? a[value.id.split(".")[0]]?.[value.id.split(".")[1]]
          : value.id.includes(",")
          ? value.format === "date-time" &&
            new Date(
              `${dateFormatChanger(
                a[value.id.split(",")[0]]
              )} ${timeFormatChanger(a[value.id.split(",")[1]], true)}`
            )
          : a[value.id];
      second =
        format === "date"
          ? new Date(b[value.id])
          : format === "time"
          ? b[value.id]
          : value.id.includes(".")
          ? b[value.id.split(".")[0]]?.[value.id.split(".")[1]]
          : value.id.includes(",")
          ? value.format === "date-time" &&
            new Date(
              `${dateFormatChanger(
                b[value.id.split(",")[0]]
              )} ${timeFormatChanger(b[value.id.split(",")[1]], true)}`
            )
          : b[value.id];
      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return first < second ? -1 : 1;
      }

      // if descending, highest sorts first
      return first < second ? 1 : -1;
    };
  };

  const SortOnDefault = (value) => {
    if (props.data.length > 0) {
      const sorting = [...props.data].sort(sortForTable(false, value));
      setSortingID({
        id: value.id,
        isState: false,
      });
      return sorting;
    } else {
      return data;
    }
  };

  const handleFilter = (value) => {
    const sorting =
      sortingID.id === value.id
        ? data.sort(sortForTable(!sortingID.isState, value))
        : data.sort(sortForTable(true, value));
    if (sortingID.id === value.id) {
      setSortingID({
        id: value.id,
        isState: !sortingID.isState,
      });
    } else {
      setSortingID({
        id: value.id,
        isState: true,
      });
    }
    setData(() => [...sorting]);
  };

  const getDropdownItem = (tableData, header) => {
    if (header.isshowAction) {
      const tableDataItem = tableData?.[header.isshowAction.item];
      const childernValue =
        header.isshowAction.children && header.isshowAction.children;
      const removeValue = childernValue.filter((i) =>
        i.notShowCondition &&
        i.notShowCondition.toLowerCase() === tableDataItem.toLowerCase()
          ? i.value.toLowerCase() === i.value.toLowerCase()
          : i.value.toLowerCase() === tableDataItem.toLowerCase()
      );
      let temp = header.action;
      removeValue.map((i) => (temp = temp.filter((j) => j !== i.id)));
      return temp.length > 0 ? temp : [];
    }
    return header.action ? header.action : [];
  };

  const permissionChecker = (value, permission) => {
    let returnValue = value;
    if (value.length > 0) {
      let updateValue = [];
      value.map((i) => {
        if (permission?.[i] === undefined) return;
        const userPermission = getUserPermission();
        const checkStatusPermission = Array.isArray(permission?.[i].permission)
          ? role_permission_checker({ permission: permission?.[i].permission })
              .permission
          : checkPermission(userPermission, permission?.[i].permission);
        if (checkStatusPermission) {
          updateValue.push(i);
        }
      });
      return updateValue;
    }
    return returnValue;
  };

  const roleChecker = (value, permission, role, data) => {
    let returnValue = value;
    returnValue = returnValue.filter(
      (i) => i !== role.target_action.find((j) => j === i)
    );
    Object.keys(role.role_checker).map((i) => {
      const userPermission = getUserPermission();
      const checkStatusPermission = checkPermission(userPermission, i);
      if (checkStatusPermission) {
        let targed_data_value = data?.[role.role_checker?.[i].targeted_data];
        console.log(checkStatusPermission, i, permission);
        if (
          role.role_checker?.[i].status.find(
            (l) => l.toLowerCase() === targed_data_value.toLowerCase()
          )
        ) {
          returnValue = permissionChecker(
            [...returnValue, ...role.role_checker?.[i].action],
            permission
          );
        }
      }
    });
    return returnValue;
  };

  useEffect(() => {
    if (props.calculatePagi) {
      handlePageChangeWithPagi(1);
    }
  }, []);

  const handlePageChangeWithPagi = (e, count) => {
    let tempConstRow = count ? count : constRowCount;
    let rowCount = props.rowCount === undefined ? tempConstRow : props.rowCount;
    const indexOfLastItem = e * rowCount;
    const indexOfFirstItem = indexOfLastItem - rowCount;
    const sliceData = props.data
      ? props.defaultSorting
        ? [...SortOnDefault(props.defaultSorting)].slice(
            indexOfFirstItem,
            indexOfLastItem
          )
        : props.data.slice(indexOfFirstItem, indexOfLastItem)
      : [];
    setData(sliceData);
  };
  const handleRowChangeWithPagi = (e) => {
    setConstRowCount(e);
    handlePageChangeWithPagi(1, e === "All" ? props.data.length : e);
  };

  const checkBoxCheck = (value) => {
    let isValue = true;
    value.length > 0
      ? data.map((j) => {
          if (!value.find((i) => i === j.id)) {
            return (isValue = false);
          }
        })
      : (isValue = false);
    return isValue;
  };

  console.log(props);

  return (
    <div>
      <div
        style={{
          maxHeight: props.maxHeight ? props.maxHeight : "",
          overflowY: "auto",
          width: props.width ? props.width : "initial",
          // maxWidth: props.maxWidth ? props.maxWidth : "",
          overflowX: "auto",
          height: props.height ? props.height : "",
        }}
        className="table-select-custom"
      >
        {props.header && (
          <table ref={ref}>
            <thead
              style={{
                position: "sticky",
                top: "0",
                background: "#EFEFEF",
                zIndex: 999,
              }}
            >
              <tr style={{ height: "50px" }}>
                {props.checkbox && props.rowCheckboxClicked && (
                  <th style={{ height: "50px", width: "40px" }}>
                    <Checkbox
                      checked={checkBoxCheck(
                        props.checkedItem ? props.checkedItem : []
                      )}
                      defaultChecked={data.headerCheckboxFlag}
                      onClick={() =>
                        props.rowCheckboxClicked &&
                        props.rowCheckboxClicked("header", data)
                      }
                    />
                  </th>
                )}
                {props.header &&
                  props.header.map((data, id) => {
                    if (data.isShow === false) return null;
                    else
                      return (
                        <th key={id} style={{ cursor: "pointer" }}>
                          <div
                            className="Table_header"
                            style={{
                              width: data.width ? data.width : "100px",
                              padding: !props.headerPadding && "0px",
                            }}
                          >
                            {data.name}
                            {
                              data.filter !== undefined ? (
                                data.filter === true ? (
                                  <span
                                    style={
                                      {
                                        // color: showFilter === data.id && "#EB7930",
                                        // color:
                                        //   tempFilteredData.find(
                                        //     (i) => i.id === data.id
                                        //   ) && "#EB7930",
                                      }
                                    }
                                    class="k-icon k-font-icon k-i-arrows-kpi"
                                    onClick={() => {
                                      if (
                                        data.filter !== undefined &&
                                        data.filter === true
                                      ) {
                                        setSortingID(data);
                                        handleFilter(
                                          data,
                                          data.format ? data.format : ""
                                        );
                                      }
                                    }}
                                  ></span>
                                ) : (
                                  ""
                                )
                              ) : null
                              // <img src={TableFilter} />
                              // <span class="k-icon k-font-icon k-i-filter"></span>
                            }
                          </div>
                        </th>
                      );
                  })}
              </tr>
            </thead>
            <tbody style={{position: props.isSmall && "relative"}}>
              {props.pageChangeLoading && props.pageChangeLoading ? (
                <Loader
                  type="infinite-spinner"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "calc(100% - 100px)",
                    position: "absolute",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100vh - 252px)",
                  }}
                />
              ) : data.length > 0 ? (
                data.map((_data, id) => (
                  <tr
                    className="TableHandle"
                    key={Math.floor(Math.random() * 10000000)}
                  >
                    {props.checkbox && props.rowCheckboxClicked && (
                      <td>
                        <Checkbox
                          defaultChecked={_data.selected}
                          id={`${_data.id ? _data.id : ""}`}
                          checked={
                            props.checkedItem
                              ? props.checkedItem.find((i) => i === _data.id)
                                ? true
                                : false
                              : false
                          }
                          // data-id={`${_data.id}`}
                          onClick={() =>
                            props.rowCheckboxClicked &&
                            props.rowCheckboxClicked("", _data.id)
                          }
                        />
                      </td>
                    )}
                    {props.header &&
                      props.header.map((dat, i) => {
                        if (dat.isShow === false) return null;
                        else {
                          if (dat.id === "button") {
                            if (dat.type === "dropdown") {
                              return (
                                <td
                                  key={i}
                                  onClick={() =>
                                    props.handleTableClick &&
                                    props.handleTableClick(_data.id)
                                  }
                                >
                                  {dat.type === "dropdown" && (
                                    <DropDownList
                                      className="reusable_table_button_dropdown"
                                      style={{ width: dat.width && dat.width }}
                                      defaultValue={"Action"}
                                      // data={[]}
                                      data={
                                        dat.role
                                          ? roleChecker(
                                              getDropdownItem(_data, dat),
                                              dat.permission,
                                              dat.role,
                                              _data
                                            )
                                          : dat.permission
                                          ? permissionChecker(
                                              getDropdownItem(_data, dat),
                                              dat.permission
                                            )
                                          : getDropdownItem(_data, dat)
                                      }
                                      onChange={(e) =>
                                        props.handleAction &&
                                        props.handleAction(
                                          e.target.value,
                                          _data.id
                                        )
                                      }
                                      size={"large"}
                                    />
                                  )}
                                </td>
                              );
                            }
                            //Please add here for more action type
                            // else if(dat.type === ""){}
                          } else {
                            return (
                              <td
                                key={i}
                                onClick={() =>
                                  props.handleTableClick &&
                                  props.handleTableClick(_data.id)
                                }
                              >
                                {getDataValue(_data, dat)}
                              </td>
                            );
                          }
                        }
                      })}
                  </tr>
                ))
              ) : (
                <div
                  className="No_record"
                  style={{
                    height: props.isSmall
                      ? `${props.height - 50}px`
                      : props.maxHeight
                      ? props.maxHeight
                      : "",
                  }}
                >
                  No record found
                </div>
              )}
            </tbody>
          </table>
        )}
      </div>

      {data.length > 0 && props.pagination && (
        <div className="reusable_table_pagination_main_box">
          <Pagination
            pageCount={props.totalPage ? props.totalPage : 1}
            calculatePagi={props.calculatePagi ? props.calculatePagi : false}
            listItem={props.calculatePagi ? props.data : []}
            rowPerPage={props.rowPerPage ? props.rowPerPage : cosntRowPerPage}
            handlePageChange={(e) =>
              props.calculatePagi
                ? handlePageChangeWithPagi(e)
                : props.handlePageChange && props.handlePageChange(e)
            }
            showRow={props.showRow ? props.showRow : false}
            rowCount={props.rowCount ? props.rowCount : constRowCount}
            activePages={props.activePage ? props.activePage : 1}
            handleRowChange={(e) =>
              props.calculatePagi
                ? handleRowChangeWithPagi(e)
                : props.handleRowChange && props.handleRowChange(e)
            }
          />
        </div>
      )}
    </div>
  );
};
