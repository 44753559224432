import React from "react"
import "../dashboard.css"

export const NoRecord = () => {
    return(
        <div className="dashboard_no_record">
            <div>Please Select other month!</div>
            <div>There is no record on selected month!</div>
        </div>
    )
}