import React, { useEffect, useRef, forwardRef, useState, useImperativeHandle } from "react";
import * as XLSX from "xlsx";

const TicketStatusTable = forwardRef((props, ref) => {
    const tableRef = useRef();
    const [data] = useState(props.data ? props.data : []);

    // Expose function to parent using useImperativeHandle
    useImperativeHandle(ref, () => {
        return {
            exportToExcel
        };
    }, []);

    const exportToExcel = () => {

        if (!tableRef.current) return;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(tableRef.current);

        XLSX.utils.book_append_sheet(wb, ws, `Ticket Status Report`);
        XLSX.writeFile(wb, `Ticket_Status_Report_${props.start_date}_${props.end_date}.xlsx`);
    };


    return (
        <div>
            <div
                style={{
                    marginTop: "20px",
                    maxHeight: props.maxHeight ? props.maxHeight : "",
                    overflowY: "auto",
                    width: props.width ? props.width : "initial",
                    // maxWidth: props.maxWidth ? props.maxWidth : "",
                    overflowX: "auto",
                    height: props.height ? props.height : "",
                }}
                className="table-select-custom"
            >
                <table ref={tableRef} style={{ width: '100%' }}>
                    <thead
                        style={{
                            position: 'sticky',
                            top: '0',
                            background: '#EFEFEF',
                        }}
                    >
                        <tr>
                            <th rowSpan={3} className="runtime_feedback_report_table_header">No</th>
                            <th rowSpan={3} className="runtime_feedback_report_table_header">Depot</th>
                            <th rowSpan={3} className="runtime_feedback_report_table_header">Total</th>
                            <th rowSpan={3} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#FFC1D0" }}>Valid</th>
                            <th rowSpan={3} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#ECB1BF" }} >Invalid</th>
                            <th rowSpan={3} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#B3D4FC" }}>Open</th>
                            <th colSpan={4} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#FFDEE6", height: "50px" }}>Closed</th>
                            <th colSpan={2} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#B3D4FC", height: "50px" }}>Open</th>
                        </tr>
                        <tr>
                            <th colSpan={2} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#FFC1D0", height: "50px" }}>Valid</th>
                            <th colSpan={2} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#ECB1BF", height: "50px" }}>Invalid</th>
                            <th colSpan={2} className="runtime_feedback_report_table_header" style={{ backgroundColor: "#DEEDFF", height: "50px" }}>Submitted</th>
                        </tr>
                        <tr>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px" }}>FLB</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px", maxWidth: "70px" }}>Whole Day Runtime</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px" }}>FLB</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px", maxWidth: "70px" }}>Whole Day Runtime</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px" }}>FLB</th>
                            <th className="runtime_feedback_report_table_header" style={{ backgroundColor: "#F6F6F6", height: "50px", maxWidth: "70px" }}>Whole Day Runtime</th>
                        </tr>
                    </thead>

                    <tbody>


                        {(data.length == 0 && !props.loading) ? (
                            <tr>
                                <td colSpan="12" style={{ textAlign: "center", padding: "20px", fontSize: "16px", fontWeight: "bold", color: "#000" }}>
                                    No Data Available
                                </td>
                            </tr>

                        ) : (
                            data.map((row, rowIndex) => rowIndex === data.length - 1 ? (
                                <tr key={rowIndex} className="runtime_feedback_report_table_footer_parent">
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}></td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>
                                        <a
                                            href={`/runtime_feedback?start_date=${props.start_date}&end_date=${props.end_date}`}
                                            className="runtime_feedback_report_hyper_link"
                                        >
                                            {row.name}
                                        </a>
                                    </td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.valid.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.invalid.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.open.total}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.valid.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.valid.whole_day_runtime}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.invalid.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.invalid.whole_day_runtime}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.open.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_footer" style={{ height: "50px", textAlign: "center" }}>{row.open.whole_day_runtime}</td>
                                </tr>
                            ) : (
                                <tr key={rowIndex}>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{rowIndex + 1}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>
                                        <a
                                            href={`/runtime_feedback?depot=${row.name}&start_date=${props.start_date}&end_date=${props.end_date}`}
                                            className="runtime_feedback_report_hyper_link"
                                        >
                                            {row.name}
                                        </a>
                                    </td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.valid.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.invalid.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.open.total}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.valid.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.valid.whole_day_runtime}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.invalid.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.invalid.whole_day_runtime}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.open.first_last_bus}</td>
                                    <td className="runtime_feedback_report_table_body" style={{ height: "50px", textAlign: "center" }}>{row.open.whole_day_runtime}</td>
                                </tr>
                            ))
                        )}

                    </tbody>


                </table>

            </div>
        </div>
    );
});

export default TicketStatusTable;
