export const happening_table_config = [
  {
    id: "title",
    name: "Title",
    isShow: true,
    width: "600px",
    filter: false,
  },
  {
    id: "start_date",
    name: "Start Date",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "end_date",
    name: "End Date",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date",
  },
  {
    id: "published_on",
    name: "Published On ",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "100px",
    filter: false,
  },
];

export const commsBarGraphConfig = {
  graph_title: "Activity Overtime",
  barSize: 8,
  legend_type: "text",
  for_module: "comms",
  xAxis_type: "number",
  xAxis_key: "",
  legend: true,
  config: [
    {
      color: "#F04A00",
      data_key: "published_count",
      title: "Published",
    },
    {
      color: "#2B63CA",
      data_key: "scheduled_count",
      title: "Scheduled",
    },
    {
      color: "#666666",
      data_key: "pending_count",
      title: "Pending Approval",
    },
  ],
};
export const pieChartColor = [
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
  "#73007D",
  "#FFD15B",
  "#00A96C",
  "#D6AAD9",
  "#2B63CA",
  "#666666",
];

export const UserBarGraphConfig = {
  graph_title: "New users by week",
  barSize: 50,
  custom_tick: true,
  legend_type: "text",
  for_module: "user_management",
  xAxis_type: "category",
  xAxis_key: "name",
  legend: false,
  config: [
    {
      color: "#F04A00",
      data_key: "count",
    },
  ],
};

export const userGroupBarGraphConfig = {
  graph_title: "Top active groups",
  barSize: 10,
  custom_tick: false,
  tick_font_size: "9px",
  legend_type: "text",
  for_module: "UserGroup",
  xAxis_type: "category",
  xAxis_key: "name",
  legend: true,
  config: [
    {
      color: "#F04A00",
      data_key: "total_users",
      title: "Total user",
    },
    {
      color: "#2B63CA",
      data_key: "active_users",
      title: "Active user",
    },
  ],
};

export const IfeedbackBarGraphConfig = {
  graph_title: "Feedback Trends",
  barSize: 8,
  legend_type: "text",
  for_module: "comms",
  xAxis_type: "number",
  xAxis_key: "",
  legend: true,
  config: [
    {
      color: "#F04A00",
      data_key: "submitted_count",
      title: "Submitted",
    },
    {
      color: "#2B63CA",
      data_key: "investigating_count",
      title: "Investigating",
    },
    {
      color: "#CCCCCC",
      data_key: "closed_count",
      title: "Closed",
    },
  ],
};

export const DrSecureBarGraphConfig = {
  graph_title: "Incident Type Frequency",
  barSize: 50,
  custom_tick: false,
  legend_type: "button",
  route: "/dr_secure/incident/list",
  for_module: "DrSecure",
  xAxis_type: "category",
  xAxis_key: "name",
  legend: true,
  config: [
    {
      color: "#F04A00",
      data_key: "value",
    },
  ],
};

export const MICLineGraphConfig = {
  graph_title: "Engagement Analysis",
  barSize: 8,
  legend_type: "text",
  for_module: "MIC",
  xAxis_type: "number",
  xAxis_key: "",
  legend: true,
  config: [
    {
      color: "#73007D",
      data_key: "notvalid_count",
      title: "Not Valid",
    },
    {
      color: "#FFD15B",
      data_key: "valid_count",
      title: "Valid",
    },
    {
      color: "#00A96C",
      data_key: "kiv_count",
      title: "KIV",
    },
    {
      color: "#D6AAD9",
      data_key: "implemnting_count",
      title: "Implementing",
    },
    {
      color: "#2B63CA",
      data_key: "implemented_count",
      title: "Implemented",
    },
  ],
};
