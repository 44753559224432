import React, { useEffect, useRef, useState } from "react";
import {
  ConfigData,
  DefaulSorting,
  dropdownConfig,
  statusConfig,
} from "./configData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import ExportIcon from "../../../assets/header/export.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import { TableHeader } from "../../../components/reusableItems/header";
import { Reusable_table } from "../../../components/reusableItems/table";
import {
  fetchAllTutorials,
  deleteTutorialById,
} from "../../../store/manage_tutorial";
import "./manage_tutorial.css";
import { useLocation, useNavigate } from "react-router";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import moment from "moment";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { fetchUserById } from "../../../store/userMgmt";
import {
  ADMIN_TOOLS_TUTORIAL_DETAILS,
  ADMIN_TOOLS_TUTORIAL_ADD,
} from "../../../routes";

export const TutorialListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const exportLink = useRef(null);
  const query = new URLSearchParams(searchQuery);
  const { loading, tutorial_list } = useSelector(
    (state) => state.manageTutorial
  );
  const [tempData, setTempData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");
  const [search, setSearch] = useState(false);

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "tutorial.access_tutorial"
  );
  const AddPermssion = checkPermission(
    userPermissions,
    "tutorial.create_tutorial"
  );

  const getData = async (callback) => {
    setMainLoading(true);
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {
        dispatch(fetchAllTutorials(query)).then((res) => {
          setTempData(
            res.payload.data.map((item, index) => ({
              ...item,
              title_en: item.title_en ? item.title_en : item.title_zh
            }))
          );
          setMainLoading(false);
        });
      }
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const handleCreateAction = () => {
    navigate(ADMIN_TOOLS_TUTORIAL_ADD);
  };

  const RefreshData = () => {
    getData();
  };

  const icons = [
    AddPermssion && {
      buttonName: "+ Add new",
      action: handleCreateAction,
    },
    {
      icon: RefreshIcon,
      title: "Refresh",
      action: RefreshData,
    },
  ];

  const actionTrigger = () => {
    setMainLoading(true);
    if (actionType === "Delete") {
      setShowDelete(false);
      const data = {
        id: actionID,
      };
      dispatch(deleteTutorialById(data)).then((res) => {
        getData();
      });
    }
  };
  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "Duplicate") {
      navigate(`${ADMIN_TOOLS_TUTORIAL_ADD}?id=${id}&isDuplicate=true`);
    }else  if (type === "View Details") {
      navigate(`${ADMIN_TOOLS_TUTORIAL_DETAILS}?id=${id}`);
    } else {
      setActionID(id);
      setActionType(type);
      if (type === "Delete") {
        setShowDelete(true);
      }
    }
  };

  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <TableHeader title={"Tutorial"} icons={icons} />
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}

          {loading || mainLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              checkbox={false}
              defaultSorting={DefaulSorting}
              //   handleTableClick={handleTableClick}
              handleAction={handleTableClick}
              maxHeight={"calc(-221px + 100vh)"}
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
