import NavigateIcon from "../../../../assets/dr_safe/navigate.png";
// components
import DrSafeDetailsStatusDialog from "./dr_safe_details_status_dialog";
import DrSafeDetailsMarkAsDuplicateDialog from "./dr_safe_details_mark_as_duplicate_dialog";
import DrSafeDetailsShareDialog from "./dr_safe_details_share_dialog";
import DrSafeDetailsRouteToDepDialog from "./dr_safe_details_route_dialog";

const DrSafeDetailsActions = ({
  onToggleDialog,
  incidentStatuses,
  currentIncidentStatus,
  onChangeIncidentStatus,
  onSaveIncidentStatus,
  showChangeStatusDialog,
  onUpdateStatusBtnClicked,
  showChangeMarkAsDuplicateDialog,
  onMarkAsDuplicateBtnClicked,
  onSubmitMarkAsDuplicate,
  parentCasesList,
  currentParentCase,
  onChangeParentCaseList,
  showChangeShareDialog,
  onShareBtnClicked,
  onSubmitShare,

  onRouteToDepClose,
  onRouteToDepOpen,
  onRouteToDepAction,
  showRouteToDepeDialog,
  case_ref_no,

  shouldShowBtnUpdateStatus,
  shouldShowBtnRoute,
  shouldShowBtnShare,
  shouldShowBtnMarkDuplicate
}) => {
  return (
    <div className="dr-safe-details-actions-wrapper">
      <div className="dr-safe-details-actions-title">Actions</div>

      <div className="dr-safe-details-actions-button-list">
        {shouldShowBtnUpdateStatus && (
          <div
            className="dr-safe-details-actions-button"
            onClick={onUpdateStatusBtnClicked}
          >
            <span>Update status</span> <img src={NavigateIcon} />
          </div>
        )}

        {shouldShowBtnRoute && (
          <div className="dr-safe-details-actions-button" onClick={onRouteToDepOpen}>
            <span>Route to other department</span> <img src={NavigateIcon} />
          </div>
        )}

        {/*
        <div className="dr-safe-details-actions-button">
          <span>Change incident type</span> <img src={NavigateIcon} />
        </div> */}
        {shouldShowBtnMarkDuplicate && (
          <div className="dr-safe-details-actions-button" onClick={onMarkAsDuplicateBtnClicked}>
            <span>Mark as duplicate</span> <img src={NavigateIcon} />
          </div>
        )}

        {/* {shouldShowBtnShare && (
          <div className="dr-safe-details-actions-button" onClick={onShareBtnClicked}>
            <span>Share</span> <img src={NavigateIcon} />
          </div>
        )} */}

      </div>
      {showChangeStatusDialog && (
        <DrSafeDetailsStatusDialog
          onToggleDialog={onToggleDialog}
          incidentStatuses={incidentStatuses}
          currentIncidentStatus={currentIncidentStatus}
          onChangeIncidentStatus={onChangeIncidentStatus}
          onSaveIncidentStatus={onSaveIncidentStatus}
        />
      )}

      {showChangeMarkAsDuplicateDialog && (
        <DrSafeDetailsMarkAsDuplicateDialog
          onToggleDialog={onToggleDialog}
          onSubmitMarkAsDuplicate={onSubmitMarkAsDuplicate}
          parentCasesList={parentCasesList}
          currentParentCase={currentParentCase}
          onChangeParentCaseList={onChangeParentCaseList}
        />
      )}

      {showChangeShareDialog && (
        <DrSafeDetailsShareDialog
          onToggleDialog={onToggleDialog}
          onSubmitShare={onSubmitShare}
        />
      )}
      {showRouteToDepeDialog && (
        <DrSafeDetailsRouteToDepDialog
        case_ref_no={case_ref_no}
        onRouteToDepClose={onRouteToDepClose}
        onRouteToDepAction={onRouteToDepAction}
        />
      )}
    </div>
  );
};

export default DrSafeDetailsActions;
