import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./dashboard.css";
import {
  CARES_ECARDS_LISTING,
  DR_INFRA_INCIDENT_LIST,
  DR_SAFE_INCIDENT_LIST,
  DR_SECURE_INCIDENT_LIST,
  HAPPENINGS_PROMOTIONS_ROUTE,
  IFEEDBACK_LIST,
  MIC_ROUTE,
  USER_MANAGEMENT_USERS,
} from "../../routes";
import { DashboardToolbar } from "./DashboardItems/toolbar";
import {
  DashboardCommunication,
  Dashboard_Communication,
} from "./DashboardItems/dashboardCommunication";
import {
  DashboardHappenings,
  Dashboard_Happenings,
} from "./DashboardItems/dashboardHappenings";
import {
  DashboardUserManagement,
  Dashboard_UserManagement,
} from "./DashboardItems/dashboardUsermanagement";
import {
  DashboardGroup,
  Dashboard_Group,
} from "./DashboardItems/dashboardGroup";
import { DashboardDR, Dashboard_DR } from "./DashboardItems/dashboardDR";
import {
  DashboardIfeedback,
  Dashboard_Ifeedback,
} from "./DashboardItems/dashboardIfeedback";
import {
  DashboardDRSecure,
  Dashboard_DRSecure,
} from "./DashboardItems/dashboardDRSecure";
import { DashboardMIC, Dashboard_MIC } from "./DashboardItems/dashboardMIC";
import { DashboardCare, Dashboard_Care } from "./DashboardItems/dashboardCare";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCare,
  fetchComms,
  fetchDR,
  fetchHappening,
  fetchIfeedback,
  fetchMIC,
  fetchUser,
  fetchUserGroup,
} from "../../store/dashboard/dashboard";
import { fetchAllCategories } from "../../store/category";
import { Loader } from "@progress/kendo-react-indicators";
import moment from "moment";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import { PermissionDeninedPage } from "../../components/reusableItems/permission_denined";
let month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let ConstMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const widget = [
  "Communication",
  "Happenings",
  "User Management",
  "Group",
  "DR Safe Reported Incident",
  "DR Infra Reported Incident",
  "iFeedback",
  "DR Secure Reported Incident",
  "MIC",
  "CARES",
];

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Today = moment(new Date()).format("YYYY-MM-DD");
  const startDate = moment(new Date()).startOf("month").format("YYYY-MM-DD");
  const endDate = moment(new Date()).endOf("month").format("YYYY-MM-DD");
  const {
    comm_loading,
    happening_loading,
    user_loading,
    userGroup_loading,
    dr_loading,
    ifeedback_loading,
    myidea_loading,
    cares_loading,
    comms,
    happening,
    user,
    user_group,
    dr,
    ifeedback,
    myidea,
    cares,
  } = useSelector((state) => state.dashboard);
  const category = useSelector((state) => state.category);
  const [commsDropdownValue, setCommsDropdownValue] = useState({'id': '', 'name': 'Category'});
  const year = [
    moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear() - 3))
    ).format("YYYY"),
    moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear() - 2))
    ).format("YYYY"),
    moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear() - 1))
    ).format("YYYY"),
    moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("YYYY"),
  ];
  const [filter, setFilter] = useState({
    date: "01",
    month: moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("MMMM"),
    year: moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("YYYY"),
    widget: JSON.parse(localStorage.getItem("dashboardWidget"))
      ? JSON.parse(localStorage.getItem("dashboardWidget"))
      : [
          "Communication",
          "Happenings",
          "User Management",
          "Group",
          "DR Safe Reported Incident",
          "DR Infra Reported Incident",
          "iFeedback",
          "DR Secure Reported Incident",
          "MIC",
          "CARES",
        ],
  });
  const userPermissions = getUserPermission();
  const CommPermission = checkPermission(
    userPermissions,
    "dashboard.access_comm_summary"
  );
  const HappeningPermission = checkPermission(
    userPermissions,
    "dashboard.access_happening_summary"
  );
  const DRPermission = checkPermission(
    userPermissions,
    "dashboard.access_dr_summary"
  );
  const UserPermission = checkPermission(
    userPermissions,
    "dashboard.access_user_summary"
  );
  const UserGroupPermission = checkPermission(
    userPermissions,
    "dashboard.access_usergroup_summary"
  );
  const iFeedbackPermission = checkPermission(
    userPermissions,
    "dashboard.access_ifeedback_summary"
  );
  const MICPermission = checkPermission(
    userPermissions,
    "dashboard.access_myideas_summary"
  );
  const CarePermission = checkPermission(
    userPermissions,
    "dashboard.access_cares_summary"
  );
  const ViewPermission = checkPermission(
    userPermissions,
    "dashboard.access_dashboard"
  );

  const getData = (startValue, endValue, category) => {
    console.log(category);
    console.log(commsDropdownValue);
    if(commsDropdownValue?.id || category != undefined ){
      dispatch(
        fetchComms({
          start_date: startValue,
          end_date: endValue,
          category_id: category ? category : commsDropdownValue?.id ,
        })
      );  
    }
    else{
      dispatch(
        fetchComms({
          start_date: startValue,
          end_date: endValue
          // category_id: undefined
          // category_id: category ? category : commsDropdownValue?.id ,
        })
      );
  
    }
    dispatch(
      fetchHappening({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchUser({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchUserGroup({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchDR({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchIfeedback({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchMIC({
        start_date: startValue,
        end_date: endValue,
      })
    );
    dispatch(
      fetchCare({
        start_date: startValue,
        end_date: endValue,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchAllCategories()).then((res) => {
      if (res.payload.length > 0) {
        // setCommsDropdownValue();
        // getData(startDate, endDate);
      }
      getData(startDate,endDate);
    });
  }, []);
  // const onItemActionChange = (e, val) => {
  //   const { id } = val.dataItem;
  //   navigate(`${NOTIFICATION_RECALL_ROUTE}?id=${id}`);
  // };

  // const HEADER_DATA = useMemo(
  //   () => [
  //     {
  //       field: "publish_on",
  //       title: "Scheduled / Published On",
  //       style: {
  //         width: 200,
  //       },
  //     },
  //     {
  //       field: "category_name",
  //       title: "Category",
  //     },
  //     {
  //       field: "title_en",
  //       title: "Title",
  //     },
  //     {
  //       field: "ack_required",
  //       title: "Ack Required",
  //       cell: (props) => (
  //         <td style={{ textAlign: "center" }}>
  //           {props.dataItem.ack_required ? "Yes" : "No"}
  //         </td>
  //       ),
  //     },
  //     {
  //       field: "-",
  //       title: "Total Recipients",
  //     },
  //     {
  //       field: "created_by.name",
  //       title: "Created By",
  //     },
  //     {
  //       field: "created_on",
  //       title: "Created On",
  //     },
  //     {
  //       field: "status",
  //       title: "Status",
  //     },
  //     {
  //       field: "Action",
  //       style: { width: 160 },
  //       cell: (props) => (
  //         <td>
  //           <DropDownList
  //             style={{
  //               width: "140px",
  //               height: "48px",
  //               backgroundColor: "#EB6E1F",
  //               color: "white",
  //               textAlign: "center",
  //               borderRadius: "8px",
  //             }}
  //             defaultValue={"Action"}
  //             data={["Recall Message", "Archieved"]}
  //             onChange={(e) => onItemActionChange(e, props)}
  //           />
  //         </td>
  //       ),
  //     },
  //   ],
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  const handleFilterChange = (value, type) => {
    let currentMonth = moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("MMMM");
    let currentYear = moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("YYYY");
    let todayValue = new Date(
      `01 ${moment(
        new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
      ).format("MMMM")} ${moment(
        new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
      ).format("YYYY")} 01:00:00`
    );
    if (type === "month") {
      const startValue = moment(
        new Date(`${filter.date} ${value} ${filter.year} 01:00:00`)
      )
        .startOf("month")
        .format("YYYY-MM-DD");
      const endValue = moment(
        new Date(`${filter.date} ${value} ${filter.year} 01:00:00`)
      )
        .endOf("month")
        .format("YYYY-MM-DD");
      setFilter({
        ...filter,
        [type]: value,
      });
      if (filter.year === currentYear) {
        month = ConstMonth.slice(
          0,
          ConstMonth.findIndex((i) => i === currentMonth) + 1
        );
      } else {
        month = ConstMonth;
      }
      getData(startValue, endValue);
    } else if (type === "year") {
      if (
        new Date(`${filter.date} ${filter.month} ${value} 01:00:00`) >
        todayValue
      ) {
        const startValue = moment(
          new Date(`${filter.date} ${currentMonth} ${value} 01:00:00`)
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        const endValue = moment(
          new Date(`${filter.date} ${currentMonth} ${value} 01:00:00`)
        )
          .endOf("month")
          .format("YYYY-MM-DD");
        setFilter({
          ...filter,
          month: currentMonth,
          [type]: value,
        });
        month = ConstMonth.slice(
          0,
          ConstMonth.findIndex((i) => i === currentMonth) + 1
        );
        getData(startValue, endValue);
      } else {
        const startValue = moment(
          new Date(`${filter.date} ${filter.month} ${value} 01:00:00`)
        )
          .startOf("month")
          .format("YYYY-MM-DD");
        const endValue = moment(
          new Date(`${filter.date} ${filter.month} ${value} 01:00:00`)
        )
          .endOf("month")
          .format("YYYY-MM-DD");
        setFilter({
          ...filter,
          [type]: value,
        });
        if (value === currentYear) {
          month = ConstMonth.slice(
            0,
            ConstMonth.findIndex((i) => i === currentMonth) + 1
          );
        } else {
          month = ConstMonth;
        }
        getData(startValue, endValue);
      }
    } else {
      setFilter({
        ...filter,
        [type]: value,
      });
      localStorage.setItem("dashboardWidget", JSON.stringify(value));
    }
  };

  useEffect(() => {
    const currentMonth = moment(
      new Date(new Date(Today).setFullYear(new Date(Today).getFullYear()))
    ).format("MMMM");
    month = ConstMonth.slice(
      0,
      ConstMonth.findIndex((i) => i === currentMonth) + 1
    );
  }, []);

  const handleCommDropdown = (value) => {
    const startValue = moment(
      new Date(`${filter.date} ${filter.month} ${filter.year} 01:00:00`)
    )
      .startOf("month")
      .format("YYYY-MM-DD");
    const endValue = moment(
      new Date(`${filter.date} ${filter.month} ${filter.year} 01:00:00`)
    )
      .endOf("month")
      .format("YYYY-MM-DD");
      setCommsDropdownValue(value);
    
    dispatch(
      fetchComms({
        start_date: startValue,
        end_date: endValue,
        category_id: value?.id? value.id : null,
      })
    );
  };

  return (
    // <div className="dashboard_main_box">
    //   {/* <TableHeader
    //     title = "Welcome to iLink Admin Portal"
    //   />
    //   <TableBody table={HEADER_DATA}/> */}
    //   {/* <h1 style={{textAlign:"center"}}>Welcome to iLink Admin Portal</h1> */}
    //   <div className="dashboard_title_box">
    //     <div className="dashboard_main_text">Welcome to iLink Admin Portal</div>
    //     <div className="dashboard_main_text">
    //       Watch this space for upcoming features in 2025
    //     </div>
    //   </div>
    //   <div>
    //     <img src={ComingSoon} />
    //   </div>
    // </div>
    <>
      {ViewPermission ? (
        <>
          {category.loading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div>
              <DashboardToolbar
                month={month}
                year={year}
                filterValue={filter}
                widget={widget}
                handleFilterChange={handleFilterChange}
              />
              <div className="dashboard_item_sub_box">
                {filter.widget.find((i) => i === "Communication") && (
                  <DashboardCommunication
                    category={category.category_ids}
                    dropdownValue={commsDropdownValue}
                    data={comms}
                    loading={comm_loading}
                    handleCommDropdown={handleCommDropdown}
                    permission={CommPermission}
                  />
                )}
                {filter.widget.find((i) => i === "Happenings") && (
                  <DashboardHappenings
                    data={happening}
                    loading={happening_loading}
                    route={HAPPENINGS_PROMOTIONS_ROUTE}
                    handleNavigate={(e) => navigate(e)}
                    permission={HappeningPermission}
                  />
                )}
                {filter.widget.find((i) => i === "User Management") && (
                  <DashboardUserManagement
                    data={user}
                    loading={user_loading}
                    route={USER_MANAGEMENT_USERS}
                    handleNavigate={(e) => navigate(e)}
                    permission={UserPermission}
                  />
                )}
                {filter.widget.find((i) => i === "Group") && (
                  <DashboardGroup
                    data={user_group}
                    loading={userGroup_loading}
                    permission={UserGroupPermission}
                  />
                )}
                {filter.widget.find(
                  (i) => i === "DR Safe Reported Incident"
                ) && (
                  <DashboardDR
                    permission={DRPermission}
                    loading={dr_loading}
                    title={"DR Safe Reported Incident"}
                    total_report={
                      dr && dr.total_safe_reports ? dr.total_safe_reports : 0
                    }
                    top_incident_location={
                      dr &&
                      dr.top_safe_locations &&
                      dr.top_safe_locations.length > 0
                        ? dr.top_safe_locations
                        : []
                    }
                    top_incident_department={
                      dr &&
                      dr.top_safe_incidents_department &&
                      dr.top_safe_incidents_department.length > 0
                        ? dr.top_safe_incidents_department
                        : []
                    }
                    pieChartData={
                      dr &&
                      dr.safe_status_distribution &&
                      dr.safe_status_distribution.length > 0
                        ? dr.safe_status_distribution
                        : []
                    }
                    route={DR_SAFE_INCIDENT_LIST}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
                {filter.widget.find(
                  (i) => i === "DR Infra Reported Incident"
                ) && (
                  <DashboardDR
                    permission={DRPermission}
                    loading={dr_loading}
                    title={"DR Infra Reported Incident"}
                    total_report={
                      dr && dr.total_infra_reports ? dr.total_infra_reports : 0
                    }
                    top_incident_location={
                      dr &&
                      dr.top_infra_locations &&
                      dr.top_infra_locations.length > 0
                        ? dr.top_infra_locations
                        : []
                    }
                    top_incident_department={
                      dr &&
                      dr.top_infra_incidents_department &&
                      dr.top_infra_incidents_department.length > 0
                        ? dr.top_infra_incidents_department
                        : []
                    }
                    pieChartData={
                      dr &&
                      dr.infra_status_distribution &&
                      dr.infra_status_distribution.length > 0
                        ? dr.infra_status_distribution
                        : []
                    }
                    route={DR_INFRA_INCIDENT_LIST}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
                {filter.widget.find((i) => i === "iFeedback") && (
                  <DashboardIfeedback
                    permission={iFeedbackPermission}
                    data={ifeedback}
                    loading={ifeedback_loading}
                    route={IFEEDBACK_LIST}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
                {filter.widget.find(
                  (i) => i === "DR Secure Reported Incident"
                ) && (
                  <DashboardDRSecure
                    permission={DRPermission}
                    data={dr}
                    loading={dr_loading}
                    route={DR_SECURE_INCIDENT_LIST}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
                {filter.widget.find((i) => i === "MIC") && (
                  <DashboardMIC
                    data={myidea}
                    permission={MICPermission}
                    loading={myidea_loading}
                    route={MIC_ROUTE}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
                {filter.widget.find((i) => i === "CARES") && (
                  <DashboardCare
                    permission={CarePermission}
                    data={cares}
                    loading={cares_loading}
                    route={CARES_ECARDS_LISTING}
                    handleNavigate={(e) => navigate(e)}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
