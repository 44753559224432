import React, { useEffect, useRef, useState } from "react";
import "./promotions.css";
import SearchIcon from "../../../assets/header/search.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import ExportIcon from "../../../assets/header/export.svg";
import { TableHeader } from "../../../components/reusableItems/header";
import { Reusable_table } from "../../../components/reusableItems/table";
import { ConfigData } from "./ConfigData";
import { Search } from "../../../components/reusableItems/search";
import { useNavigate } from "react-router";
import {
  HAPPENINGS_PROMOTIONS_CREATE_ROUTE,
  HAPPENINGS_PROMOTIONS_DETAIL_ROUTE,
  HAPPENINGS_PROMOTIONS_DUPLICATE_ROUTE,
  HAPPENINGS_PROMOTIONS_EDIT_ROUTE,
} from "../../../routes";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPromotionDelete,
  fetchPromotionExport,
  fetchPromotionListing,
  fetchPromotionListingFilter,
  fetchPromotionUpdate,
} from "../../../store/happenings/promotion";
import { Loader } from "@progress/kendo-react-indicators";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";
import { Filter } from "./filter";
import { fetchCategoryListing } from "../../../store/admin_articles/manage_Category";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

export const Promotion_listing = () => {
  const exportLink = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showUnPublish, setShowUnPublish] = useState(false);
  const [showPublishValidatoin, setShowPublishValidation] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [categroy, setCategory] = useState([]);
  const [filterValue, setFilterValue] = useState({
    startDate_from: "",
    startDate_to: "",
    startDate: "all",
    endDate_from: "",
    endDate_to: "",
    endDate: "all",
    createdDate_from: "",
    createdDate_to: "",
    createdDate: "all",
    status: [],
    category: [],
  });
  const [tempUpdate, setTempUpdate] = useState({
    id: "",
    type: "",
  });
  const { listing_data, export_data } = useSelector((state) => state.promotion);
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "happening.access_happening_promotion_list"
  );

  useEffect(() => {
    setFilterLoading(true);
    dispatch(fetchCategoryListing()).then((res) => {
      if (res.payload.status_code === 200) {
        setCategory(res.payload.data);
      }
    });
    dispatch(fetchPromotionListing()).then((res) => {
      if (res.payload.status_code === 200) {
        setTempData(res.payload.data);
        setFilterLoading(false);
      } else {
        setFilterLoading(false);
        alert(res.payload.Message);
      }
    });
  }, []);

  const handleExportAction = () => {
    setExportLoading(true);
    dispatch(fetchPromotionExport()).then((res) => {
      exportLink.current.link.click();
      setExportLoading(false);
    });
  };

  const handleSearchAction = () => {
    setSearch(!search);
  };

  const handleFilterAction = () => {
    setShowFilter(!showFilter);
  };

  const handleCreateAction = () => {
    navigate(HAPPENINGS_PROMOTIONS_CREATE_ROUTE);
  };
  const icons = [
    {
      buttonName: "+ Create new",
      action: handleCreateAction,
    },
    {
      icon: ExportIcon,
      title: "Export",
      action: handleExportAction,
    },
    {
      icon: FilterIcon,
      title: "Filter",
      action: handleFilterAction,
    },
    {
      icon: SearchIcon,
      title: "Search",
      action: handleSearchAction,
    },
  ];

  const actionTrigger = () => {
    if (tempUpdate.type === "Publish" || tempUpdate.type === "Unpublish") {
      setShowPublish(false);
      setShowUnPublish(false);
      setFilterLoading(true);
      dispatch(
        fetchPromotionUpdate({
          status: tempUpdate.type === "Publish" ? "Published" : "Unpublished",
          id: tempUpdate.id,
        })
      ).then((res) => {
        if (res.payload.status_code === 200) {
          dispatch(fetchPromotionListing()).then((res1) => {
            if (res1.payload.status_code === 200) {
              setTempData(res1.payload.data);
              setFilterLoading(false);
            } else {
              setFilterLoading(false);
              alert(res1.payload.Message);
            }
          });
        } else {
          setFilterLoading(false);
          alert(res.payload.message);
        }
      });
    } else {
      setShowDelete(false);
      setFilterLoading(true);
      dispatch(fetchPromotionDelete(tempUpdate.id)).then((res) => {
        if (res.payload.status_code === 200) {
          dispatch(fetchPromotionListing()).then((res1) => {
            if (res1.payload.status_code === 200) {
              setTempData(res1.payload.data);
              setFilterLoading(false);
            } else {
              setFilterLoading(false);
              alert(res1.payload.Message);
            }
          });
        } else {
          setFilterLoading(false);
          alert(res.payload.message);
        }
      });
    }
  };

  const handleTableClick = (type, id) => {
    if (type === "View Details") {
      navigate(`${HAPPENINGS_PROMOTIONS_DETAIL_ROUTE}?id=${id}`);
    } else if (type === "Duplicate") {
      navigate(`${HAPPENINGS_PROMOTIONS_DUPLICATE_ROUTE}?duplicateID=${id}`);
    } else if (type === "Publish" || type === "Unpublish") {
      setTempUpdate({
        ...tempUpdate,
        id: id,
        type: type,
      });
      if (type === "Publish") {
        const find = tempData.find((i) => i.id === id);
        if (find.status === "Submitted") {
          if (find.published_end === undefined || find.published_end === "") {
            setShowPublishValidation(true);
          } else if (
            find.published_on === undefined ||
            find.published_on === ""
          ) {
            setShowPublishValidation(true);
          } else if (
            new Date().setHours(0, 0, 0, 0) >
              new Date(find.published_on.split("T")[0]).setHours(0, 0, 0, 0) ||
            new Date().setHours(0, 0, 0, 0) >
              new Date(find.published_end.split("T")[0]).setHours(0, 0, 0, 0)
          ) {
            setShowPublishValidation(true);
          } else if (
            new Date(find.published_on.split("T")[0]).setHours(0, 0, 0, 0) >
            new Date(find.published_end.split("T")[0]).setHours(0, 0, 0, 0)
          ) {
            setShowPublishValidation(true);
          } else if (find.published_on === find.published_end) {
            setShowPublishValidation(true);
          } else {
            setShowPublish(true);
          }
        } else {
          setShowPublish(true);
        }
      } else {
        setShowUnPublish(true);
      }
    } else {
      setTempUpdate({
        ...tempUpdate,
        id: id,
        type: type,
      });
      setShowDelete(true);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value === "") applySearch("");
  };

  const handleClear = async () => {
    setFilterLoading(true);
    setSearchValue("");
    await setTempData(listing_data);
    setFilterLoading(false);
  };

  const applySearch = async (value) => {
    setFilterLoading(true);
    let searchColumn = [{ id: "title", caseSensitive: true }];
    const filtered = await searchFilter(
      listing_data,
      searchColumn,
      value === "" ? "" : searchValue
    );
    setTempData(filtered);
    setFilterLoading(false);
  };

  const FilterAction = () => {
    setFilterLoading(true);
    setShowFilter(false);
    let params = [];
    if (filterValue.startDate === "date") {
      if (
        filterValue.startDate_from === "" ||
        filterValue.startDate_to === ""
      ) {
        return;
      } else if (
        new Date(filterValue.startDate_from) >
        new Date(filterValue.startDate_to)
      ) {
        return;
      } else {
        params.push(
          `start_date_start=${moment(
            new Date(filterValue.startDate_from)
          ).format("YYYY-MM-DD")}`,
          `start_date_end=${moment(new Date(filterValue.startDate_to)).format(
            "YYYY-MM-DD"
          )}`
        );
      }
    }
    if (filterValue.endDate === "date") {
      if (filterValue.endDate_from === "" || filterValue.endDate_to === "") {
        return;
      } else if (
        new Date(filterValue.endDate_from) > new Date(filterValue.endDate_to)
      ) {
        return;
      } else {
        params.push(
          `end_date_start=${moment(new Date(filterValue.endDate_from)).format(
            "YYYY-MM-DD"
          )}`,
          `end_date_end=${moment(new Date(filterValue.endDate_to)).format(
            "YYYY-MM-DD"
          )}`
        );
      }
    }
    if (filterValue.createdDate === "date") {
      if (filterValue.createdDate === "" || filterValue.createdDate === "") {
        return;
      } else if (
        new Date(filterValue.createdDate_from) >
        new Date(filterValue.createdDate_to)
      ) {
        return;
      } else {
        params.push(
          `created_on_start=${moment(
            new Date(filterValue.createdDate_from)
          ).format("YYYY-MM-DD")}`,
          `created_on_end=${moment(new Date(filterValue.createdDate_to)).format(
            "YYYY-MM-DD"
          )}`
        );
      }
    }
    if (filterValue.status.length > 0) {
      params.push(`status=${filterValue.status.join(",")}`);
    }
    if (filterValue.category.length > 0) {
      params.push(`category=${filterValue.category.join(",")}`);
    }
    dispatch(fetchPromotionListingFilter(params.join("&"))).then((res) => {
      if (res.payload.status_code === 200) {
        setTempData(res.payload.data);
        setFilterLoading(false);
      } else {
        setFilterLoading(false);
        alert(res.payload.message);
      }
    });
  };

  const filterClearAction = async () => {
    setShowFilter(false);
    setFilterLoading(true);
    setFilterValue({
      ...filterValue,
      startDate_from: "",
      startDate_to: "",
      startDate: "all",
      endDate_from: "",
      endDate_to: "",
      endDate: "all",
      createdDate_from: "",
      createdDate_to: "",
      createdDate: "all",
      status: [],
      category: [],
    });
    await setTempData(listing_data);
    setFilterLoading(false);
  };

  const editRedirect = () => {
    navigate(`${HAPPENINGS_PROMOTIONS_EDIT_ROUTE}?id=${tempUpdate.id}`);
  };
  console.log(export_data);
  return (
    <>
      {viewPermssion ? (
        <>
          {exportLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 76px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          <TableHeader title={"Promotions"} icons={icons} />
          <CSVLink
            uFEFF={false}
            asyncOnClick={true}
            data={export_data}
            filename="export_happening_promotion"
            ref={exportLink}
            hidden
          />
          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {showFilter && (
            <Filter
              setShowFilter={setShowFilter}
              filterValue={filterValue}
              category={categroy}
              setFilterValue={setFilterValue}
              FilterAction={FilterAction}
              filterClearAction={filterClearAction}
            />
          )}
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showPublishValidatoin && (
            <ShowDialog
              title={"Please Edit!"}
              content={
                "Update the Scheduled/Published On and Scheduled/Published End fields accordingly to publish the selected promotion."
              }
              cancelContent={"Cancel"}
              confrimContent={"Edit"}
              cancelbtn={() => setShowPublishValidation(false)}
              confrinbtn={editRedirect}
            />
          )}
          {showPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Publish?"}
              cancelContent={"Cancel"}
              confrimContent={"Publish"}
              cancelbtn={() => setShowPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showUnPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Unpublish?"}
              cancelContent={"Cancel"}
              confrimContent={"Unpublish"}
              cancelbtn={() => setShowUnPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {filterLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              defaultSorting={false}
              handleAction={handleTableClick}
              maxHeight={
                search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
              }
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
