import React, { useEffect, useState } from "react";
import "./articles_admin.css";
import { TextBox } from "@progress/kendo-react-inputs";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  fetchCategoryCreate,
  fetchCategoryDetail,
  fetchCategoryListing,
  fetchCategoryUpdate,
} from "../../../store/admin_articles/manage_Category";
import { Loader } from "@progress/kendo-react-indicators";
import { HAPPENINGS_CATEGORY_ROUTE } from "../../../routes";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";

export const Manage_category_form = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(false);

  const userPermissions = getUserPermission();
  const AddPermssion = checkPermission(
    userPermissions,
    "happening.create_happening_category"
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(fetchCategoryDetail(id)).then((res) => {
        if (res.payload.status_code === 200) {
          setCategoryName(res.payload.data.category);
          setLoading(false);
        }
      });
    }
  }, [id]);

  const handleSubmit = () => {
    setLoading(true);
    if (id) {
      dispatch(fetchCategoryUpdate({ id: id, category: categoryName })).then(
        (res) => {
          if (res.payload.status_code === 200) {
            navigate(HAPPENINGS_CATEGORY_ROUTE);
            setLoading(false);
          } else {
            setLoading(false);
            alert(res.payload.Message);
          }
        }
      );
    } else {
      dispatch(fetchCategoryCreate({ category: categoryName })).then((res) => {
        if (res.payload.status_code === 201) {
          navigate(HAPPENINGS_CATEGORY_ROUTE);
          setLoading(false);
        } else {
          setLoading(false);
          alert(res.payload.Message);
        }
      });
    }
  };

  return (
    <>
      {AddPermssion ? (
        <div>
          {loading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 76px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <>
              <div
                className="ManageCategory_create_header"
                //remove style if autosave needs to show
                style={{ minHeight: "50px" }}
              >
                <div
                  className="ManageCategory_create_back"
                  onClick={() => navigate(HAPPENINGS_CATEGORY_ROUTE)}
                >
                  <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                  Back
                </div>
              </div>
              <div className="ManageCategory_create_scroll_box">
                <div>
                  <div className="ManageCategory_create_item_main_box">
                    <div className="ManageCategory_create_item_main_box_title">
                      Category Name
                    </div>
                    <div>
                      <TextBox
                        className="ManageCategory_create_item_main_box_field"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ManageCategory_create_footer_main_box">
                <div className="ManageCategory_create_review_main_box">
                  <div
                    className="ManageCategory_create_footer_button_cancel"
                    onClick={() => navigate(HAPPENINGS_CATEGORY_ROUTE)}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="ManageCategory_create_footer_button_save"
                  onClick={() => handleSubmit()}
                  style={{
                    pointerEvents: categoryName === "" ? "none" : "",
                    opacity: categoryName === "" ? "0.5" : "1",
                  }}
                >
                  Submit
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
