import React from "react";
import TotalIcon from "../../../assets/dashboard/comm_publish.svg";
import ActiveIcon from "../../../assets/dashboard/comm_schedule.svg";
import InActiveIcon from "../../../assets/dashboard/comm_draft.svg";
import { BarChartComponents } from "../components/barChart";
import { UserBarGraphConfig } from "./configData";
import { Loader } from "@progress/kendo-react-indicators";
import { NoRecord } from "../components/noRecord";
import { NoPermission } from "../components/noPermission";

export const DashboardUserManagement = (props) => {
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  return (
    <div>
      <div className="dashboard_graph_title_box">
        <div className="dashboard_graph_title">User Management</div>
      </div>
      {props.permission ? (
        props.loading ? (
          <Loader
            type="converging-spinner"
            style={{
              width: "100%",
              minHeight: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ) : (
          <div
            style={{ marginTop: "20px" }}
            className="dashboard_usermanagement_main_box"
          >
            <div style={{ flex: "1 1" }}>
              <div className="dashboard_usermanagement_first_box_first_child">
                <div className="dashboard_usermanagement_first_box_first_child_item">
                  <div>
                    <img src={TotalIcon} />
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_text">
                    Total Users
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_count">
                    {props.data && props.data.total_users !== undefined
                      ? kFormatter(props.data.total_users)
                      : "-"}
                  </div>
                </div>
                <div className="dashboard_usermanagement_first_box_first_child_item">
                  <div>
                    <img src={ActiveIcon} />
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_text">
                    Active
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_count">
                    {props.data && props.data.total_active_users !== undefined
                      ? kFormatter(props.data.total_active_users)
                      : "-"}
                  </div>
                </div>
                <div className="dashboard_usermanagement_first_box_first_child_item">
                  <div>
                    <img src={InActiveIcon} />
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_text">
                    Inactive
                  </div>
                  <div className="dashboard_usermanagement_first_box_first_child_item_count">
                    {props.data && props.data.total_inactive_users !== undefined
                      ? kFormatter(props.data.total_inactive_users)
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="dashboard_usermanagement_chart_first_box">
                {props.data &&
                props.data.weekly_count &&
                props.data.weekly_count.length > 0 ? (
                  <BarChartComponents
                    data={
                      props.data &&
                      props.data.weekly_count &&
                      props.data.weekly_count.length > 0
                        ? props.data.weekly_count
                        : []
                    }
                    config={UserBarGraphConfig}
                  />
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
            <div
              style={{ flex: "1 1" }}
              className="dashboard_usermanagement_child_second_box"
            >
              <div className="dashboard_graph_title_box">
                <div className="dashboard_usermanagement_second_box_title">
                  Top num of users by Department/Depot
                </div>
                <div
                  className="dashboard_usermanagement_see_all_button"
                  onClick={() =>
                    props.handleNavigate &&
                    props.handleNavigate(
                      props.route ? props.route : "/dashboard"
                    )
                  }
                >
                  See all
                </div>
              </div>
              <div className="dashboard_usermanagement_child_second_item_box">
                {props.data &&
                props.data.group_by_deparment &&
                props.data.group_by_deparment.length > 0 ? (
                  props.data.group_by_deparment.slice(0, 12).map((i, index) => (
                    <div
                      key={index}
                      className="dashboard_usermanagement_child_second_item_child"
                    >
                      <div className="dashboard_usermanagement_child_second_item_child_title_box">
                        <div>{index + 1}.</div>
                        <div>{i.name}</div>
                      </div>
                      <div>{i.value}</div>
                    </div>
                  ))
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <NoPermission />
      )}
    </div>
  );
};
