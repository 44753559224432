export const ConfigData = [
  {
    id: "category",
    name: "Category Name",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "created_by.name",
    name: "Created By",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "created_on",
    name: "Created On",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "updated_by.name",
    name: "Updated By",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "updated_on",
    name: "Updated On",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time",
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["Edit", "Delete"],
    permission: {
      Edit: {
        id: "Edit",
        permission: "happening.edit_happening_category",
      },
      Delete: {
        id: "Delete",
        permission: "happening.delete_happening_category",
      },
    },
  },
];
