import React, { useEffect, useState } from "react";
import "./runtime_feedback.css";
import { Loader } from "@progress/kendo-react-indicators";
import { Stepper } from "@progress/kendo-react-layout";
import { CustomStep } from "../../components/common/custom_steps";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPermission,
  getUserPermission,
} from "../../config/permission_helper";
import {
  fetchRuntimeAudit,
  fetchRuntimeDetail,
  fetchRuntimeStatusUpdate,
} from "../../store/runtime/runtime";
import { RUNTIME_FEEDBACK_LIST } from "../../routes";
import moment from "moment";
import BUS from "../../assets/Runtime/ic_ bus.svg";
import BUS_STOP from "../../assets/Runtime/ic_bus stop.svg";
import { role_permission_checker } from "../../components/reusableItems/table";
import { ShowDialog } from "../../components/reusableItems/dialog";

export const Runtime_Feedback_Details = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, detail_data, audit_data, auditLoading } = useSelector(
    (state) => state.runtime
  );
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [tempStatus, setTempStatus] = useState("");

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "runtime_feedback.access_runtime_feedback_portal"
  );
  const updatePermssion = checkPermission(
    userPermissions,
    "runtime_feedback.update_runtime_feedback_status"
  );

  useEffect(() => {
    dispatch(fetchRuntimeDetail(id)).then((res) => {
      dispatch(fetchRuntimeAudit(id));
    });
  }, []);

  const getDropdownData = () => {
    const validator = role_permission_checker({
      permission: ["runtime_feedback.update_runtime_feedback_status"],
      // role: ["Validator"],
    });
    // const system_owner = role_permission_checker({
    //   permission: [
    //     "runtime_feedback.update_valid_invalid_status_runtime_feedback",
    //   ],
    //   // role: ["System Owner"],
    // });
    if (
      Object.values(validator).every((item) => item === true) &&
      detail_data.status === "Submitted"
    ) {
      return ["Invalid", "Valid"];
    }
    // else if (
    //   Object.values(system_owner).every((item) => item === true) &&
    //   detail_data.status !== "Submitted"
    // ) {
    //   return ["Review in Progress", "Not Supported"];
    // }
  };

  const updateStatus = () => {
    setShowUpdateStatus(false);
    let params = [{ id: id, status: tempStatus }];
    dispatch(fetchRuntimeStatusUpdate(params)).then((res) => {
      console.log(res);
      if (res.payload.status_code === 200) {
        dispatch(fetchRuntimeDetail(id)).then((res1) => {
          dispatch(fetchRuntimeAudit(id));
        });
      } else {
        alert(res.payload.message);
      }
    });
  };

  const checkCanUpdate = () => {
    const validator = role_permission_checker({
      permission: ["runtime_feedback.update_runtime_feedback_status"],
      // role: ["Validator"],
    });
    // const system_owner = role_permission_checker({
    //   permission: [
    //     "runtime_feedback.update_valid_invalid_status_runtime_feedback",
    //   ],
    //   // role: ["System Owner"],
    // });

    if (
      Object.values(validator).every((item) => item === true) &&
      detail_data.status === "Submitted"
    ) {
      return true;
    }
    // else if (
    //   Object.values(system_owner).every((item) => item === true) &&
    //   detail_data.status !== "Submitted"
    // ) {
    //   return true;
    // }
  };

  return (
    <>
      {viewPermssion && (
        <>
          {showUpdateStatus && (
            <ShowDialog
              cancelbtn={() => {
                setShowUpdateStatus(false);
                setTempStatus("");
              }}
              title={"Update Status"}
              dropdownContent={"Set status"}
              dropdown={true}
              cancelContent={"Cancel"}
              confrimContent={"Update"}
              confrinbtn={updateStatus}
              dropdownData={getDropdownData()}
              dropdownOnChange={(e) => setTempStatus(e)}
              dropdownValue={tempStatus}
            />
          )}
          {loading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="Runtime_detail_main_box">
              <div className="Runtime_detail_child_box">
                <div className="Runtime_detail_left_box">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="Runtime_detail_back"
                      onClick={() => navigate(RUNTIME_FEEDBACK_LIST)}
                    >
                      <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                      Back
                    </div>
                  </div>

                  <div className="Runtime_detail_child_box_main_detail_box">
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Case Ref No.
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.reference_no
                          ? detail_data.reference_no
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Ticket Status
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.ticket_status
                          ? detail_data.ticket_status
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Operation Date
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.operation_date
                          ? moment(new Date(detail_data.operation_date)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Service No.
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.service_no
                          ? detail_data.service_no
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Direction
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.direction
                          ? detail_data.direction
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Current Status
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.status
                          ? detail_data.status
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        Review
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {/* {detail_data && detail_data.reviews
                          ? `${
                              detail_data.reviews.first_or_last &&
                              detail_data.reviews.first_or_last
                            } , ${
                              detail_data.reviews.whole_day_runtime &&
                              detail_data.reviews.whole_day_runtime.join(", ")
                            }`
                          : "-"} */}
                        {detail_data && detail_data.reviews && detail_data.reviews.type
                          ? detail_data.reviews.type === "Whole Day Runtime"
                            ? `${detail_data.reviews.type} (${
                                detail_data.reviews.ranges &&
                                detail_data.reviews.ranges.join(", ")
                              })`
                            : `${detail_data.reviews.type}`
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        From
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.from_bus_stop_code
                          ? detail_data.from_bus_stop_code
                          : "-"}
                      </div>
                    </div>
                    <div className="Runtime_detail_child_box_main_detail_items">
                      <div className="Runtime_detail_child_box_main_detail_items_title Runtime_detail_center_item detail_padding_bottom">
                        To
                      </div>
                      <div className="Runtime_detail_child_box_main_detail_items_splitter" />
                      <div className="Runtime_detail_center_item Runtime_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_bus_stop_code
                          ? detail_data.to_bus_stop_code
                          : "-"}
                      </div>
                    </div>
                    {detail_data && detail_data.reviews && detail_data.reviews.type && detail_data.reviews.type === "Whole Day Runtime" ? (
                      <div className="Runtime_detail_route_main_box">
                        <div className="Runtime_detail_route_first_child_box">
                          <div className="Runtime_detail_route_first_child_box_image">
                            <img src={BUS} />
                          </div>
                          <div className="Runtime_detail_route_first_child_box_detail_box">
                            <div className="Runtime_detail_route_first_child_box_detail_item_first">
                              {detail_data && detail_data.service_no
                                ? detail_data.service_no
                                : "-"}
                            </div>
                            <div className="Runtime_detail_route_first_child_box_detail_item_second">
                              Bedok Interchange &gt;&gt;&gt; Woodlads
                              Interchange
                            </div>
                          </div>
                        </div>
                        <div className="Runtime_detail_route_second_child_box">
                          <div className="Runtime_detail_route_second_child_box_arrow_main_box">
                            <div className="Runtime_detail_route_second_child_box_arrow_tail"></div>

                            <div className="Runtime_detail_route_second_child_box_arrow_head">
                              &#x25BC;
                            </div>
                          </div>
                          <div className="Runtime_detail_route_second_detail_box">
                            {(detail_data && detail_data.routes
                              ? detail_data.routes
                              : []
                            )
                              .slice(0, -1)
                              .map((i) => (
                                <div
                                  key={i.bus_stop_code}
                                  className="Runtime_detail_route_second_detail_box_item_box"
                                >
                                  <div className="Runtime_detail_route_second_detail_box_item_box_first">
                                    {i.bus_stop_code}
                                  </div>
                                  <div className="Runtime_detail_route_second_detail_box_item_box_second">
                                    {i.name}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="Runtime_detail_route_first_child_box">
                          <div className="Runtime_detail_route_first_child_box_image">
                            <img src={BUS_STOP} />
                          </div>
                          <div className="Runtime_detail_route_second_detail_box_item_box">
                            <div className="Runtime_detail_route_second_detail_box_item_box_first">
                              {detail_data && detail_data.routes
                                ? detail_data.routes[
                                    detail_data.routes.length - 1
                                  ].bus_stop_code
                                : "-"}
                            </div>
                            <div className="Runtime_detail_route_second_detail_box_item_box_second">
                              {detail_data && detail_data.routes
                                ? detail_data.routes[
                                    detail_data.routes.length - 1
                                  ].name
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="Runtime_detail_right_box">
                  <div className="Runtime_detail_right_status_box">
                    <div className="Runtime_detail_right_box_status_title">
                      Status
                    </div>
                    <div className="Runtime_detail_right_box_status_subdetail">
                      {detail_data && detail_data.status
                        ? detail_data.status
                        : "-"}
                    </div>
                  </div>
                  {/* )} */}
                  <div className="Runtime_detail_right_box_action_main_box">
                    <div className="Runtime_detail_right_box_action_title">
                      Actions
                    </div>
                    {updatePermssion && checkCanUpdate() && (
                      <div
                        className="Runtime_detail_right_box_action_edit"
                        onClick={() => setShowUpdateStatus(true)}
                      >
                        Update Status &#x2192;
                      </div>
                    )}
                    {/* {EditPermssion && (
                  <div
                    className="Runtime_detail_right_box_action_edit"
                    // onClick={() => setShowStatus(true)}
                  >
                    Edit Visibility &#x2192;
                  </div>
                )}
                {DeletePermssion && (
                  <div
                    className="Runtime_detail_right_box_action_delete"
                    // onClick={() => setShowDelete(true)}
                  >
                    Delete Articles &#x2192;
                  </div>
                )} */}
                  </div>
                  <div className="Runtime_detail_right_box_action_main_box">
                    <div className="Runtime_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                      {auditLoading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={audit_data}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
