import React, { useEffect } from 'react';
import { Switch, TextBox } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import CopyIcon from "../../../../assets/common/copy.svg";
import DeleteIcon from "../../../../assets/common/delete.svg";
import { LANGUAGE_TABS } from './question_lists';


const BranchingOptionsList = ({ question , enQuestions , zhQuestions , updateQuestion , tab }) => {
    const addOption = () => {
      const option = {
        "question_id": "",
        "value": "",
      }
      updateQuestion(question.qid, "options", [...question.options, option]);
    };

    const updateOption = (index, key, value) => {
      const newOptions = [...question.options];
      newOptions[index] = { ...newOptions[index], [key]: value };
      updateQuestion(question.qid, "options", newOptions);
    };

    const deleteOption = (index) => {
      const newOptions = [...question.options];
      newOptions.splice(index, 1); // Remove the option at the given index
      updateQuestion(question.qid, "options", newOptions);
    };

    return (
      <div className="poll_and_survey_add_option_list">
        {question.options.map((option, index) => (
          <div className="poll-survey-option-item">
            <TextBox
              className="poll_and_survey_main_box_field"
              value={option.value}
              onChange={(e) => updateOption(index, "value", e.target.value)}
            />

            <div> Go To </div>

            <DropDownList
              data={tab === LANGUAGE_TABS.EN ? enQuestions : zhQuestions}
              textField="qtitle"
              dataItemKey="qid"
              value={option.question_id}
              onChange={(e) => {
                updateOption(index, "question_id", e.target.value)
              }}
              placeholder="Select Type"
              className="poll_and_survey_create_item_main_box_field tutorial_dropdown"
              itemRender={itemRender}
            />
            <div
              className="delete-button"
              onClick={() => {
                deleteOption(index);
              }}
            >
              <img src={DeleteIcon} className="delete-button-icon" />
            </div>
          </div>
        ))}

        <div className="poll_and_survey_add_option_main">
          <div
            className="poll_and_survey_add_row_button"
            onClick={addOption}
          >
            <div>Add Option</div>
          </div>

        </div>

      </div>
    );
  };



  const itemRender = (li, itemProps) => {
    return React.cloneElement(li, {
      style: {
        height: "48px",
        lineHeight: "48px",
      },
    });
  };

  export default BranchingOptionsList;