import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";

const {
  DASHBOARD_COMMS_ENDPOINT,
  DASHBOARD_HAPPENING_ENDPOINT,
  DASHBOARD_USER_ENDPOINT,
  DASHBOARD_USER_GROUP_ENDPOINT,
  DASHBOARD_DR_ENDPOINT,
  DASHBOARD_IFEEDBACK_ENDPOINT,
  DASHBOARD_MYIDEA_ENDPOINT,
  DASHBOARD_CARES_ENDPOINT,
} = config.api_endpoint;

const initialState = {
  comm_loading: false,
  happening_loading: false,
  user_loading: false,
  userGroup_loading: false,
  dr_loading: false,
  ifeedback_loading: false,
  myidea_loading: false,
  cares_loading: false,
  error: null,
  comms: {},
  happening: {},
  user: {},
  user_group: {},
  dr: {},
  ifeedback: {},
  myidea: {},
  cares: {},
};

export const fetchComms = createAsyncThunk(
  "dashboard/fetchComms",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DASHBOARD_COMMS_ENDPOINT, params, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchHappening = createAsyncThunk(
  "dashboard/fetchHappening",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        DASHBOARD_HAPPENING_ENDPOINT,
        params,
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "dashboard/fetchUser",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DASHBOARD_USER_ENDPOINT, params, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchUserGroup = createAsyncThunk(
  "dashboard/fetchUserGroup",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        DASHBOARD_USER_GROUP_ENDPOINT,
        params,
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchDR = createAsyncThunk(
  "dashboard/fetchDR",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DASHBOARD_DR_ENDPOINT, params, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchIfeedback = createAsyncThunk(
  "dashboard/fetchIfeedback",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        DASHBOARD_IFEEDBACK_ENDPOINT,
        params,
        token
      );
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchMIC = createAsyncThunk(
  "dashboard/fetchMIC",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DASHBOARD_MYIDEA_ENDPOINT, params, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

export const fetchCare = createAsyncThunk(
  "dashboard/fetchCare",
  async (params, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(DASHBOARD_CARES_ENDPOINT, params, token);
      return results;
    } catch (err) {
      return rejectWithValue(err.details);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchComms.pending, (state) => {
        state.comm_loading = true;
      })
      .addCase(fetchComms.fulfilled, (state, action) => {
        // let tempCommsData = {...action.payload.data}
        // if(action.payload.data){
        //     console.log(action.payload.data);
        //     let tempModifiedGroupBy = []
        //     if(Object.keys(tempCommsData.group_by_category).length > 0){
        //         Object.keys(tempCommsData.group_by_category).map(i => {
        //             tempModifiedGroupBy.push({
        //                 name: i,
        //                 count: tempCommsData.group_by_category?.[i]
        //             })
        //         })
        //     }
        //     tempCommsData = {
        //         ...tempCommsData,
        //         group_by_category: tempModifiedGroupBy
        //     }
        // }
        state.comm_loading = false;
        state.comms = action.payload.data;
      })
      .addCase(fetchComms.rejected, (state, action) => {
        state.comm_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchHappening.pending, (state) => {
        state.happening_loading = true;
      })
      .addCase(fetchHappening.fulfilled, (state, action) => {
        state.happening_loading = false;
        state.happening = action.payload.data;
      })
      .addCase(fetchHappening.rejected, (state, action) => {
        state.happening_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.user_loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        let tempUserData = { ...action.payload.data };
        if (action.payload.data) {
          let tempModifiedWeekdata = [];
          if (Object.keys(tempUserData.weekly_count).length > 0) {
            Object.keys(tempUserData.weekly_count).map((i) => {
              tempModifiedWeekdata.push({
                id: i,
                name:
                  i === "first"
                    ? "01"
                    : i === "sec"
                    ? "02"
                    : i === "third"
                    ? "03"
                    : "04",
                count: tempUserData.weekly_count?.[i],
              });
            });
          }
          tempUserData = {
            ...tempUserData,
            weekly_count: tempModifiedWeekdata,
          };
        }
        state.user_loading = false;
        state.user = tempUserData;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchUserGroup.pending, (state) => {
        state.userGroup_loading = true;
      })
      .addCase(fetchUserGroup.fulfilled, (state, action) => {
        state.userGroup_loading = false;
        state.user_group = action.payload.data;
      })
      .addCase(fetchUserGroup.rejected, (state, action) => {
        state.userGroup_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchDR.pending, (state) => {
        state.dr_loading = true;
      })
      .addCase(fetchDR.fulfilled, (state, action) => {
        state.dr_loading = false;
        state.dr = action.payload.data;
      })
      .addCase(fetchDR.rejected, (state, action) => {
        state.dr_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchIfeedback.pending, (state) => {
        state.ifeedback_loading = true;
      })
      .addCase(fetchIfeedback.fulfilled, (state, action) => {
        state.ifeedback_loading = false;
        state.ifeedback = action.payload.data;
      })
      .addCase(fetchIfeedback.rejected, (state, action) => {
        state.ifeedback_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchMIC.pending, (state) => {
        state.myidea_loading = true;
      })
      .addCase(fetchMIC.fulfilled, (state, action) => {
        state.myidea_loading = false;
        state.myidea = action.payload.data;
      })
      .addCase(fetchMIC.rejected, (state, action) => {
        state.myidea_loading = true;
        state.error = action.error.message;
      })
      .addCase(fetchCare.pending, (state) => {
        state.cares_loading = true;
      })
      .addCase(fetchCare.fulfilled, (state, action) => {
        state.cares_loading = false;
        state.cares = action.payload.data;
      })
      .addCase(fetchCare.rejected, (state, action) => {
        state.cares_loading = true;
        state.error = action.error.message;
      });
  },
});

const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
