import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./reusable.css";

export const CustomDropdownMulti = ({
  ID,
  value,
  data,
  onChange,
  className,
}) => {
  const ref = useRef();
  const [id, setID] = useState("");
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    setID(uuidv4());
  }, [data]);

  const closeOpenMenus = (e) => {
    if (!ref.current?.contains(e.target)) {
      if (ref.current) {
        setIsShow(false);
        // document
        //   .getElementById("box_transaction")
        //   .classList.remove("is-active");
      }
    }
  };

  const openClose = () => {
    document.addEventListener("click", closeOpenMenus);
    setIsShow(!isShow);
    // document.getElementById(`${id}`).classList.toggle("is-active");
  };

  const handleClick = (item) => {
    const find = value.find((i) => i === item);
    if (find) {
      const filter = value.filter((i) => i !== item);
      onChange([...filter]);
    } else {
      onChange([...value, item]);
    }
  };
  return (
    <div
      className={
        className
          ? `custom_dropdown_main_box ${className}`
          : "custom_dropdown_main_box"
      }
      ref={ref}
    >
      <div className="custom_dropdown_child_box" onClick={openClose}>
        <div>Select Module</div>
        <div>
          <span class="k-icon k-font-icon k-i-chevron-down" />
        </div>
      </div>
      {/* {isShow && ( */}
      <div
        className={
          isShow
            ? "custom_dropdown_items_box is-active"
            : "custom_dropdown_items_box"
        }
      >
        {data.map((i) => (
          <div
            key={i}
            className="custom_dropdown_item"
            onClick={(e) => handleClick(i)}
          >
            <div style={{ minWidth: "16px" }}>
              {value && value.find((j) => j === i) ? (
                <span class="k-icon k-font-icon k-i-check custom_dropdown_check_icon icon_background" />
              ) : (
                ""
              )}
            </div>
            <div>{i}</div>
          </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
};
