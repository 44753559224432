export const ConfigData = [
  {
    id: "title",
    name: "Title",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "description",
    name: "Description",
    isShow: true,
    width: "230px",
    filter: false,
  },
  {
    id: "publish_on",
    name: "Start Dates",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time"

  },
  {
    id: "publish_end",
    name: "End Dates",
    isShow: true,
    width: "160px",
    filter: false,
    format: "date-time"

  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "160px",
    filter: false,

  },
  {
    id: "total_responding",
    name: "No.of respondents",
    isShow: true,
    width: "160px",
    filter: false,

  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Update Status","Delete"],
    permission: {
      ["View Details"]: {
        id: "View Details",
        permission: "survey.view_all_surveys",
      },
      ["Delete"]: {
        id: "Delete",
        permission: "survey.delete_survey",
      },
      ["Update Status"]: {
        id: "Update Status",
        permission: "survey.publish_survey",
      },
    },
  },
];

export const statusConfig = [
  {
    id: "draft",
    name: "Draft",
  },
  {
    id: "pendingapproval",
    name: "Pending Approval",
  },
  {
    id: "approve",
    name: "Approved",
  },
  {
    id: "publish",
    name: "Published",
  },
  {
    id: "close",
    name: "Closed",
  },
  {
    id: "conclude",
    name: "Concluded",
  },
];

export const DefaulSorting = {
  id: "publish_on",
  name: "Publish On",
  isShow: true,
  width: "160px",
  filter: true,
};