import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import "../../communication.css";
import {
  InputSeparator,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Icon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import SearchIcon from "../../../../assets/communication/search_icon.svg";

export const SelectUsers = (props) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");
  let delay;
  useEffect(() => {
    delay = setTimeout(() => {
      if (value.length >= 3) callAPI();
    }, 1000);

    return () => clearTimeout(delay);
  }, [value]);

  const callAPI = () => {
    console.log("callAPI: ", value);
    props.onFilter({ search_key: value });
  };

  const handleChange = React.useCallback((event) => {
    setValue(event.target.value);
    console.log("handleChange: ", event.target.value);
  }, []);
  return (
    <>
      <div className="select-user-group">
        <div className="tab-name">Select User(s)</div>
        <div className="user-group-box">
          <div className="search-users-box">
            <label className="user-group-label">Search</label>
            <TextBox
              className="search-users-txtbox"
              value={value}
              onChange={handleChange}
              placeholder="Search by SAP No. / Name / BC No. (min 3 characters, case sensitive)"
              suffix={() => (
                <>
                  <InputSuffix>
                    <Icon>
                      <img src={SearchIcon} />
                      &nbsp;
                    </Icon>
                  </InputSuffix>
                  <InputSeparator />
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};
