import React, { useEffect } from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import "./style.css";

const TextAreaBox = React.memo(({ title, value,disabled, onChange, errorMessage }) => {
    return (
        <div className="poll_and_survey_main_box margin-bottom">
            <div className="poll_and_survey_main_box_title">{title}</div>
            <div>
                <TextArea
                    className="poll_and_survey_main_text_area_box_field"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                {errorMessage && (
                    <div className="poll_and_survey_error_text">{errorMessage}</div>
                )}
            </div>
        </div>
    );
});

export default TextAreaBox;
