import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./aprewall.css";
import { TableHeader } from "../../../components/reusableItems/header";
import RefreshIcon from "../../../assets/header/refresh.svg";
import SearchIcon from "../../../assets/header/search.svg";
import FilterIcon from "../../../assets/header/filter.svg";
import ExportIcon from "../../../assets/header/export.svg";
import { Reusable_table } from "../../../components/reusableItems/table";
import { ConfigData, DefaulSorting, data } from "./configData";
import {
  CARES_APPRECIATION_WALLS_CREATE,
  CARES_APPRECIATION_WALLS_Detail,
} from "../../../routes";
import {
  fetchApreDelete,
  fetchApreListing,
  fetchApreUpdateStatus,
} from "../../../store/aprewall/aprewall";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { Search } from "../../../components/reusableItems/search";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import { searchFilter } from "../../../components/reusableItems/filter/filter_helper";

export const CaresAppwasllList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, listing_data } = useSelector((state) => state.aprewall);
  const [showDelete, setShowDelete] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showUnPublish, setShowUnPublish] = useState(false);
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempData, setTempData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);

  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "cares.access_cares_appwall_list"
  );
  console.log("thisisoermision", viewPermssion);

  useEffect(() => {
    setFilterLoading(true);
    dispatch(fetchApreListing()).then((res) => {
      setTempData(res.payload.data);
      setFilterLoading(false);
    });
  }, []);

  const handleCreateAction = () => {
    console.log("create");
    navigate(CARES_APPRECIATION_WALLS_CREATE);
  };

  const handleRefreshAction = () => {};
  const handleSearchAction = () => {
    setSearch(!search);
  };

  const icons = [
    {
      buttonName: "+ Add new",
      action: handleCreateAction,
    },
    // {
    //   icon: RefreshIcon,
    //   action: handleRefreshAction,
    // },
    {
      icon: SearchIcon,
      title: "Search",
      action: handleSearchAction,
    },
  ];

  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "View Details") {
      navigate(`${CARES_APPRECIATION_WALLS_Detail}?id=${id}`);
    } else {
      setActionID(id);
      setActionType(type);
      if (type === "Publish") {
        setShowPublish(true);
      } else if (type === "Delete") {
        setShowDelete(true);
      } else if (type === "Unpublish") {
        setShowUnPublish(true);
      }
    }
  };

  const actionTrigger = () => {
    if (actionType === "Delete") {
      setShowDelete(false);
      dispatch(fetchApreDelete(actionID)).then((res) => {
        dispatch(fetchApreListing());
      });
    } else if (actionType === "Publish" || actionType === "Unpublish") {
      setShowPublish(false);
      setShowUnPublish(false);
      dispatch(
        fetchApreUpdateStatus({
          id: actionID,
          status: actionType === "Publish" ? "Published" : "Unpublished",
        })
      ).then((res) => {
        setFilterLoading(true);
        dispatch(fetchApreListing()).then((res) => {
          setTempData(res.payload.data);
          setFilterLoading(false);
        });
      });
    }
  };

  const handleClear = async () => {
    setFilterLoading(true);
    setSearchValue("");
    await setTempData(listing_data);
    setFilterLoading(false);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  const applySearch = async () => {
    setFilterLoading(true);
    console.log(searchValue);
    let searchColumn = [
      { id: "title_en", caseSensitive: true },
      { id: "title_zh", caseSensitive: true },
      { id: "status", caseSensitive: false },
      { id: "from_date", caseSensitive: false },
      { id: "to_date", caseSensitive: false },
      { id: "to_time", caseSensitive: false },
      { id: "from_time", caseSensitive: false },
    ];
    const filtered = await searchFilter(
      listing_data,
      searchColumn,
      searchValue
    );
    setTempData(filtered);
    setFilterLoading(false);
  };

  console.log(tempData);

  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <TableHeader title={"Appreciation Wall"} icons={icons} />
          {search && (
            <Search
              handleSearch={handleSearch}
              type={"text"}
              searchValue={searchValue}
              placeholder={"Search"}
              handleClear={handleClear}
              applySearch={applySearch}
            />
          )}
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Publish?"}
              cancelContent={"Cancel"}
              confrimContent={"Publish"}
              cancelbtn={() => setShowPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showUnPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Unpublish?"}
              cancelContent={"Cancel"}
              confrimContent={"Unpublish"}
              cancelbtn={() => setShowUnPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {loading || filterLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              defaultSorting={DefaulSorting}
              // handleTableClick={handleTableClick}
              handleAction={handleTableClick}
              maxHeight={
                search ? "calc(-282px + 100vh)" : "calc(100vh - 220px)"
              }
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
