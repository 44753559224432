import React, { useEffect, useRef, useState } from "react";
import {
  ConfigData,
  DefaulSorting,
  dropdownConfig,
  statusConfig,
} from "./configData";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import ExportIcon from "../../../assets/header/export.svg";
import RefreshIcon from "../../../assets/header/refresh.svg";
import { TableHeader } from "../../../components/reusableItems/header";
import { Reusable_table } from "../../../components/reusableItems/table";
import {
  approveEcards,
  deleteECard,
  fetchAlleCardsList,
  fetchBusinessAreas,
  fetchDepots,
  fetchecardExport,
  updateECardVisibility,
} from "../../../store/ecards";
import "./ecard.css";
import { useLocation, useNavigate } from "react-router";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { EcardsFilter } from "./ecards_filter";
import moment from "moment";
import { CSVLink } from "react-csv";
import { CARES_ECARDS_VIEW_DETAILS } from "../../../routes";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { fetchUserById } from "../../../store/userMgmt";

export const EcardListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { searchQuery } = useLocation();
  const exportLink = useRef(null);
  const query = new URLSearchParams(searchQuery);
  const { loading, ecard_lists, business_areas, depots } = useSelector(
    (state) => state.ecard
  );
  const { export_data, export_loading } = useSelector((state) => state.ecard);
  const [tempData, setTempData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showUnPublish, setShowUnPublish] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [actionID, setActionID] = useState("");
  const [actionType, setActionType] = useState("");
  const [search, setSearch] = useState(false);
  const [exportIDS, setExportIDS] = useState([]);
  const [filterValue, setFilterValue] = useState({
    to_user_businessarea: "",
    to_user_dept: "",
    cares_date: "",
    status: "",
  });
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "cares.access_cares_ecard_list"
  );

  const getData = async (callback) => {
    setMainLoading(true);
    const business = await dispatch(fetchBusinessAreas()).then((res) => {
      return res.payload;
    });
    const depart = await dispatch(fetchDepots()).then((res) => {
      return res.payload;
    });
    dispatch(fetchUserById(localStorage.getItem("username"))).then(
      async (result) => {
        const filtered_department = depart.find(
          (i) => i.id === result.payload.dept_code
        );
        const filtered_Businessarea = result.payload.depot
          ? business.find((i) => i.code === result.payload.depot)
          : "";
        const filtered_status = statusConfig.find((i) => i.id === "Submitted");
        dispatch(fetchAlleCardsList(query)).then((res) => {
          if (callback) {
            let filterValues = {
              ...filterValue,
              cares_date: filterValue.cares_date,
              to_user_businessarea: filterValue.to_user_businessarea,
              to_user_dept: filterValue.to_user_dept,
              status: filterValue.status,
            };
            handleFilter(filterValues, "", undefined, res.payload.data);
          } else {
            setTempData(res.payload.data);
            setMainLoading(false);
          }
        });
      }
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const RefreshData = () => {
    setFilterValue({
      ...filterValue,
      to_user_businessarea: "",
      to_user_dept: "",
      cares_date: "",
      status: "",
    });
    getData();
  };

  const handleExport = () => {
    dispatch(fetchecardExport(query)).then((res) => {
      exportLink.current.link.click();
    });
  };

  const icons = [
    {
      icon: RefreshIcon,
      title: "Refresh",
      action: RefreshData,
    },
    {
      icon: ExportIcon,
      title: "Export",
      action: handleExport,
    },
  ];

  const rowCheckboxClicked = async (type, id) => {
    let tempId = [...exportIDS];
    if (type === "header") {
      let temp = [];
      await id.map((i) => {
        temp.push(i.id);
      });
      const filtered = temp.filter((i) => tempId.find((j) => j === i) !== i);
      if (filtered.length === 0) {
        const deletedFilter = tempId.filter(
          (i) => temp.find((j) => j === i) !== i
        );
        // setExportIDS([...deletedFilter]);
        tempId = [...deletedFilter];
      } else {
        tempId = [...tempId, ...filtered];
        // setExportIDS([...tempId, ...filtered]);
      }
      // console.log(tempId);
      // id.map((i) => {
      //   if (!tempId.find((j) => j === i.id)) {
      //     tempId.push(i.id);
      //   } else {
      //     const filtered = tempId.filter((l) => l !== i.id);
      //     console.log("see", filtered);
      //     tempId = filtered;
      //   }
      // });
    } else {
      if (exportIDS.find((i) => i === id)) {
        const filtered = tempId.filter((i) => i !== id);
        tempId = filtered;
      } else {
        tempId.push(id);
      }
    }
    setExportIDS(tempId);
  };
  const handleFilter = async (value, id, keyItem, EcardData) => {
    setMainLoading(true);
    setExportIDS([]);
    let allData = EcardData ? EcardData : ecard_lists;
    let tempFilterValue = {};
    if (id === "") {
      let isEmpty = true;
      for (var key in value) {
        if (value[key] !== "") {
          isEmpty = false;
        }
      }
      if (isEmpty) {
        getData();
        return;
      }
      setFilterValue({
        ...value,
      });
      tempFilterValue = {
        ...value,
      };
    } else {
      setFilterValue({
        ...filterValue,
        [id]: value,
      });
      tempFilterValue = {
        ...filterValue,
        [id]: value,
      };
    }
    console.log(tempFilterValue, value[id]);
    let filteredData = [];
    await allData.map((i) => {
      let tempitem = i;
      for (let j = 0; j < Object.keys(tempFilterValue).length; j++) {
        let key = Object.keys(tempFilterValue)[j];
        console.log(tempFilterValue, key);
        if (tempitem !== undefined && tempitem[key]) {
          let isDate = new Date(tempitem[key]).getTime()
            ? new Date(tempitem[key])
            : undefined;
          console.log(key, tempitem, isDate, tempFilterValue);
          if (tempFilterValue[key] !== "" && isDate) {
            if (
              moment(isDate).format("MMM YYYY") !==
              moment(tempFilterValue[key]).format("MMM YYYY")
            ) {
              tempitem = undefined;
            }
          } else {
            if (id === key) {
              if (tempitem[key] !== tempFilterValue[key]?.[keyItem]) {
                tempitem = undefined;
              }
            } else {
              if (tempFilterValue[key] !== "") {
                const getDropdownConfig = dropdownConfig.find(
                  (l) => l.id === key
                );
                if (
                  tempitem[key] !==
                  tempFilterValue[key]?.[getDropdownConfig.dataKeyItem]
                ) {
                  tempitem = undefined;
                }
              }
            }
          }
        }
      }
      if (tempitem) {
        filteredData.push(i);
      }
    });
    setTempData(filteredData);
    setMainLoading(false);
  };
  const clearFilter = async () => {
    setMainLoading(true);
    await setFilterValue({
      ...filterValue,
      to_user_businessarea: "",
      to_user_dept: "",
      cares_date: "",
      status: "",
    });
    await setTempData(ecard_lists);
    setMainLoading(false);
  };
  const actionTrigger = () => {
    setMainLoading(true);
    if (actionType === "Delete") {
      setShowDelete(false);
      dispatch(deleteECard(actionID)).then((res) => {
        getData();
      });
    } else if (actionType === "Publish" || actionType === "Unpublish") {
      setShowPublish(false);
      setShowUnPublish(false);
      dispatch(
        updateECardVisibility({
          id: actionID,
          status: actionType === "Publish" ? "publish" : "unpublish",
        })
      ).then((res) => {
        getData();
      });
    }
  };
  const handleTableClick = (type, id) => {
    console.log(type, id);
    if (type === "View Details") {
      navigate(`${CARES_ECARDS_VIEW_DETAILS}?id=${id}`);
    } else {
      setActionID(id);
      setActionType(type);
      if (type === "Publish") {
        setShowPublish(true);
      } else if (type === "Delete") {
        setShowDelete(true);
      } else if (type === "Unpublish") {
        setShowUnPublish(true);
      }
    }
  };

  const shouldDisableApprove = () => {
    let returnValue = true;
    let editPermssion = checkPermission(
      userPermissions,
      "cares.update_cares_ecard_status"
    );
    if (editPermssion) {
      if (exportIDS.length > 0) {
        const tempList = ecard_lists.filter(
          (i) => exportIDS.find((j) => j === i.id) === i.id
        );
        if (tempList.find((i) => i.status.toLowerCase() !== "submitted")) {
          returnValue = true;
        } else {
          returnValue = false;
        }
      }
    }
    return returnValue;
  };

  const handleApprove = () => {
    setShowApprove(false);
    setMainLoading(true);
    dispatch(approveEcards(exportIDS)).then((res) => {
      getData(true);
    });
  };
  return (
    <>
      {viewPermssion ? (
        <div className="container">
          <CSVLink
            uFEFF={false}
            asyncOnClick={true}
            data={export_data}
            filename="export_cares_ecards"
            ref={exportLink}
            hidden
          />
          {export_loading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 95px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          )}
          <TableHeader title={"eCard"} icons={icons} />
          {showDelete && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to delete?"}
              cancelContent={"Cancel"}
              confrimContent={"Delete"}
              cancelbtn={() => setShowDelete(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Publish?"}
              cancelContent={"Cancel"}
              confrimContent={"Publish"}
              cancelbtn={() => setShowPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showUnPublish && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Unpublish?"}
              cancelContent={"Cancel"}
              confrimContent={"Unpublish"}
              cancelbtn={() => setShowUnPublish(false)}
              confrinbtn={actionTrigger}
            />
          )}
          {showApprove && (
            <ShowDialog
              title={"Please Confirm!"}
              content={"Are you sure you want to Approve selected eCards?"}
              cancelContent={"Cancel"}
              confrimContent={"Approve"}
              cancelbtn={() => setShowApprove(false)}
              confrinbtn={handleApprove}
            />
          )}
          {loading || mainLoading ? null : (
            <EcardsFilter
              approveID={exportIDS}
              dropdownConfig={dropdownConfig}
              status={statusConfig}
              businessArea={business_areas}
              department={depots}
              filterValue={filterValue}
              handleFilter={handleFilter}
              clearFilter={clearFilter}
              shouldDisableApprove={shouldDisableApprove}
              handleApprove={() => setShowApprove(true)}
            />
          )}
          {loading || mainLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100vh - 165px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <Reusable_table
              header={ConfigData}
              data={tempData}
              pagination={true}
              calculatePagi={true}
              pageChangeLoading={false}
              showRow={true}
              checkbox={true}
              department={depots}
              defaultSorting={DefaulSorting}
              rowCheckboxClicked={rowCheckboxClicked}
              checkedItem={exportIDS}
              //   handleTableClick={handleTableClick}
              handleAction={handleTableClick}
              maxHeight={
                search ? "calc(-282px + 100vh)" : "calc(-320px + 100vh)"
              }
              maxWidth={"calc(100vw - 240px)"}
            />
          )}
        </div>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
