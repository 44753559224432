import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import TextFieldBox from "../components/text_field_box";
import TextAreaBox from "../components/text_area";
import "../poll_and_survey.css";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import { CustomDatePicker } from "../../../../components/custom/datepicker";
import moment from "moment";
import { LANGUAGE_TABS } from "../view/question_lists";
import RadioIcon from "../../../../assets/poll_survey/radio.svg";
import CheckboxIcon from "../../../../assets/poll_survey/checkbox.svg";
import StarIcon from "../../../../assets/poll_survey/star.svg";
import { TextBox } from "@progress/kendo-react-inputs";



export const ReviewAndPublish = (props) => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const minMinute = new Date(new Date().getTime() + 15 * 60000);
  const [tab, setTab] = useState(LANGUAGE_TABS.EN);

  const onTabChange = (tab) => {
    setTab(tab);
  };


  const RenderRow = ({ title, value }) => {
    return (
      <>
        {value ? (
          <>
            <div className="poll_survey_child_box_main_detail_items">
              <div className="poll_survey_child_box_main_detail_items_title poll_survey_center_item poll_survey_detail_padding_bottom">
                {title}
              </div>
              <div className="poll_survey_child_box_main_detail_items_splitter" />
              <div className="poll_survey_center_item poll_survey_child_box_main_detail_items_subdetail poll_survey_detail_padding_bottom">
                {value}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };



  const Item = ({ question, index }) => {

    return (
      <div className="poll_and_survey_create_item_main_box">
        <div className="flex-between">
          <div className="poll_and_survey_create_item_main_box_title">{`Question ${index + 1}`}</div>
          <div className="required-text" key={index}>{question.is_required ? 'Required' : ''}</div>
        </div>
        <div className="poll_and_survey_create_item_main_box_field" style={{ margin: "1px" }}>
          <div className="poll_and_survey_create_item_main_box_title">{`${question.qtitle}`}</div>
          {question.options.map((option, index) => (
            <div>
              <div className="poll-survey-option-item" style={{ marginBottom: "5px" }}>
                <img src={question.qtype.value === 'single-choice' ? RadioIcon : CheckboxIcon} className="delete-button-icon" />
                <div className="poll_and_survey_create_item_main_box_title_review">{`${option.value}`}</div>
              </div>
              {option.type === 'other' ?
                <div>
                  <TextBox
                    className="review-text-field"
                    value={"Please Input"}
                    disabled
                  />
                </div> : null}
            </div>
          ))}

          {question.qtype.value == "star-rating" ? (
            <div className="poll-survey-option-item" style={{ marginBottom: "5px" }}>
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
              <img src={StarIcon} className="delete-button-icon" />
            </div>
          ) : null}

          {question.qtype.value == "open-ended" ? (
            <TextAreaBox
              className="review-text-field"
              value={"Please Input"}
              disabled={true}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="poll_and_survey_scroll_box">

        <div style={{ marginTop: "20px" }}>

          <RenderRow
            title={"Title"}
            value={props.editData.title}
          />

          <RenderRow
            title={"Title in Description"}
            value={props.editData.description}
          />

          <RenderRow
            title={"Start Date"}
            value={moment(props.editData.start_date).format("DD-MM-YYYY")}
          />

          <RenderRow
            title={"Start Time"}
            value={moment(props.editData.start_time).format("hh:mm A")}
          />

          <RenderRow
            title={"End Date"}
            value={moment(props.editData.end_date).format("DD-MM-YYYY")}
          />

          <RenderRow
            title={"End Time"}
            value={moment(props.editData.end_time).format("hh:mm A")}
          />

        </div>

        <div className="poll_and_survey_tab_content">
          <div className="tabs">
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.EN)}
              className={`tab ${tab === LANGUAGE_TABS.EN ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.EN}
            </div>
            <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
            <div
              onClick={() => onTabChange(LANGUAGE_TABS.CH)}
              className={`tab ${tab === LANGUAGE_TABS.CH ? "isactive" : ""
                }`}
            >
              {LANGUAGE_TABS.CH}
            </div>
          </div>
        </div>


        {tab == LANGUAGE_TABS.EN ? (
          props.enQuestions.map((question, index) => (
            <Item question={question} index={index} key={index} />
          ))

        ) : (
          props.zhQuestions.map((question, index) => (
            <Item question={question} index={index} key={index} />
          ))

        )

        }

      </div>
    </>
  );
};
